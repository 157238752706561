// Coronavirus home module
.home.layout-grid #covid-module {
	border-radius: 0.1875em;
	background-color: $color-neutral-cool-10;
	display: flex;
	flex-direction: column;
	margin-bottom: colspan(g,4);

	@include breakpoint-min(sm) {
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		margin-bottom: colspan(g,8);
		flex-direction: row;
		justify-content: space-between;
	}
	@include breakpoint-min(lg) {
		margin-bottom: colspan(g,9);
	}

	.group-cover {
		display: flex;
		flex-direction: column;

		img {
			display: none;
		}

		@include breakpoint-min(md) {
			width: colspan(2,8);

			img {
				display: block;
			}
		}
		@include breakpoint-min(lg) {
			width: colspan(2,12);
		}
		@include breakpoint-min(xl) {
			width: colspan(2,9);
		}

		.pp-module-title {
			background-color: #000;
			color: #fff;
			font-size: $scale-4;
			padding: $scale-3;
		}

		.lead-art {
			flex: 1;

			a, a:hover {
				border-bottom: none;
			}

			img {
				object-fit: cover;
				mix-blend-mode: multiply;
			}
		}
	}

	.group-header {
		margin: colspan(p,4);

		@include breakpoint-min(sm) {
			margin: colspan(p,6);
		}
		@include breakpoint-min(md) {
			font-size: $scale-4;
			margin: 0 0 $scale-4 0;
		}
	}

	.group-features {

		@include breakpoint-min(md) {
			border-right: $border;
			border-color: $color-neutral-cool-20;
			width: colspan(4,8);
			margin: colspan(p,8) 0;
			padding-right: calc(#{colspan(g,8)} - 1px);
		}

		@include breakpoint-min(lg) {
			width: colspan(7,12) + colspan(g,12);
			margin: colspan(p,12) 0;
			padding-right: calc(#{colspan(g,12)} - 1px);
		}

		@include breakpoint-min(xl) {
		   width: colspan(5,9);
		   margin: colspan(p,9) 0; 
		   padding-right: calc(#{colspan(g,9)} - 1px);
		}

		.story-entry {
			@include clearfix;

			.lead-art {
				float: right;
				width: colspan(1,4) - colspan(p,4);
				margin: 0 colspan(p,4) colspan(p,4) colspan(p,4);

				@include breakpoint-min(sm) {
					width: colspan(1,6) - colspan(p,6) + colspan(g,6);
					margin: 0 colspan(p,6) colspan(p,6) colspan(p,6);
				}
				@include breakpoint-min(md) {
					width: colspan(1,4);
					margin: 0 0 colspan(p,4) colspan(p,4);
				}
				@include breakpoint-min(lg) {
					width: colspan(1,7);
					margin: 0 0 colspan(p,7) colspan(p,7);
				}
				@include breakpoint-min(xl) {
					width: colspan(1,5);
					margin: 0 0 colspan(p,5) colspan(p,5);
				}
			}
			.description {
				margin-left: colspan(p,4);
				
				@include breakpoint-min(sm) {
					margin-left: colspan(p,6);
				}
				@include breakpoint-min(md) {
					margin-left: 0;
				}
			}
			.hed {
				@include breakpoint-min(md) {
					line-height: $line-height-shorter;
					font: 700 $scale-6 $fonts-hed;
				}
			}
			.dek {
				@include breakpoint-min(md) {
					display: none;
				}
			}
			.metadata {
				color: $color-neutral-cool-40;
			}
		}
	}

	.group-resources {

		@include breakpoint-min(md) {
			width: colspan(2,8);
			margin: colspan(p,8) 0;
			padding-right: colspan(p,8);
		}

		@include breakpoint-min(lg) {
			width: colspan(3,12) - colspan(g,12);
			margin: colspan(p,12) 0;
			padding-right: colspan(p,12);
		}

		@include breakpoint-min(xl) {
			width: colspan(2,9);
			margin: colspan(p,9) 0;
			padding-right: colspan(p,9);
		}

		.promo-in-brief {
			.brief-thumb {
				display: none;
				width: colspan(1,4) - colspan(p,4);
				margin: 0 0 colspan(p,4) colspan(p,4);

				@include breakpoint-min(sm) {
					width: colspan(2,6) - colspan(p,6);
					margin: 0 0 colspan(p,6) colspan(p,6);
				}
			}
			.brief-title {
				font-weight: 700;
				margin-left: colspan(p,4);
				
				@include breakpoint-min(sm) {
					margin-left: colspan(p,6);
				}
				@include breakpoint-min(md) {
					margin-left: 0;
					font-size: $scale-4;
					line-height: $line-height-shorter;
				}
			}
		}
	}
}

.home.layout-float #covid-module {
	border-radius: 0.1875em;
	background-color: $bg-low-contrast;
	background-color: #dceef9;
	padding: $scale-6;

	.lead-art {
		margin-top: $scale-4;
	}

	.lead-art a,
	.lead-art a:hover {
		border-bottom: none;
	}

	.promo-in-brief.callout .brief,
	.promo-in-brief:last-of-type .brief {
		padding-bottom: 0;
	}

	.promo-in-brief.callout h3 {
		font-weight: 700;
		padding-right: 75px;
	}

	.icon {
		float: right;
		width: 75px;
		height: 75px;
		padding: 15px;
	}

	hr {
		border-color: #fff;
		margin-left: (-$scale-6);
		margin-right: (-$scale-6);
	}
}

// Article Canned Promo

article {
	aside.canned-promo.special-coronavirus {
		background-color: $color-neutral-cool-10;
		color: $pp-blue;
		padding: $scale-6;
		border-radius: 0.1875em;
		border-top: 0;
		border-bottom: 0;

		@include breakpoint-max(sm) {
			width: 100%;
		}

		.icon {
			float: right;
			width: 60px;
			height: 60px;
			padding: 0 0 15px 15px;
		}

		.aside-head {
			color: $pp-blue;
			border-top: none;
			padding-top: 0;
			margin-bottom: $scale-2;
		}

		.aside-dek,
		.aside-note {
			a {
				border: none;
				text-decoration: underline;
			}
		}

		.aside-note {
			font-size: 0.6rem;
		}
	}
}

// Article Screendoor Callout

article {
	aside.canned-promo.special-coronavirus-callout {
		border: 0;
		border-radius: 0.1875em;
		background-color: $color-neutral-cool-10;
		padding: 0 colspan(g,4) colspan(g,4) colspan(g,4);
		@include breakpoint-min(md) {
			padding: 0 colspan(g,5) colspan(g,5) colspan(g,5);
		}
		@include breakpoint-min(lg) {
			padding: 0 colspan(g,7) colspan(g,7) colspan(g,7);
		}

		.aside-head {
			display: inline-block;
			font-size: $scale-4;
			text-transform: uppercase;
			text-align: center;
			background-color: $pp-blue;
			padding: $scale-4;
			border-radius: 0 0 $scale-2 $scale-2;
			margin-bottom: $scale-6;
			color: white;

			@include breakpoint-min(lg) {
				font-size: $scale-5;
			}
		}

		.dek {
			margin-bottom: $scale-8;
			font-size: $scale-6;
			color: $pp-blue;
		}

		.fr_form {
			color: $pp-blue;

			hr {
				background-color: white;
			}

			.fr_description {
				color: rgba($pp-blue, 0.75); 
			}            

			.fr_sub_label {
				color: rgba($pp-blue, 0.5);
			}

			& .fr_bottom {
				background-color: rgba($pp-blue, 0.1);
			}
		}
	}
}
