// ------------------------------------------------------------
// IMPORTS, SYSTEM VARIABLES
// ------------------------------------------------------------
@import "reset";
@import "lib/collapsible";
@import "column-settings";
@import "column-setter";
@import "fonts";
@import "colors";
@import "global-styles";
@import "global-forms";
@import "global-modules";
@import "global-nav";
@import "layout";
@import "story-entry";
@import "article";
@import "listing";
@import "about";
@import "home";
@import "home-illinois";
@import "impact";
@import "footer";
@import "video";
@import "newsletters";
@import "electionland";
@import "texas";
@import "coronavirus";

$image-assets: "../images";
$font-assets: "../fonts";
$cachebuster: random(1000000);

@include grid(content); // Apply the grid to items with a class of "content".
// @include grid(content,overlay); // Apply the grid and overlay to items with a class of "content".

.content {
    @include clearfix;
}

#admin-link {
    position: fixed;
    bottom: 0.75rem;
    right: 1rem;
    z-index: 10000;
    font-size: $scale-4;
}
