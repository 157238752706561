@use "sass:math";

// ------------------------------------------------------------
// MODULE DEFINITIONS
// ------------------------------------------------------------

.pp-module {
    font-family: $fonts-sans;
}

.pp-module:last-child {
    margin-bottom: 0;
}

.pp-module-title {
    color: $hed-color;
    font-size: $scale-5;
    font-weight: bold;
    letter-spacing: $letter-spacing-base;
    text-transform: uppercase;

    .dark & {
        color: white;
    }
}

.pp-module-description {
    font-size: $scale-5;
}

.module-photo {
    margin-bottom: 1em;
}

.module-content {
    font-family: $fonts-sans;
    font-size: $scale-5;
}

.module-content p + p {
    margin-top: $scale-5;
}

.module-cap {
    display: flex;
}

.module-cap .btn {
    align-self: flex-start;
    flex: 0 0 auto;
}

.global-promos {
    @include breakpoint-min(sm) {
        float: right;
        width: colspan(2,6);
    }
    @include breakpoint-min(md) {
        width: colspan(3,8);
    }
    @include breakpoint-min(lg) {
        width: colspan(4,12);
    }

    > * {
        margin-bottom: colspan(g,4);
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g,2);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g,3);
        }
        @include breakpoint-min(lg) {
            margin-bottom: colspan(g,4);
        }
    }

    .about & {
        @include breakpoint-min(sm) {
            width: 100%;
        }

        > * {
            @include breakpoint-range(md,lg) {
                padding: colspan(p,2);
                margin-bottom: colspan(g,2);
            }
        }
    }
}

// Site Promo Module

.site-promo-module {
    font-family: $fonts-sans;
    background-color: $bg-low-contrast;
    margin-bottom: colspan(g,4);
    @include breakpoint-range(sm,lg) {
        margin-bottom: colspan(g,2);
    }

    a {
        color: $text-color;

        &:focus .site-title,
        &:hover .site-title {
            color: $link-color;
        }
    }

    .site-text {
        padding: colspan(p,4);
        @include breakpoint-range(sm,lg) {
            padding: colspan(p,2);
        }
    }

    .site-title {
        color: $hed-color;
        font-weight: bold;
    }

    .site-description {
        font-size: $scale-5;
    }
}

// External ad module

.ad-300x250 {
    display: none;
    background-color: $bg-low-contrast;
    @include breakpoint-min(lg) {
        display: block;
        // margin-bottom: colspan(g, 4);
    }

    .inner {
        width: 300px;
        margin: 0 auto;
    }
}

// Awards module

.module-awards {
    background: #fce89d;
    background: linear-gradient(to bottom, #fce89d, #f8da80);
    padding: colspan(p, 4);
    @include breakpoint-min(md) {
        display: block;
        padding: colspan(p, 2);
    }
}
@include breakpoint-min(md) {
    #level3 .module-awards {
        .module-photo {
            display: block;
            margin: 0 auto $scale-5;
            max-width: 7em;
        }
    }
}

// Donate module

.home #level3 .module-donate,
.module-donate {
    background-color: #273F57;
    color: #FFF;
    display: block;
    padding: 0;
}

.module-donate.has-photo .module-photo.is-bg {
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 52.333333333333333333%;
    /* 157 / 300 (Currently using //s3.amazonaws.com/cdn.propublica.net/deploy/images/common/donate-photo-a.png as a reference for this proportion) */
    margin: 0;
}

.donate-content {
    justify-content: space-between;
    padding: colspan(p, 2);
    @include breakpoint-min(sm) {
        background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 8%, rgba(255, 255, 255, 0.125) 8.1%, rgba(255, 255, 255, 0.125) 16%, rgba(255, 255, 255, 0.0625) 16.1%, rgba(255, 255, 255, 0.0625) 24%, rgba(255, 255, 255, 0) 24.1%);
    }
    @include breakpoint-min(md) {
        display: flex;
        padding: colspan(p, 3);
    }
    @include breakpoint-min(lg) {
        padding: colspan(p, 4);
    }
}

.module-hed {
    font-family: $fonts-hed;
    font-size: $scale-6;
    margin-bottom: $scale-2;
}

.module-hed strong {
    display: block;
    font-size: 1.1em;
}

.module-donate .btn {
    align-self: flex-end;
    font-family: $fonts-sans;
    font-size: $scale-5;
    line-height: 1;
}

#level3 {
    @include breakpoint-max(md) {
        .module-donate.has-photo {
            display: flex;
            flex-direction: row-reverse;

            .module-photo.is-bg {
                background-size: cover;
                margin: 0;
                /* specificity hack */
                padding-top: calc(52.333333333333333333% / 2);
                /* 157 / 300 (Currently using //s3.amazonaws.com/cdn.propublica.net/deploy/images/common/donate-photo-a.png as a reference for this proportion) */
                width: 50%;
            }

            .module-content {
                padding-bottom: $scale-5;
                padding-top: $scale-5;
                width: 50%;
            }
        }
    }

    .donate-content {
        background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 8%, rgba(255, 255, 255, 0.125) 8.1%, rgba(255, 255, 255, 0.125) 16%, rgba(255, 255, 255, 0.0625) 16.1%, rgba(255, 255, 255, 0.0625) 24%, rgba(255, 255, 255, 0) 24.1%);
        display: flex;
        justify-content: space-between;
        padding: colspan(p, 4);
        @include breakpoint-min(md) {
            background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.25) 10%, rgba(255, 255, 255, 0.125) 10.1%, rgba(255, 255, 255, 0.125) 20%, rgba(255, 255, 255, 0.0625) 20.1%, rgba(255, 255, 255, 0.0625) 30%, rgba(255, 255, 255, 0) 30.1%);
            display: block;
            padding: colspan(p, 2);
        }

        .module-hed {
            font-size: $scale-6;
        }

        .module-donate .btn {
            @include breakpoint-min(md) {
                font-size: $scale-4;
            }
        }
    }
}

// “Follow ProPublica” module

.module-follow .pp-module-title {
    margin-bottom: $scale-5;
}

.module-follow .pp-module-description {
    margin-bottom: $scale-5;
}

.module-follow .module-content {
    font-size: $scale-5;
}

.links-social {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $scale-5;
    @include breakpoint-min(md) {
        margin-bottom: 0;
    }
}

.links-social li {
    flex: 0 0 100%;
    margin-bottom: $scale-3;
    // margin-bottom: colspan(p,2);
    @include breakpoint-min(md) {
        flex: 0 1 calc((50% - (#{$scale-3} / 2)));

        .home & {
            flex: 0 0 100%;
        }
    }
    // @include breakpoint-min(lg) {
    // 	flex: 0 1 (50% - (colspan(p,4) / 2));
    // 	.home & {
    // 		flex: 0 0 100%;
    // 	}
    // }
    .home &:last-child {
        margin-bottom: 0;
    }
}

// In a .links-social context, .btn-social objects display as block-level elements
.links-social .btn-social {
    display: block;
}

// .social-main — “Primary” social links (e.g., FB and Twitter); extends .btn
.social-main {
    font-size: $scale-5;
    color: #fff;

    &:focus,
    &:hover {
        background: $text-color;
    }
}

.social-main .icon {
    fill: #fff;
}

// FB- and Twitter-specific styles; extends .btn, extends .social-main
.social-link-fb {
    background-color: $fb-color;

    &:focus,
    &:hover {
        background-color: darken($fb-color, 15%);
    }
}

.social-link-twitter {
    background-color: $twitter-color;

    &:focus,
    &:hover {
        background-color: darken($twitter-color, 15%);
    }
}

.social-link-youtube {
    background-color: $youtube-color;

    &:focus,
    &:hover {
        background-color: darken($youtube-color, 15%);
    }
}

.social-link-rss {
    background-color: $rss-color;

    &:focus,
    &:hover {
        background-color: darken($rss-color, 15%);
    }
}

// “In brief” module - contains short product/story title+blurb pairs, with optional photo

// .promo-in-brief .brief,
.promo-in-brief .pp-module-title {
    margin-bottom: $scale-5;
}

.promo-in-brief .brief {
    // @include clearfix;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $scale-5;
    text-decoration: none;
    border-bottom: none;

    &:focus,
    &:hover {
        color: #2187d4;
    }
}
// .promo-in-brief .brief:last-child {
// 	margin-bottom: 0;
// }

.promo-in-brief .brief-thumb {
    align-self: flex-start;
    flex-shrink: 0;
    order: 2;
    margin-left: $scale-6;
    width: colspan(1,4);
    @include breakpoint-range(sm, lg) {
        display: none;
    }
}

.promo-in-brief .brief-description {
    @include breakpoint-range(sm, lg) {
        font-size: $scale-5;
    }
}

// .promo-in-brief .has-photo .brief-description {
// 	padding-left: (colspan(1, 4) - colspan(p, 4)) + colspan(p, 12);
// 	margin-left: colspan(p, 4);
// 	@include breakpoint-range(sm, lg) {
// 		font-size: $scale-5;
// 		padding-left: 0;
// 		margin-left: 0;
// 		margin-right: $scale-5;
// 		padding-right: $scale-5;
// 	}
// 	@include breakpoint-min(lg) {
// 		padding-left: (colspan(1, 4) - colspan(p, 4)) + colspan(p, 12);
// 		margin-left: colspan(p, 4);
// 	}
// }

.promo-in-brief .hed {
    font-size: $scale-4;
    font-family: $fonts-sans;
    font-weight: bold;
    text-transform: uppercase;
}

// Most (popular | emailed) stories module
.module-popular .pp-module-title {
    margin-bottom: $scale-5;
}

.module-popular .module-content {
    font-size: $scale-5;
}

// Collapsible “tab” menu

.tabnav {
    display: flex;
    margin: 0 0 -1px;
}

.tabnav li {
    margin-right: $scale-1;
    flex: 0 1 auto;
    list-style: none;
    padding: 0;
}

.tabnav a {
    border-radius: 0.25em 0.25em 0 0;
    border: $border;
    color: $pp-blue;
    font-size: $scale-5;
    line-height: 1.15;
    display: block;
    // margin-bottom: -1px;
    padding: 0.75em;
    text-decoration: none;

    .dark & {
        border-color: hsla(0,0%,100%,0.15);
        border-bottom-color: transparent;
        color: inherit;
    }
}

.tabnav a:focus,
.tabnav a:hover {
    border-bottom: 0;
}

.tabnav .tab-active,
.tabnav .tab-active:focus,
.tabnav .tab-active:hover {
    border: $border;
    border-bottom: 1px solid #fff;
    font-weight: bold;

    .dark & {
        border-color: hsla(0,0%,100%,0.15);
        border-bottom-color: darken(desaturate($pp-blue, 13%), 13%);
    }
}

.module-popular .tabs .collapsible-expanded {
    border-top: $border;

    .dark & {
        border-color: hsla(0,0%,100%,0.15);
    }
}

// Note: specificity hack
div .list-stories {
    // border: $border;
    // border-bottom-left-radius: 0.25em;
    // border-bottom-right-radius: 0.25em;
    border-top: none;
    margin: 0;
    padding-top: 0.5rem;
    list-style-type: none;
    padding-bottom: 0.5rem;
    // list-style-type: decimal;
    list-style: none;
}

.list-stories li {
    clear: left;
    counter-increment: list-counter;
}

.list-stories a {
    border-bottom: $border;
    color: $text-color;
    display: block;
    padding: $scale-4 0 $scale-4 2.5rem;
    text-decoration: none;
    @include breakpoint-range(sm,lg) {
        padding-left: 0;
    }

    .dark & {
        border-color: hsla(0,0%,100%,0.15);
        color: inherit;
    }
}

.list-stories a:focus,
.list-stories a:hover {
    // border-color: $link-color;
    border-bottom: $border;
    color: $link-color;

    .dark & {
        border-color: hsla(0,0%,100%,0.15);
        color: white;
    }
}

.list-stories a:before {
    color: darken($bg-low-contrast, 15%);
    content: counter(list-counter);
    display: block;
    float: left;
    font-family: $fonts-sans;
    font-size: $scale-7;
    font-weight: bold;
    line-height: 1;
    margin-left: -2.5rem;
    text-align: center;
    width: 2.5rem;
    @include breakpoint-range(sm,lg) {
        color: $pp-blue;
        content: counter(list-counter) '. ';
        display: inline;
        float: none;
        font-size: inherit;
        line-height: inherit;
        margin-left: 0;
        text-align: inherit;
        width: auto;
    }

    .dark & {
        color: hsla(0,0%,100%,0.15);
    }
}

.list-stories a:focus:before,
.list-stories a:hover:before {
    color: $link-color;

    .dark & {
        color: white;
    }
}

// Pagination module

.pagination {
    clear: both;
    // font-family: $fonts-sans-initial;
    //
    // .fonts-loaded & {
    // 	font-family: $fonts-sans;
    // }
    font-family: $fonts-sans;
    font-size: $scale-5;
    padding: colspan(p,4) 0;
    margin: colspan(p,4) 0;
    border-top: $border;
    border-bottom: $border;
    @include breakpoint-min(md) {
        padding: colspan(p,5) 0;
        margin: colspan(p,5) 0;
    }
    @include breakpoint-min(lg) {
        padding: colspan(p,8) 0;
        margin: colspan(p,8) 0;
    }

    &:first-child {
        border-top: none;
        padding-top: 0;
        margin-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    ul {
        // text-align: center;
    }

    li {
        display: inline-block;
        border-radius: 0.25em;
        overflow: hidden;

        &.current {
            color: $page-bg-color;
            background: $link-color;
            padding: 0.5em 1em;
        }
    }

    a {
        display: block;
        background: $bg-low-contrast;
        padding: 0.5em 1em;
    }
}

// Month archive jump menu

.browse-by-month {
    // font-family: $fonts-sans-initial;
    //
    // .fonts-loaded & {
    // 	font-family: $fonts-sans;
    // }
    font-family: $fonts-sans;
    background-color: $bg-low-contrast;
    padding: colspan(p,4);
    margin-bottom: colspan(g,4);
    @include breakpoint-min(sm) {
        padding: colspan(p,2);
        margin-bottom: colspan(g,2);
    }
    @include breakpoint-min(md) {
        padding: colspan(p,3);
        margin-bottom: colspan(g,3);
    }
    @include breakpoint-min(lg) {
        padding: colspan(p,4);
        margin-bottom: colspan(g,4);
    }

    select {
        font-size: $scale-6;
        background-color: $page-bg-color;
        // padding: 0.5em;
        width: 100%;
        height: 2em;
        border: $border;
    }
}

// Latest Stories

.latest-stories {
    clear: both;
    width: 100%;
    // margin-top: colspan(g,4);
    padding-top: colspan(g,4);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    @include breakpoint-min(sm) {
        // margin-top: colspan(g,6);
        padding-top: colspan(g,6);
    }
    @include breakpoint-min(md) {
        // margin-top: colspan(g,8);
        padding-top: colspan(g,8);
    }
    @include breakpoint-min(lg) {
        // margin-top: colspan(g,12);
        padding-top: colspan(g,12);
    }

    .title {
        font-family: $fonts-sans;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-base;
        width: 100%;
    }

    .story-entry {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: colspan(2,4);
        margin-top: colspan(g,4);
        @include breakpoint-min(sm) {
            width: colspan(3,6);
            margin-top: colspan(g,6);
        }
        @include breakpoint-min(md) {
            width: colspan(2,8);
            margin-top: colspan(g,8);
        }
        @include breakpoint-min(lg) {
            width: colspan(3,12);
            margin-top: colspan(g,12);
        }

        .description:first-child {
            // If the story has no image
            display: flex;
            flex: 1;
            padding: colspan(p,2);
            background: $bg-low-contrast;
            // background: $pp-blue;
            @include breakpoint-min(sm) {
                padding: colspan(p,3);
            }
            @include breakpoint-min(md) {
                padding: colspan(p,2);
            }
            @include breakpoint-min(lg) {
                padding: colspan(p,3);
            }

            .hed {
                align-self: center;
                // color: white;
                font-size: $scale-7;
                padding-bottom: 1em;
            }
        }
    }
}

@media print {
    .latest-stories {
        display: none;
    }
}

// Modal dialogs

.modal {
    background-color: $bg-dark;
    color: #fff;
    font-family: $fonts-sans;
    font-size: $scale-6;
    display: none; // Suppress flash of modal as JS inits. Also covers no-JS use case when link to modal simply clicks through.

    .enhanced &.collapsible-enhanced {
        display: block;
        position: fixed;
    }

    &.collapsible-enhanced {
        z-index: 1001;
        opacity: 0;
        pointer-events: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        background-color: $bg-dark;
        // background-color: transparentize($bg-dark, 0.025);
    }

    &.collapsible-expanded {
        opacity: 1;
        pointer-events: initial;
    }

    .collapsible-header.close-btn {
        position: fixed;
        right: 1rem;
        top: 1rem;
        opacity: 0;
    }

    &.collapsible-expanded .collapsible-header {
        opacity: 1;
        cursor: pointer;
    }

    // // for vertically + horizontally centering inner content
    // .centered {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translateX(-50%) translateY(-50%);
    //     margin-left: auto;
    //     margin-right: auto;
    //
    //     form {
    //         display: block;
    //         width: 100%;
    //     }
    // }
}

// Newsletter signup modal

#modal-newsletter {
    input[type="submit"] {
        background-color: $red;
    }
}

// Search modal

#modal-search {
    // faux vertical centering
    padding-top: 45vh;

    .input-wrapper {
        width: 100%;
    }

    input[type="search"] {
        border-color: transparent;
        width: 100%;
    }
}

// Creative Commons modal

#modal-republish {
    .collapsible-content {
        & > .wrapper {
            padding-top: colspan(g,4);
            padding-bottom: colspan(g,4);
            @include breakpoint-min(md) {
                width: colspan(6,8);
                margin-left: colspan(1,8) + colspan(g,8);
            }
            @include breakpoint-min(lg) {
                width: colspan(8,12);
                margin-left: colspan(2,12) + colspan(g,12);
                padding-top: colspan(1,12);
                padding-bottom: colspan(1,12);
            }
        }

        .license{
            font-size: $scale-5;
        }

        .republish-instructions {
            .title {
                font-family: $fonts-hed;
                font-size: $scale-7;
                font-weight: bold;
                margin-top: 0.5em;
                margin-bottom: 0.33em;
            }

            .byline {
                font-size: $scale-5;
            }

            .intro {
                margin-top: 1em;
                font-size: $scale-5;
            }

            ul {
                list-style: disc outside none;
                font-size: $scale-5;
                margin-bottom: 1em;
            }

            li {
                margin-left: 1.125em;
            }

            li,
            p {
                padding-bottom: 1em;
            }
        }

        .republish-copy {
            & > p {
                font-size: $scale-5;
                margin-bottom: 0.75em;
            }

            textarea {
                width: 100%;
                height: 15vh;
                font-size: $scale-4;
                line-height: 1.25em;
            }
        }
    }
}

// Generic newsletter signup module

.form-subscribe {
    margin-top: $scale-3;
    padding-top: $scale-5;
    @include breakpoint-min(md) {
        border-top: $border;
    }

    .dark & {
        border-color: hsla(0,0%,100%,0.15);
    }

    .home & {
        display: none;
    }

    &:first-child {
        border-top: none;
        padding-top: none;
    }

    & p {
        margin-bottom: $scale-3;
    }

    & .input-text,
    & label,
    & legend {
        display: block;
    }

    & .input-text {
        padding: 0.5em;
        width: 100%;
    }

    & input[type="submit"] {
        background-color: $pp-blue;
        text-transform: none;
    }

    .form-label-set {
        position: relative;

        .input-text {
            padding-left: 1.75em;
        }

        label {
            position: absolute;
            top: 0;
            padding-top: 0.5em;
            padding-left: 0.5em;
        }

        label svg {
            fill: desaturate(lighten($pp-blue, 50%), 15%);
        }

        .input-text:focus + label svg {
            fill: $text-color;
        }
    }

    .article-footer & {
        padding-top: 0;
        margin-top: 0;
    }

    .article-footer & .hed-form {
        font-weight: normal;
    }

    .article-footer & p {
        padding-top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .article-footer & .input-text {
        flex: 1;
        border: 1px solid $pp-blue;
    }
}

// For when subscribe module appears in "Follow ProPublica" module

.module-follow .form-subscribe {
    .pp-module-title {
        margin-bottom: $scale-2;
    }

    .input-text,
    input[type="submit"] {
        font-size: $scale-5;
    }

    .input-text {
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        padding-left: 2.125em;
        border: 1px solid $pp-blue;
    }

    .form-label-set label {
        padding-top: 0.5em;
    }

    input[type="submit"] {
        // font-size: 1em;
        font-size: $scale-5;
        text-align: center;
        width: 100%;
        margin-top: $scale-3;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
    }
}

// "Slide-in" newsletter promo module (used on 'large' articles)

#newsletter-slide-in {
    top: auto; // reset
    padding: 1rem 0 0.25rem;
    transition: all 0.25s ease;
    transform: translateY(100%);

    .content-bottom-reached & {
        transform: translateY(0);
    }

    .global-footer-reached &,
    &.collapsible-collapsed {
        transform: translateY(100%);
    }

    & > .wrapper {
        position: relative;
        padding: 1rem 0;
    }

    .close-btn {
        position: absolute;
        right: 0.75rem;
        top: 0.75rem;
    }

    .close-btn .icon {
        width: 1rem;
        height: 1rem;
    }

    form {
        font-size: $scale-6;
    }

    label {
        color: white;
        font-size: $scale-5;
        font-weight: bold;
        text-align: center;
        line-height: $line-height-shorter;
        margin-bottom: 0.5em;
        @include breakpoint-min(lg) {
            font-size: $scale-6;
        }
    }

    .input-wrapper {
        width: 100%;
        border-color: white;
    }

    input[type="email"] {
        border: 0;
    }

    input[type="submit"] {
        background-color: $red;
        @include breakpoint-min(sm) {
            width: colspan(2,6);
        }
        @include breakpoint-min(md) {
            width: colspan(3,8);
        }
        @include breakpoint-min(lg) {
            width: colspan(3,10);
        }
    }

    .legal {
        display: block;
        margin-top: 1.5em;
        text-align: center;
        color: transparentize(white, 0.50);
        font-size: $scale-3;
        line-height: $line-height-base;

    }
}

// Newsletter "modal roadblock"/intersitital promo

#newsletter-roadblock {
    top: auto; // reset
    background-color: $bg-low-contrast;
    @include breakpoint-min(sm) {
        background-color: rgba(0, 0, 0, 0.85);
        // background-color: transparentize($bg-dark, 0.15);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .collapsible-content {
        padding-top: colspan(p,4);
        padding-bottom: math.div(colspan(p,4), 4); // divide to make room for legal text
        @include breakpoint-min(sm) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            max-width: 30em;
            margin: 0; // override column-setter to allow centering
            padding: 3vh 5vw 1.125vh;
            background-color: white;
            // background-color: $bg-low-contrast;
            // border: 1px solid $pp-blue;
        }
        @include breakpoint-min(xl) {
            padding: 4vh 3vw 1.5vh;
        }
    }

    .close-btn {
        position: absolute;
        top: colspan(p,4);
        right: colspan(p,4);
        opacity: 0.33;
    }

    & > .close-btn {
        @include breakpoint-min(sm) {
            display: none;
        }
    }

    .collapsible-content .close-btn {
        display: none;
        @include breakpoint-min(sm) {
            display: block;
            top: 1rem;
            right: 1rem;
        }
    }

    .hed {
        font-family: $fonts-hed;
        font-weight: bold;
        font-size: $scale-7;
        line-height: $line-height-shortest;
        color: $pp-blue;
        text-align: center;
        margin-bottom: 0.5em;
        display: none; // off by default on mobile
        @include breakpoint-min(sm) {
            display: block;
            font-size: $scale-8;
        }
    }

    .intro {
        font-family: $fonts-sans;
        font-size: $scale-5;
        color: $pp-blue;
        text-align: center;
        margin-bottom: 1em;
        padding-right: colspan(g,4);
        padding-left: colspan(g,4);
        @include breakpoint-min(sm) {
            margin-top: inherit;
            padding-right: 0;
            padding-left: 0;
        }
    }

    input[type="email"],
    input[type="text"],
    input[type="submit"] {
        display: block;
        width: 100%;
        margin-bottom: 0.5em;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        text-align: center;
    }

    input[type="submit"] {
        margin-bottom: 1em;
    }

    .close-link a {
        color: $pp-blue;
        font-family: $fonts-sans;
        font-size: $scale-5;
        text-decoration: underline;
        display: block;
        text-align: center;

        &:hover,
        &:focus {
            border-color: transparent;
        }
    }

    .legal {
        margin-top: 1.5em;
        line-height: $line-height-base;
        text-align: center;

        a {
            color: transparentize($text-color, 0.33);
        }
    }
}

// Dating Apps callout
// (This is for a one-off and would be worth reorganizing once we have a more modular styles loading solution for the site.)

.callout-datingapps {

    // Reset some article styles
    article & p { margin-bottom: 0; }
    article & ul { margin: 0; }
    article & li { list-style: none; }

    font-family: $fonts-sans;
    padding: 1em 0 3em 0;

    @include breakpoint-min(lg) {
        align-items: center;
        justify-content: space-between;
        display: flex;
        text-align: left;
        width: colspan(10, 8);
        margin-left: -(colspan(1, 8) + colspan(g, 8));
    }

    @include breakpoint-min(xl) {
        width: colspan(8, 6);
        margin-left: -(colspan(1, 6) + colspan(g, 6));
    }

    .callout-contact {
        font-size: $scale-6;

        @include breakpoint-range(xs, lg) {
            border-bottom: 1px solid black;
            margin-bottom: 1.25em;
            padding-bottom: 1.25em;
        }

        @include breakpoint-min(lg) {
            border-right: 1px solid black;
            margin-right: math.div(colspan(g, 10), 2);
            padding-right: math.div(colspan(g, 10), 2) + colspan(p, 10);
            width: colspan(5, 10) + colspan(g, 10);
        }

        @include breakpoint-min(xl) {
            border-right: 1px solid black;
            margin-right: math.div(colspan(g, 8), 2);
            padding-right: math.div(colspan(g, 8), 2) + colspan(p, 8);
            width: colspan(5, 8) + colspan(g, 8);
        }

        .contact-button {
            text-align: center;
            font-weight: bold;
            letter-spacing: 0.01em;
            padding: 0.75em 0;
            background: black;
            border: none;
            border-radius: 0.1875em;
            color: white;
            display: block;
            &:focus,
            &:hover {
                background: lighten(black, 10%);
            }
        }

        p {
            margin-top: 0.75em;
        }
    }

    .callout-share {
        color: $pp-blue;
        font-size: $scale-4;

        @include breakpoint-min(lg) {
            padding-left: colspan(p, 10);
            width: colspan(5, 10);
        }

        @include breakpoint-min(xl) {
            padding-left: colspan(p, 8);
            width: colspan(3, 8);
        }

        p {
            margin-bottom: 0.75em;
        }

        .share-options {
            display: flex;
            flex-wrap: wrap;
        }

        .share-item {
            margin-top: 0.75em;
            font-size: $scale-5;
            flex-grow: 1;

            @include breakpoint-min(lg) {
                flex-basis: 50%;
            }

            a {
                border: none;
                color: $pp-blue;
                display: block;
                &:focus,
                &:hover {
                    color: lighten($pp-blue, 10%);
                }
            }

            svg {
                display: inline-block;
                width: 1.25em;
                transform: scale(1.25) translate(-0.125em, 0.125em);
            }

            > span {
                display: block;
                // margin-top: 0.5em;
            }

        }
    }

}
