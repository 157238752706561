@use "sass:math";

// ------------------------------------------------------------
// ARTICLE
// ------------------------------------------------------------
// .v3-medium > figure,
// .article-body,
// .article-footer,

.article-wrap,
.post-article {
	> figure {
		margin-right: 0;
	}
	@include breakpoint-min(sm) {
		float: left;
		clear: left;
		width: colspan(4,6);
	}
	@include breakpoint-min(md) {
		width: colspan(5,8);
	}
	@include breakpoint-min(lg) {
		width: colspan(7,12);
		margin-left: colspan(1,12) + colspan(g,12);
	}
}

article {
	margin-bottom: 2em;
	@include breakpoint-min(md) {
		// margin-bottom: colspan(1,5);
	}
	@include breakpoint-min(lg) {
		// margin-bottom: colspan(1,8);
	}

	.article-header {
		width: 100%;
		@include breakpoint-min(sm) {
			padding-bottom: colspan(p,6);
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			padding-bottom: colspan(p,8);
			margin-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			width: colspan(11,12);
			// padding-right: colspan(1,12) + colspan(g,12);
            padding-right: 0;
			padding-bottom: colspan(g,12);
			margin-left: colspan(1,12) + colspan(g,12);
			margin-bottom: colspan(g,12);
		}
		// @include breakpoint-min(xl) {
		// 	padding-right: colspan(2,12) + colspan(g,12);
		// }
	}

    .article-header .hed {
        @include breakpoint-min(lg) {
            padding-right: colspan(1,11) + colspan(g,11);
        }
        @include breakpoint-min(lg) {
            padding-right: colspan(2,11) + colspan(g,11);
        }
    }

    .article-header .dek {
        @include breakpoint-min(lg) {
            padding-right: colspan(3,11) + colspan(g,11);
        }
    }

	.article-large & > .lead-art {
		@include breakpoint-min(lg) {
			width: colspan(11,12);
			margin: 0 0 colspan(g,12) (colspan(1,12) + colspan(g,12));
		}
	}

    a {
        color: $link-color;
        border-bottom: 1px solid rgba($link-color, 1);
        &:hover {
            border-bottom: 1px solid transparent;
        }
    }

	p {
		line-height: 1.625;
		margin-bottom: 1em;
	}

	.article-body > h1,
	.article-body > h2,
	.article-body > h3,
	.article-body > h4,
	.article-body > h5,
	.article-body > h6 {
		color: $hed-color;
		font-weight: bold;
		margin: $scale-8 0 $scale-6;

		&:first-child {
			margin-top: 0;
		}

		.dark & {
			color: white;
		}
	}

	.article-body > h1,
	.article-body > h3 {
		font-size: $scale-7;
		font-family: $fonts-sans;
		margin: $scale-9 0 $scale-6;
		@include breakpoint-min(md) {
			font-size: $scale-7;
			line-height: $line-height-shorter;
			// + p {
			// 	margin-top: $scale-4;
			// }
		}
	}

	.article-body > h2,
	.article-body > h4 {
		@include breakpoint-min(md) {
			font-size: $scale-7;
		}
	}

	.article-body > h5 {
		font-size: $scale-5;
		@include breakpoint-min(md) {
			font-size: $scale-6;
		}
	}

	.article-body > h6 {
		font-size: $scale-4;
		@include breakpoint-min(md) {
			font-size: $scale-5;
		}
	}

	blockquote {
		font-style: italic;
		margin: $scale-8 2em;
	}

	ol,
	ul {
		margin: $scale-8 2em;
		list-style-position: outside;
		list-style-image: none;
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	li ol,
	li ul {
		margin: $scale-5 0 $scale-5 2em;
	}

	table {
		width: 100%;
		text-align: left;
		border: 2px solid $border-color;
	}

	th {
		font-weight: bold;
		vertical-align: bottom;
		background: lighten($border-color,12%);

		&.sort-ascending:after {
			content: " ▼";
		}

		&.sort-descending:after {
			content: " ▲";
		}
	}

	td,
	th {
		padding: $scale-3;
		border: 2px solid $border-color;

		&.numeric {
			-webkit-font-feature-settings: "tnum" 1;
			-moz-font-feature-settings: "tnum" 1;
			-ms-font-feature-settings: "tnum" 1;
			font-feature-settings: "tnum" 1;
			text-align: right;
		}
	}

	hr {
		margin: 2em 4em;
		height: 1px;
		background: $border-color;
		outline: none;
		border: none;
	}

	code {
		font-family: $fonts-monospace;
		background-color: $bg-low-contrast;
	}

	pre {
		white-space: pre;
		overflow: scroll;
		background-color: $bg-low-contrast;
		padding: colspan(p,4);
		margin: 1em 0;
		@include breakpoint-min(md) {
			padding: colspan(p,5);
		}
		@include breakpoint-min(lg) {
			padding: colspan(p,7);
		}
	}

	nav {}

	form {}

	figcaption {
		color: $text-color-low-contrast;
		font-style: italic;

		&:last-child {
			padding: $scale-3 0;
			border-bottom: $border;
		}
	}

	figure {
		width: 100%;
		// overflow: scroll;
	}

	header + aside,
	header + figure {
		&.x-small,
		&.small,
		&.medium,
		&.large,
		&.x-large,
		 {
			// This allows
			float: left;
			margin-top: 0;
		}
	}

	aside,
	figure {
		float: left;
		clear: left;
		// font-family: $fonts-sans-initial;
		// .fonts-loaded & {
		// 	font-family: $fonts-sans;
		// }
		font-family: $fonts-sans;
		font-size: $scale-5;

		margin: math.div(colspan(p,4), 4) colspan(p,4) colspan(p,4) 0;
		@include breakpoint-min(sm) {
			margin: math.div(colspan(p,4), 4) colspan(g,4) colspan(g,4) 0;
		}
		@include breakpoint-min(md) {
			margin: math.div(colspan(p,5), 2) colspan(g,5) colspan(g,5) 0;
		}
		@include breakpoint-min(lg) {
			margin: math.div(colspan(p,7), 2) colspan(g,7) colspan(g,7) 0;
		}

		> *:first-child {
			margin-top: 0;
		}

		> *:last-child {
			margin-bottom: 0;
		}

		> h3,
		> .aside-head,
		> .figure-head {
			color: $hed-color;
			font-size: $scale-6;
			font-weight: bold;
			line-height: $line-height-shorter;

			&:first-child {
				padding-top: $scale-4;
				border-top: $border;
				margin-bottom: $scale-3;
			}

			.dark & {
				color: white;
			}
		}

		&.large > h3:first-child,
		&.large > .aside-head:first-child,
		&.x-large > h3:first-child,
		&.x-large > .aside-head:first-child, {
			// Include both h3 and .aside-head to cover headers from Craft markdown as well as templated headers
			padding-top: 0;
			border-top: 0;
		}
	}

	figure.lead-art {
		margin-top: 0;
		z-index: 10;
	}

	.global-promos {
		display: none;
		@include breakpoint-min(sm) {
			display: block;
			float: right;
			// width: colspan(2,4);
			// margin-right: -(colspan(2,4) + colspan(g,4));
			> * {
				margin-bottom: colspan(g,2);
			}
		}
		@include breakpoint-min(md) {
			// width: colspan(3,5);
			// margin-right: -(colspan(3,5) + colspan(g,5));
			> * {
				margin-bottom: colspan(g,3);
			}
		}
		@include breakpoint-min(lg) {
			// width: colspan(4,7);
			// margin-right: -(colspan(4,7) + colspan(g,7));
			> * {
				margin-bottom: colspan(g,4);
			}
		}
	}

	.global {
		width: 100%;
	}

	.global.external {
		display: none;
		@include breakpoint-min(lg) {
			display: block;

			> * {
				width: 300px;
				margin: 0 auto;
			}
		}
	}

	aside.ad-300 {
		float: none;
		clear: both;
		width: 100%;
		margin: 1em 0;
        overflow: hidden;
		@include breakpoint-min(lg) {
			width: colspan(4,7);
			float: right;
			clear: right;
			margin: 0;
			margin-right: -(colspan(4,7) + colspan(g,7));
		}

		> div {
			margin: 0 auto;
		}

		&:before {
			content: "Advertisement";
			display: block;
			font-size: $scale-4;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			padding-bottom: 0.25em;
		}
	}

    aside.newsletter {
        > .newsletter-head:first-child {
            color: $pp-blue;
            // font-size: $scale-7;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.3;
            padding-top: .625rem;
            border-top: 1px solid $pp-blue;
        }

        .form-subscribe {
            padding-top: 0;
            margin-top: $scale-4;
            border: none;
        }

        .input-text {
            border: 1px solid $pp-blue;
        }

		&.x-small,
        &.small,
        &.medium {
            .input-text {
                padding-top: 0.75em;
                padding-bottom: 0.75em;
            }

            .form-label-set label {
                padding-top: 0.75em;
            }

            input[type="submit"] {
                font-size: 1.125em;
                text-align: center;
                width: 100%;
                margin-top: $scale-3;
                padding-top: 0.75em;
                padding-bottom: 0.75em;
            }
        }

        &.large,
        &.x-large {
            padding-top: 1.5rem;
            padding-bottom: 1.75rem;

            > h3:first-child {
                margin-bottom: 0.125rem;
            }

            p {
                text-align: center;
            }

            .input-text {
                padding-top: 0.75em;
                padding-bottom: 0.75em;
            }

            .form-label-set label {
                padding-top: 0.75em;
            }

            input[type="submit"] {
                font-size: 1.125em;
                text-align: center;
                width: 100%;
                margin-top: $scale-3;
                padding-top: 0.75em;
                padding-bottom: 0.75em;
            }

            .legal {
                text-align: center;
            }
        }

        &.large,
        &.x-large {
            > h3:first-child {
                text-align: center;
                border-top: 0;
            }
        }

    }

    figure.pull-quote {
        blockquote {
            margin: 0 $scale-8;
        }

        blockquote p {
            position: relative;
            font-size: $scale-6;
            // font-style: normal;
        }

        blockquote p:first-child:before {
            content: "“";
            position: absolute;
            top: -($scale-9);
            left: -($scale-10);
            font-size: $scale-12;
            font-weight: bold;
            color: $border-color;
            // color: $text-color-low-contrast;
        }

        figcaption {
            font-style: normal;

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }

            p {
                padding-left: $scale-8;
            }

            p:first-child:before {
                content: "—";
            }
        }

        figcaption p {

        }
    }

	.x-small {
		display: none;
		@include breakpoint-min(md) {
			display: block;
			width: colspan(2,5);
		}
		@include breakpoint-min(lg) {
			width: colspan(2,7);
		}
	}

	.small {
		width: colspan(2,4);
		@include breakpoint-min(md) {
			width: colspan(2,5);
		}
		@include breakpoint-min(lg) {
			width: colspan(3,7);
		}
	}

	.medium {
		width: colspan(2,4);
		@include breakpoint-min(md) {
			width: colspan(3,5);
		}
		@include breakpoint-min(lg) {
			width: colspan(4,7);
		}
	}

	.x-small.mobile-large,
	.small.mobile-large,
	.medium.mobile-large {
		@include breakpoint-max(lg) {
			float: none;
			margin: $scale-8 0;
			width: 100%;
		}
	}

	.large {
		float: none;
		margin-bottom: $scale-8;
		margin-right: 0;
		margin-top: $scale-8;
		width: 100%;
	}

	aside.large {
		padding: $scale-6 0;
		border-top: $border;
		border-bottom: $border;
		@include breakpoint-min(lg) {
			padding-left: colspan(1,7) + colspan(g,7);
			padding-right: colspan(1,7) + colspan(g,7);
		}
	}

	.x-large {
		float: none;
		width: 100%;
		margin-top: $scale-8;
		margin-bottom: $scale-8;
		@include breakpoint-min(sm) {
			width: colspan(6,4);
		}
		@include breakpoint-min(md) {
			width: colspan(8,5);
		}
		@include breakpoint-min(lg) {
			width: colspan(11,7);
		}
	}

	.right {
		float: right;
		clear: right;
		margin-right: 0;
		margin-left: colspan(g,4);
		@include breakpoint-min(md) {
			margin-left: colspan(g,6);
		}
		@include breakpoint-min(lg) {
			margin-left: colspan(g,7);
		}
	}

	.article-header,
	.story-entry {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			font-size: inherit;
			line-height: inherit;
			text-indent: inherit;
			text-transform: inherit;
			letter-spacing: inherit;
		}
	}

	aside .story-entry {
		@include clearfix;
		margin: $scale-6 0;

		&:first-child {
			margin-top: 0;
		}

		.lead-art.thumbnail {
			margin: 0 0 $scale-5 $scale-5;
		}

		.lead-art.thumbnail + .description {
			margin-top: 0;
		}

		.metadata,
		.series-header {
			font-size: $scale-4;
		}

		.hed {
			font-size: $scale-6;
		}

		.dek {
			font-size: $scale-5;
			font-weight: normal;
			margin-bottom: 0;
		}
	}

	aside.x-small .story-entry {
		.lead-art.thumbnail {
			display: none;
		}

		.hed {
			font-size: $scale-5;
		}

		.hed + .dek {
			margin-top: 0;
		}
	}

	aside.small .story-entry {
		.lead-art.thumbnail {
			display: none;
			@include breakpoint-min(lg) {
				display: block;
				float: right;
				width: colspan(1,3);
			}
		}

		.hed {
			font-size: $scale-5;
		}

		.hed + .dek {
			margin-top: 0;
		}
	}

	aside.medium .story-entry {
		.lead-art.thumbnail {
			display: none;
			@include breakpoint-min(md) {
				display: block;
				float: right;
				width: colspan(1,3);
			}
			@include breakpoint-min(lg) {
				width: colspan(1,4);
			}
		}

		&:first-of-type .hed {
			@include breakpoint-min(md) {
				font-size: $scale-7;
				line-height: $line-height-shorter;
			}
		}
	}

	aside.large .story-entry {
		.lead-art {
			@include breakpoint-min(md) {
				float: right;
				margin-bottom: $scale-5;
				margin-left: $scale-5;
				width: colspan(2,5);
			}
		}

		.lead-art.thumbnail {
			float: right;
			width: colspan(1,4);
			@include breakpoint-min(md) {
				width: colspan(1,5);
			}
		}

		.lead-art + .description {
			@include breakpoint-min(md) {
				margin-top: 0;
			}
		}

		.hed {
			font-size: $scale-6;
			line-height: $line-height-shorter;
		}

		&:first-of-type .hed {
			font-size: $scale-7;
			line-height: $line-height-shorter;
		}
	}

    .top-notes,
    .bottom-notes {
        font-size: 0.875em;
        margin-bottom: 1.25em;
    }

	.top-notes {}

    .bottom-notes {
        margin-top: 1.5em;
    }

	.note {
		font-style: italic;

		&.co-publish {}

		&.correction {}
	}

	.topics-list {
		font-family: $fonts-sans;
		margin: 2em 0;

		p,
		ul {
			display: inline;
			margin-left: 0;
		}

		li {
			display: inline-block;

			&:after {
				display: inline;
				content: ",";
			}

			&:last-of-type:after {
				content: "";
			}
		}

		a {
			// display: block;
		}
	}


    .event-metadata {
        font-family: $fonts-sans;
        font-size: $scale-5;
        text-align: center;
        margin-bottom: 2em;
        @include breakpoint-min(lg) {
            clear: left;
            float: left;
            margin-bottom: colspan(g,7);
            margin-right: colspan(g,7);
            width: colspan(3,7);
        }

        *:last-child {
            margin-bottom: 0;
        }

        .event-metadata-wrapper {
            padding: colspan(p,4);
            border-radius: 0.25em;
            background-color: hsla(205, 40%, 20%, 1);
            color: white;

            @include breakpoint-min(lg) {
                padding: colspan(p,3);
                // padding-top: colspan(p,3) * 1.5;
                padding-bottom: colspan(p,3) * 1.25;
            }

            h3 {
                font-weight: normal;
                font-size: $scale-4;
                text-transform: uppercase;
                letter-spacing: 0.25em;
                margin-bottom: 1em;
                padding-bottom: 0.5em;
                border-bottom: 1px solid hsla(205, 30%, 30%, 1);
            }

            .event-date {
                font-size: $scale-7;
                font-weight: bold;
                margin-bottom: 0;
                text-transform: uppercase;
            }

            .event-more-info-btn {
                display: block;
                padding: 0.25em 0.75em;
                color: white;
                text-align: center;
                background-color: hsla(205, 70%, 40%, 1);
                border: none;
                border-radius: 0.1875em;
                &:hover {
                    background: $link-color;
                }
            }
        }

        .event-supporter-wrapper {
            padding: colspan(p,4);
            @include breakpoint-min(lg) {
                padding: colspan(p,3);
            }

            p {
                font-size: $scale-4;
                line-height: $line-height-base;
            }

            img {
                width: colspan(2,4);
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0.33em;
                @include breakpoint-min(lg) {
                    width: colspan(2,3);
                }
                @include breakpoint-min(xl) {
                    width: 50%;
                }
            }
        }
    }


    .article-footer {
        clear: both;
        margin-top: 2.5rem;

        > * + * {
            margin-top: 2rem;
        }

        .newsletter {
            margin-bottom: 3rem;
            padding: 1.5rem colspan(p,4);
            border: 1px solid $pp-blue;
            color: $pp-blue;
            font-family: $fonts-sans;
            font-size: $scale-5;
            @include breakpoint-min(md) {
                padding: 1.5rem colspan(p,5);
            }
            @include breakpoint-min(lg) {
                padding: 1.5rem colspan(p,7);
            }

            .pp-module-title {
                text-transform: none;
                text-align: center;
                font-size: $scale-6;
                line-height: 1.2;
                margin-bottom: 1em;
            }

            .input-text {
                padding: 0.75em 0;
                text-align: center;
            }

            .form-label-set label {
                padding-top: 0.75em;
            }

            input[type="submit"] {
                font-size: 1.125em;
                text-align: center;
                width: 100%;
                margin-top: $scale-3;
                padding-top: 0.75em;
                padding-bottom: 0.75em;
            }

			.legal {
				display: block;
				text-align: center;
			}

            .error,
            .success {
                font-style: italic;
            }

            .error {
                color: $red;
            }
        }

        .fundraising-letter-inline {
            background-color: $bg-low-contrast;
            padding: colspan(g,4);
            @include breakpoint-min(md) {
                padding: colspan(g,5);
            }
            @include breakpoint-min(lg) {
                padding: colspan(g,7);
            }

            .pp-module-title {
                font-size: $scale-7;
                letter-spacing: 0;
                text-align: center;
                text-transform: none;
                font-family: $fonts-hed;
                margin-bottom: 1em;
            }

            .module-content p {
                font-size: $scale-6;
                line-height: $line-height-base;
            }

            .btn-urgent {
                width: 100%;
                text-align: center;
                line-height: $line-height-taller;
                margin-top: 1em;

                &:hover,
                &focus {
                    border-bottom: none;
                    color: white;
                }
            }
        }

    }

	.author-bio {
		clear: both;
		font-family: $fonts-sans;
		font-size: $scale-5;

		margin-top: colspan(g,4);
		@include breakpoint-min(md) {
			margin-top: colspan(g,5);
		}
		@include breakpoint-min(lg) {
			margin-top: colspan(g,7);
		}

		> * + * {
			margin-top: 1em;
		}

		.thumbnail {
			display: block;
			float: left;
			width: colspan(1,4);
			margin: 0 colspan(p,4) colspan(p,4) 0;
			border-radius: 50%;
			border: 0;
			overflow: hidden;
			@include breakpoint-min(md) {
				width: colspan(1,5);
				margin: 0;
			}
			@include breakpoint-min(lg) {
				width: colspan(1,7);
			}
		}

		.thumbnail + .info,
		.thumbnail + .info + .contact {
			@include breakpoint-min(md) {
				width: colspan(4,5);
				margin-left: colspan(1,5) + colspan(g,5);
			}
			@include breakpoint-min(lg) {
				width: colspan(6,7);
				margin-left: colspan(1,7) + colspan(g,7);
			}
		}

		.author-name {
			color: $hed-color;
			font-size: $scale-6;
			font-weight: bold;
			margin-bottom: 0;

			a {
				color: inherit;
				border: 0;
                text-decoration: none;
			}

			a:hover {
				color: $link-color;
			}
		}

		.short-bio {
			margin: 0;
		}

		.contact {
			color: $pp-blue;
			// font-family: $fonts-sans-initial;
            //
			// .fonts-loaded & {
			// 	font-family: $fonts-sans;
			// }
            font-family: $fonts-sans;
			line-height: 1;
			margin: 1em 0 0;

			li {
				display: inline-block;
				margin: 0 0.2em 0.2em 0;
				border-radius: 0.15em;
				overflow: hidden;
			}

			a {
				color: inherit;
				text-decoration: inherit;
				border: none;

				&:focus .author-name,
				&:hover .author-name {
					color: $link-color;
				}
			}

			.contact-method {
				display: block;
				background: $bg-low-contrast;
				padding: 0.25em 0.65em 0.25em 0.5em;
			}

			a.contact-method:focus,
			a.contact-method:hover {
				color: white;
				background: $link-color;

				.icon {
					fill: white;
				}
			}

			.icon {
				display: inline;
				vertical-align: middle;
				width: $scale-5;
				fill: $pp-blue;
				margin-right: 0.15em;
			}
		}
	}

}

// ARTICLE V3 (SPLIT)

.article-split {
	@include breakpoint-min(lg) {
		.masthead-wrapper {
			position: absolute;
			top: 0;
			background-color: transparent;

			.masthead {
				border-bottom: none;
			}
		}

		main {
			margin-top: 0;
		}
	}
}

.article-split .logo:after {
	display: none !important;
}

.article-split .article-header {
	@include grid(content);

	@include breakpoint-min(lg) {
		// This is normally 11 columns wide, but we’ll make it 12 columns
		margin-left: 0;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		min-height: 100vh;

		.lead-art,
		.aspect-1-1 {
			bottom: 0;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;

			width: 100%;
			min-height: 100vh;
		}

		.lead-art {
			left: colspan(4,12) + colspan(g,12);
			width: calc(50vw + #{colspan(2,12) + math.div(colspan(g,12), 2)}); // 50% of viewport + 2/12 columns + half a gutter

			img,
			video {
				object-fit: cover;
			}

			.caption { display: none; }
		}

		.description {
			margin-bottom: 0;
			margin-top: auto;

			@include breakpoint-min(sm) {
				margin-top: colspan(2,6);
			}
			@include breakpoint-min(md) {
				margin-top: colspan(3,8);
			}
			@include breakpoint-min(lg) {
				margin-top: colspan(3,12);
			}
			@include breakpoint-min(xxl) {
				margin-top: colspan(3,12);
			}
		}

		.series-header,
		.hed,
		.dek,
		.metadata {
			width: colspan(4,12);
			padding-right: 0;
		}
	}

	.series-header {
		margin-bottom: 0.75rem;
	}

	.hed {
		@include breakpoint-min(lg) {
			font-size: $scale-9;
		}
	}

	.dek {
		@include breakpoint-min(lg) {
			font-size: $scale-6;
		}
	}

	.metadata {
		@include breakpoint-min(lg) {
			font-size: $scale-5;
		}
	}

	+ .caption {
		display: none;

		@include breakpoint-min(lg) {
			display: block;
		}

		font-family: $fonts-sans;
		font-size: $scale-4;
		max-width: 70ch;
		margin: colspan(p,4) 0 colspan(p,4) auto;
		text-align: right;

		&::before {
			content: "Above: ";
		}

		html[lang="es"] &::before {
			content: "Arriba: ";
		}

		@include breakpoint-min(sm) {
			margin-top: colspan(p,6);
			margin-bottom: colspan(p,6);
		}
		@include breakpoint-min(md) {
			margin-top: colspan(p,8);
			margin-bottom: colspan(p,8) * 2;
		}
		@include breakpoint-min(lg) {
			margin-top: colspan(p,12);
			margin-bottom: colspan(g,12) * 2;
		}
	}

	// Since share tools have been moved out of the header and into the content:
	// - on mobile, the border is added to the share tools and removed from the header
	// - on desktop, positioning tweaks to keep in the same place

	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;

	@include breakpoint-min(sm) {
		padding-bottom: colspan(p,6);
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		padding-bottom: colspan(p,8);
		margin-bottom: colspan(g,8);
	}
	@include breakpoint-min(lg) {
		padding-bottom: colspan(g,12);
		margin-bottom: colspan(g,12);
	}

	@include breakpoint-min(lg) {
		border-bottom: $border;
	}

	+ .caption + .story-tools {
		margin-top: colspan(p,4);
		margin-bottom: colspan(g,4);
		padding-bottom: $scale-6;
		border-bottom: 1px solid $border-color;

		@include breakpoint-min(sm) {
			margin-top: colspan(p,6);
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			margin-top: colspan(p,8);
			margin-bottom: colspan(g,8);
		}

		@include breakpoint-min(lg) {
			margin: 0;
			top: auto;
			left: initial;
			width: colspan(1,12);
			padding-bottom: 0;
			border-bottom: none;
		}
	}

}

// ARTICLE V3 (FULL)

@supports (object-fit: cover) {

	.article-full .article-header {
		@include grid(description-full);
		@include full-width;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: 0;
		min-height: calc(100vh);
		padding: 0;

		a:focus,
		a:hover {
			border-color: currentColor;
			color: inherit;
		}

		.lead-art,
		.aspect-3-2 {
			bottom: 0;
			left: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
		}

		.lead-art img {
			object-fit: cover;
		}

		.lead-art figcaption {
			display: none;
		}

		.description-wrap {
			background: linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0));
			@include breakpoint-max(sm) {
				padding-top: colspan(3,5);
			}
		}

		.description {
			margin-bottom: colspan(p,4);
			margin-top: auto;
			z-index: 2;

			@include breakpoint-min(sm) {
				margin-top: colspan(2,6);
				margin-bottom: colspan(g,6);
			}
			@include breakpoint-min(md) {
				margin-top: colspan(3,8);
				margin-bottom: colspan(1,8) * 0.66;
			}
			@include breakpoint-min(lg) {
				margin-top: colspan(3,12);
				margin-bottom: colspan(1,12) * 0.66;
			}
			@include breakpoint-min(xxl) {
				margin-top: colspan(3,12);
				// margin-bottom: colspan(1,12) * 0.66;
				margin-bottom: math.div($col, (($col * 12) + ($gut * 11))) * $max-width * 0.66;
			}
		}

		.series-header {
			margin-left: 0;
		}

		.series-header a,
		.hed,
		.dek,
		.byline,
		.timestamp {
			color: white;
			filter: drop-shadow(1px 1px 0px rgba(0,0,0,0.6));
		}

		.story-tools {
			color: white;

			@include breakpoint-min(lg) {
				position: static;
				width: auto;
			}

			li {
				display: inline-block;
				margin: 0 $scale-5 0 0;
				width: $scale-6;

				@include breakpoint-min(md) {
					width: $scale-6;
				}
			}
		}

		+ .caption {
			font-family: $fonts-sans;
			font-size: $scale-4;
			max-width: 70ch;
			margin: colspan(p,4) auto colspan(p,4) 0;
			// text-align: right;

			&::before {
				content: "Above: ";
			}

			html[lang="es"] &::before {
				content: "Arriba: ";
			}

			@include breakpoint-min(sm) {
				margin-top: colspan(p,6);
				margin-bottom: colspan(p,6);
			}
			@include breakpoint-min(md) {
				margin-top: colspan(p,8);
				margin-bottom: colspan(p,8) * 2;
			}
			@include breakpoint-min(lg) {
				margin-top: colspan(p,12);
				margin-bottom: colspan(g,12) * 2;
			}
		}

		+ .caption + .story-tools {
			margin-top: colspan(p,4);
			margin-bottom: colspan(g,4);

			@include breakpoint-min(sm) {
				margin-top: colspan(p,6);
				margin-bottom: colspan(g,6);
			}
			@include breakpoint-min(md) {
				margin-top: colspan(p,8);
				margin-bottom: colspan(g,8);
			}
			@include breakpoint-min(lg) {
				left: auto;
				margin: 0;
				top: auto;
				// width: colspan(1,12);
				width: auto;
			}
		}
	}
}

// CALLOUT V3 MINIMAL

.callout-minimal {

	.story-tools {
		@include breakpoint-min(lg) {
			position: static;
			width: auto;
			margin-top: 1rem;

			li {
				display: inline-block;
				margin: 0 $scale-5 0 0;
				width: $scale-6;
			}
		}

		ul > li {
			width: auto;
		}
		ul > li > a {
			display: flex;
			height: 1rem;
		}
		.icon {
			width: auto;
		}
		.icon-label {
			display: block;
			margin-left: $scale-1;
			font-size: $scale-4;
			line-height: 1.75;
			font-family: $fonts-sans;
			font-weight: 700;
		}
	}

	.callout-messaging.wrapper {
		border-bottom: $border;

		.story-tools {
			margin-top: 0;
		}
		section {
			padding-bottom: $scale-6;
		}
		&.has-button {
			@include breakpoint-min(lg) {
				display: flex;
				align-items: stretch;
				border-bottom: none;
				padding-bottom: none;

				section {
					display: flex;
					flex-direction: column;
					justify-content: center;
					flex: 1;
					padding-bottom: 0;
				}
				section:nth-of-type(1) {
					padding-right: 1rem;
				}
				section:nth-of-type(2) {
					padding-left: 1rem;
					border-left: $border;

					.callout-share-prompt,
					ul {
						text-align: left;
					}
				}
			}
		}
		& > section:first-of-type::before {
			content: '';
			display: block;
			height: 1px;
			background-color: $border-color;
			margin: 0 auto $scale-6;
			width: colspan(2,4);

			@include breakpoint-min(sm) {
				width: colspan(2,6);
			}
			@include breakpoint-min(md) {
				width: colspan(2,8);
			}
			@include breakpoint-min(lg) {
				width: colspan(2,10);
			}
			@include breakpoint-min(xl) {
				width: colspan(2,8);
			}
		}
		&.has-button > section:first-of-type::before {
			@include breakpoint-min(lg) {
				display: none;
			}
		}
		.callout-button {
			display: block;
			padding: $scale-2;
			border-radius: 3px;
			background-color: black;
			color: white;
			font-family: $fonts-sans;
			font-size: $scale-5;
		}
		.callout-button-prompt {
			display: block;
			font-family: $fonts-sans;
			font-size: $scale-5;

			a {
				display: inline;
				text-decoration: underline;
			}
		}
		.callout-button + .callout-button-prompt {
			margin-top: $scale-2;
		}
		.callout-share-prompt {
			display: block;
			margin-bottom: $scale-2;
			font-size: $scale-4;
			font-family: $fonts-sans;
			color: $text-color-low-contrast;
		}
	}

	.callout-inactive-message {
		background-color: $color-neutral-cool-10;
		border-radius: 4px;
		padding: 1rem;
	}

	.article-header {
		text-align: center;
		border: none;

		@include breakpoint-min(sm) {
			padding-bottom: 0;
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			padding-bottom: 0;
			margin-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			padding-bottom: 0;
			margin-bottom: colspan(g,12);
			width: colspan(10,12);
			margin: 0 auto;
			margin-bottom: inherit;
		}
		@include breakpoint-min(xl) {
			width: colspan(8,12);
		}

		.hed,
		.dek {
			padding-right: 0;
		}

	}

	.article-wrap,
	.post-article {

		@include breakpoint-min(sm) {
			float: none;
			margin: 0 auto;
			width: colspan(6,6);
		}
		@include breakpoint-min(md) {
			width: colspan(6,8);
		}
		@include breakpoint-min(lg) {
			width: colspan(8,12);
		}
		@include breakpoint-min(xl) {
			width: colspan(6,12);
		}
	}

}

.post-article {
	> * + * {
		margin-top: 2em;
	}
}

.related-stories {
	clear: both;
	margin: colspan(1,4) 0;
	border-bottom: $border;
	@include breakpoint-min(md) {
		margin: colspan(1,5) 0;
	}
	@include breakpoint-min(lg) {
		margin: colspan(1,7) 0;
	}

	.title {
		// font-family: $fonts-sans-initial;
        //
		// .fonts-loaded & {
		// 	font-family: $fonts-sans;
		// }
        font-family: $fonts-sans;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: $letter-spacing-base;
		margin-bottom: colspan(p,4);
		@include breakpoint-min(md) {
			margin-bottom: colspan(p,5);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(p,7);
		}
	}
}

.related-stories .story-entry {
	@include clearfix;
	clear: both;
	padding: colspan(p,4) 0;
	border-top: $border;
	@include breakpoint-min(md) {
		padding: colspan(p,5) 0;
	}
	@include breakpoint-min(lg) {
		padding: colspan(p,7) 0;
	}

	.lead-art {
		float: right;
		width: colspan(1,4);
		margin-left: $scale-6;
		margin-bottom: $scale-6;
		@include breakpoint-min(md) {
			width: colspan(1,5);
		}
		@include breakpoint-min(lg) {
			width: colspan(1,7);
		}
	}

	.lead-art + .description {
		margin-top: 0;
		// margin-left: colspan(1,4) + colspan(g,4);
		// @include breakpoint-min(md) {
		// 	margin-left: colspan(1,5) + colspan(g,5);
		// }
		// @include breakpoint-min(lg) {
		// 	margin-left: colspan(1,7) + colspan(g,7);
		// }
	}
}

#comments {
	clear: both;
}

.article.article-legacy {
	article {
		width: 300px;
		margin-left: auto;
		margin-right: auto;
		@include clearfix;
		// @media screen and (min-width: 480px) and (max-width: 800px) {
		@include breakpoint-range(md,lg) {
			width: 630px;
		}
		// @media screen and (min-width: 800px) {
		@include breakpoint-min(lg) {
			width: 960px;
		}
	}

	.article-wrap {
		width: 100%;
		@media screen and (min-width: 800px) {
			width: 630px;
			float: left;
			margin-left: 0;
		}
	}

	.article-body,
	.article-header {
		margin-left: 0;
		margin-right: 0;
	}

	.global-promos {
		display: none;
		// @media screen and (min-width: 800px) {
		@include breakpoint-min(lg) {
			display: block;
			width: 300px;
			float: right;
		}
	}

	.callout .pp-int-hed {
		font-family: $fonts-hed;
		font-weight: bold;

		a {
			color: inherit;

			&:focus,
			&:hover {
				color: $link-color;
			}
		}
	}

	article + .post-article {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		// @media screen and (min-width: 800px) {
		@include breakpoint-min(lg) {
			float: none;
			width: 960px;
			padding-right: 330px;
		}
	}
}

.article.article-legacy-special {
	article {
		width: 300px;
		margin-left: auto;
		margin-right: auto;
		@include clearfix;
		// @media screen and (min-width: 480px) and (max-width: 800px) {
		@include breakpoint-range(md,lg) {
			width: 630px;
		}
		// @media screen and (min-width: 800px) {
		@include breakpoint-min(lg) {
			width: 960px;
		}
	}

	.article-wrap {
		width: 100%;
		@media screen and (min-width: 800px) {
			// width: 630px;
			width: 930px;
			float: left;
			margin-left: 0;
		}
	}

	.article-body,
	.article-header {
		margin-left: 0;
		margin-right: 0;
	}

	.article-body img {
		width: auto;
		display: inline-block;
	}

	.article-header {
		padding-right: 0;
	}

	.global-promos {
		display: none;
		// @media screen and (min-width: 800px) {
		// @include breakpoint-min(lg) {
		// 	display: block;
		// 	width: 300px;
		// 	float: right;
		// }
	}

	.callout .pp-int-hed {
		font-family: $fonts-hed;
		font-weight: bold;

		a {
			color: inherit;

			&:focus,
			&:hover {
				color: $link-color;
			}
		}
	}

	.article-footer,
	article + .post-article {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		// @media screen and (min-width: 800px) {
		@include breakpoint-min(lg) {
			float: none;
			width: 960px;
			padding-right: 330px;
		}
	}
}

@media print {
	article {
		.article-body,
		.article-footer,
		.article-header,
		.lead-art {
			font-size: $scale-6;
			float: none;
			width: 35em;
			padding: 0;
			margin: 2em auto;
			border: none;
		}

		.ad-300,
		.global-promos,
		.newsletter,
		.promo,
		.story-tools {
			display: none;
		}

		figure {
			page-break-inside: avoid;
		}

		.x-large {
			width: 100%;
		}

		.article-header .metadata {
			color: black;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			page-break-after: avoid;
		}

		hr {
			border-bottom: 0.5pt solid black;
		}

		.article-body a {
			color: black;
			border-bottom: 0.5pt solid black;

			// &[href^=http]:after {
			// 	font-family: $fonts-sans;
			// 	font-size: $scale-5;
			// 	font-style: italic;
			// 	border-color: white;
			// 	content: " <" attr(href) ">";
			// }
		}

		.image figcaption,
		.lead-art figcaption {
			font-size: $scale-5;
			color: black;
			border: none;
		}

		.author-bio .contact {
			color: black;

			.contact-method {
				padding-left: 0;
			}

			.icon {
				fill: black;
			}
		}
	}

	.post-article {
		display: none;
	}
}
