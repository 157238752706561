article {
    aside.canned-promo.partnership-texastribune {
        @include breakpoint-max(sm) {
            width: 100%;
        }
        .dek {
            margin-top: $scale-4;

            a {
                font-weight: 700;
                border: none;
                text-decoration: underline;
            }
        }
    }
}
