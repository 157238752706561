.home-illinois .wrapper,
.home-texas .wrapper {
    @include breakpoint-min(lg) {
        margin: 0 colspan(2,12) + colspan(g,12);
    }
}

.home-illinois .features,
.home-texas .features {
    @include breakpoint-min(lg) {
        width: colspan(12,8);
        margin-left: -(colspan(2,8) + colspan(g,8));
    }
}

.home-places .features,
.home-illinois .features,
.home-local-reporting-network .features,
.home-texas .features {
    border-bottom: $border;
    margin-bottom: colspan(g,4);
    @include breakpoint-min(sm) {
        margin-bottom: colspan(g,6) * 2;
    }
    @include breakpoint-min(md) {
        margin-bottom: colspan(g,8) * 2;
    }
    @include breakpoint-min(lg) {
        margin-bottom: colspan(g,12) * 2;
    }
}

.home-local-reporting-network .features > p.more-stories-link {
    font-family: $fonts-sans;
    // font-size: $scale-7;
    text-align: center;
    @include breakpoint-range(xs,md) {
        clear: both;
        margin-left: 0;
        width: 100%;
    }

    a {
        background: $bg-low-contrast;
        border: none;
        border-radius: 0.25em;
        color: inherit;
        display: block;
        padding: 0.5em;
        transition: 0.2s;

        &:focus,
        &:hover {
            background: $link-color;
            color: white;
        }
    }
}

.home-places,
.home-illinois,
.home-local-reporting-network,
.home-texas {
    .features {
        @include breakpoint-min(lg) {
            @include clearfix;
        }

        .timestamp {
            white-space: nowrap;
        }
    }

    .features > *:nth-child(1) {
        @include clearfix;
        margin-bottom: colspan(g,4);
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g,6);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g,8);
        }
        @include breakpoint-min(lg) {
            display: flex;
            justify-content: space-between;
            padding-bottom: colspan(g,12);
            margin-bottom: colspan(g,12);
            border-bottom: $border;
        }

        .lead-art {
            @include breakpoint-min(lg) {
                // float: right;
                order: 2;
                width: colspan(6,12);
            }
        }

        .description {
            @include breakpoint-min(lg) {
                text-align: center;
                width: colspan(8,12);
                margin-left: auto;
                margin-right: auto;
            }
        }

        .hed {
            font-size: $scale-9;
            line-height: $line-height-shortest;
            @include breakpoint-min(sm) {
                font-size: $scale-10;
                margin-bottom: $scale-5;
            }
            @include breakpoint-min(lg) {
                margin-bottom: $scale-6;
            }
        }

        .dek {
            @include breakpoint-min(md) {
                padding-right: colspan(1,7) + colspan(g,7);
            }
            @include breakpoint-min(lg) {
                width: colspan(6,8);
                padding-right: 0;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .lead-art + .description {
            text-align: left;
            margin-top: $scale-4;
            @include breakpoint-min(sm) {
                padding-right: colspan(1,6) + colspan(g,6);
            }
            @include breakpoint-min(md) {
                padding-right: colspan(1,8) + colspan(g,8);
            }
            @include breakpoint-min(lg) {
                align-self: center;
                order: 1;
                width: colspan(6,12);
                padding: 0;
                margin-top: 0;
                margin-left: 0;
            }

            .hed {
                font-size: $scale-7;
                line-height: $line-height-shorter;
                @include breakpoint-max(lg) {
                    margin-bottom: 0;
                }
                @include breakpoint-min(sm) {
                    font-size: $scale-9;
                }
                @include breakpoint-min(md) {
                    font-size: $scale-8;
                    // line-height: $line-height-shortest;
                }
                @include breakpoint-min(lg) {
                    font-size: $scale-9;
                }
                @include breakpoint-min(xl) {
                    font-size: $scale-10;
                    line-height: $line-height-shortest;
                }
            }

            .dek {
                @include breakpoint-min(lg) {
                    width: 100%;
                    padding-right: colspan(1,6) + colspan(g,6);
                }
            }
        }
    }

    .features > *:nth-child(2),
    .features > *:nth-child(3),
    .features > *:nth-child(4),
    .features > *:nth-child(5),
    .features > *:nth-child(6) {
        float: left;
        width: colspan(2,4);
        margin-left: colspan(g,4);
        margin-bottom: colspan(g,4);
        @include breakpoint-min(sm) {
            width: colspan(3,6);
            margin-left: colspan(g,6);
            margin-bottom: colspan(g,6);
        }

        .hed {
            @include breakpoint-min(md) {
                font-size: $scale-7;
            }
        }

        .lead-art + .description .hed {
            @include breakpoint-min(md) {
                font-size: $scale-6;
            }
        }
    }

    .features > *:nth-child(2),
    .features > *:nth-child(4) {
        clear: left;
        margin-left: 0;
    }

    .features > *:nth-child(2) {
        @include breakpoint-min(md) {
            width: colspan(3,8);
            padding-right: colspan(g,8);
            margin-bottom: colspan(g,8);
            border-right: $border;
        }
        @include breakpoint-min(lg) {
            width: colspan(4,12);
            padding-right: colspan(g,12);
            margin-bottom: colspan(g,12);
        }
    }

    .features > *:nth-child(3),
    .features > *:nth-child(4),
    .features > *:nth-child(5),
    .features > *:nth-child(6) {
        @include breakpoint-min(md) {
            float: right;
            clear: right;
            width: colspan(5,8);
            margin-left: 0;
            margin-bottom: colspan(g,8);
        }
        @include breakpoint-min(lg) {
            width: colspan(8,12);
            margin-bottom: colspan(g,12);
        }

        .lead-art {
            @include breakpoint-min(md) {
                float: left;
                clear: left;
                width: colspan(2,5);
                margin-right: colspan(g,5);
                margin-bottom: colspan(p,5);
            }
            @include breakpoint-min(lg) {
                width: colspan(2,8);
                margin-right: colspan(g,8);
                margin-bottom: colspan(p,8);
            }
        }

        .lead-art + .description {
            @include breakpoint-min(md) {
                // float: left;
                // width: colspan(6,8);
                margin-top: 0;
                // margin-left: colspan(g,8);
            }
        }
    }
}

// .home-places,
.home-texas {
    .features > *:nth-child(2) {
        @include breakpoint-max(md) {
            @include clearfix;
            width: 100%;
            margin-bottom: colspan(g,4);
        }
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g,6);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g,8);
        }
    }

    .features > *:nth-child(3),
    .features > *:nth-child(5) {
        @include breakpoint-max(md) {
            clear: left;
            margin-left: 0;
        }
    }

    .features > *:nth-child(4),
    .features > *:nth-child(6) {
        clear: none;
        margin-left: colspan(g,4);
        @include breakpoint-min(sm) {
            margin-left: colspan(g,6);
        }
    }
}

.home-places,
.home-illinois,
.home-texas {
    // .introduction {
    // 	margin: colspan(1,4) 0;
    // 	@include breakpoint-min(sm) {
    // 		font-size: $scale-7;
    // 		margin: colspan(1,6) 0;
    // 	}
    // 	@include breakpoint-min(md) {
    // 		margin: colspan(1,8) 0;
    // 	}
    // }
    .featured-title {
        font-family: $fonts-sans;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-more;
        margin: colspan(g,4) 0;
        @include breakpoint-min(sm) {
            margin: colspan(g,6) 0;
        }
        @include breakpoint-min(md) {
            margin: colspan(g,8) 0;
        }
    }

    .river {
        margin: colspan(g,4) 0 2rem;
        @include breakpoint-min(sm) {
            margin: colspan(g,6) 0 3rem;
        }
        @include breakpoint-min(md) {
            margin: colspan(g,8) colspan(1,8) 4rem colspan(g,8);
        }
    }

    .river .story-entry {
        border-top: $border;
        padding: colspan(p,4) 0;
        @include clearfix;
        @include breakpoint-min(sm) {
            padding: colspan(p,6) 0;
        }

        &:first-of-type {
            border-top: 0;
            padding-top: 0;
        }

        .lead-art {
            float: right;
            width: colspan(1,4);
            margin-left: colspan(g,4);
            margin-bottom: $scale-6;
            @include breakpoint-min(sm) {
                width: colspan(1,6);
                margin-left: colspan(g,6);
            }
        }

        .lead-art + .description {
            // float: left;
            // width: colspan(3,4);
            margin-top: 0;
            // margin-left: colspan(g,4);
            // @include breakpoint-min(sm) {
            // 	width: colspan(5,6);
            // 	margin-left: colspan(g,6);
            // }
        }
    }

    .promo-modules {
        margin: 2em 0;
        padding-top: 2em;
        border-top: $border;
        // @include breakpoint-min(sm) {
        // 	margin: colspan(1,6) 0;
        // }
        // @include breakpoint-min(md) {
        // 	margin: colspan(1,8) 0;
        // }
        .subscribe-email {
            display: block;
            text-align: center;
            margin: 2em auto;
            @include breakpoint-min(md) {
                width: colspan(6,8);
            }

            .hed-subscribe {
                color: $hed-color;
                font-size: $scale-6;
                margin-bottom: 1em;
            }

            input {
                font-family: $fonts-sans;
                padding: 1em;
            }
        }
    }

    .promo-social {
        @include clearfix;
        font-family: $fonts-sans;
        text-align: center;

        > * {
            display: block;
            margin: 1em 0;
            @include breakpoint-min(md) {
                width: colspan(4,8);
            }
        }

        a {
            display: block;
            width: 100%;
            // padding: colspan(p,2);
            padding: 0.5em;
            // line-height: $line-height-shorter;
            border-radius: 0.25em;
            color: white;
            @include breakpoint-min(sm) {
                // padding: colspan(p,3);
            }
            @include breakpoint-min(md) {
                // padding: colspan(p,4);
            }
        }

        .twitter {
            @include breakpoint-min(md) {
                float: right;
            }

            a {
                background-color: $twitter-color;

                &:focus,
                &:hover {
                    color: white;
                    background-color: darken($twitter-color, 10%);
                }
            }
        }

        .facebook {
            @include breakpoint-min(md) {
                float: left;
            }

            a {
                background-color: $fb-color;

                &:focus,
                &:hover {
                    color: white;
                    background-color: darken($fb-color, 10%);
                }
            }
        }

        svg.icon {
            display: inline-block;
            width: 0.8em;
            height: 0.8em;
            margin-right: 0.125em;
            // line-height: $line-height-shorter;
            // margin: 0 auto 0.5em auto;
        }
    }

    .river .more-stories-link {
        margin: 1rem 0;
        text-align: center;

        a {
            font-family: $fonts-sans;
            font-weight: bold;
            border-bottom: 1px solid $text-color-retina;
            color: $text-color-retina;
        }
    }

    .latest-stories .title {
        text-align: center;
    }
}

.home-places .river {
    margin: 0;
}

.home-illinois {
    .introduction {
        text-align: center;
        font-size: $scale-5;
        @include breakpoint-min(md) {
            font-size: $scale-6;
        }
    }
}

// Breadcrumb nav (for conversion of Illinois homepage to “Midwest” page)

.home-places,
.home-illinois {
    @include grid(breadcrumb);

    .breadcrumb {
        margin-top: 0.75rem;
        font-family: $fonts-sans;
        font-weight: bold;
        font-size: $scale-6;
        @include breakpoint-min(xl) {
            font-size: 1.125rem;
        }
    }

    .breadcrumb__subcategory:before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        display: inline-block;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat, repeat;
        background-position: 50% 50%;
        background-size: 0.65em auto;
        transform: rotate(270deg);
        margin-right: 0.25em;
        margin-left: 0.125em;
    }
}
