@use "sass:math";

// -------------------------------------------------------------------
// Impact Template

/* TODO
	– finish layout across breakpoints for “Recent Impact” stories
	– finish layout across breakpoints for “Impact Profiles”
	– square aspect ratio for impact profile photos
	– Newsletter placement after 4th story in “Recent Impact” section (may need help on the template logic to do this after the 4th story and have the stories continue beneath – if this is the case I’ll put the newsletter at the bottom of the recent impact stories)
*/

// ------------------------------------------------------------------
/* For reference:

	body base = font-size 18px

 	global breakpoints:
    	xs:  (cols:  4, min: 0,      margin: $pad), // Includes optional custom margin
    	sm:  (cols:  6, min: 37.5em, margin: $gut), // Includes optional custom margin
    	md:  (cols:  8, min: 48em),
    	lg:  (cols: 12, min: 60em),

    	//xl:  (cols: 12, min: 75em), // not needed for this layout
    	//xxl: (cols: 12, min: 90em) // not needed for this layout

	@include breakpoint-min(xs) {}
	@include breakpoint-min(sm) {}
	@include breakpoint-min(md) {}
	@include breakpoint-min(lg) {}

--------------------------------------------------------------------- */
/* Type variables to find right balance in relative sizes*/

$impact-rem-size-title: 6rem;
$impact-rem-height-title: $line-height-base;				//

$impact-rem-size-profile-name: 1.1rem; 						//
$impact-rem-height-profile-name: $line-height-shortest;		//

$impact-rem-size-profile-location: .8rem;					//
$impact-rem-height-profile-location: $line-height-base;		//

$impact-rem-size-profile-p: .75rem;
$impact-rem-height-profile-p: $line-height-base;			//

$impact-rem-size-h3: $scale-6; 								// 16px
$impact-rem-height-h3: $line-height-base;					//

$impact-rem-size-featured-title: 1.2rem;					//
$impact-rem-height-featured-title: $line-height-shorter;	//


/* -------------------------------------------------------------------------------- */
/* using ".impact-page" as the class on content because ".impact" is in global nav */

.impact-page {
	// show grid lines
	//@include grid( content, overlay );

	.impact-header {
		padding: 0 0 2rem 0;
			@include breakpoint-min(xs) {  // 4 cols
				width: colspan( 4, 4 );
				margin-left: none;
				padding-right: none;
				padding-bottom: 1rem;
			}
			@include breakpoint-min(sm) {  // 6 cols
				width: colspan( 6, 6);
			}
			@include breakpoint-min(md) {
				width: colspan( 6, 8 ); // 8 cols
				margin-left: colspan( 1, 8 ) + colspan( g, 8 );
			}
			@include breakpoint-min(lg) {  // 12 cols
				width: colspan( 8, 12 );
				margin-left: colspan( 2, 12 ) + colspan( g, 12 );
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: flex-start;
			}

		// container
		.propublica-10 {
			width: 100%;
			text-align: center;
			//border: 1px solid red;
			position: relative;

			@include breakpoint-min(xs) {
				/*	width: colspan( 1, 4 ) * 1.5;
					height: colspan( 1, 4 ) * 1.5;
					margin-top: 0em;*/
				}
			@include breakpoint-min(sm) {
			/*	width: colspan( 2, 6 );
				height: colspan( 2, 6 );
				margin-top: -1em;*/
			}
			@include breakpoint-min(md) {
			//	margin-top: 0em;

			}
			@include breakpoint-min(lg) {
			//	width: colspan( 2, 8 );
			//	height: colspan( 2, 8 );
			//	margin-top: -0.25em;
			}
		}

		svg {
				//float: left;
				display: block;
				width: 100%;


				//text-align: center;


				@include breakpoint-min(xs) {
					margin-top: -2em;
					margin-bottom: -2em;
				}
				@include breakpoint-min(sm) {
					margin-top: -1em;
					margin-bottom: -1em;
				}
				@include breakpoint-min(md) {


				}
				@include breakpoint-min(lg) {
					margin-top: -2em;
					margin-bottom: 0em;
				}
			}

		// "IMPACT"
		.hed {
			//border: 1px solid green;
			font-family: $fonts-sans;
			font-size: $impact-rem-size-title;
			// line-height: $impact-rem-height-title;
			line-height: 1;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: -.2rem;
			color: $pp-blue;

			display: block;
			width: 100%;
			margin-bottom: 0;
			padding: 0;
			margin-left: -.07em;
			// display: none;

			@include breakpoint-min(xs) { // 4 cols
				font-size: $impact-rem-size-title - math.div($impact-rem-size-title, 3);
				//border-bottom: none;
			}
			@include breakpoint-min(sm) { // 6 cols
				font-size: $impact-rem-size-title - math.div($impact-rem-size-title, 8);
				//border-bottom: 1px solid $pp-blue;
			}
			@include breakpoint-min(md) { //8 cols
				font-size: $impact-rem-size-title - math.div($impact-rem-size-title, 5);
			}
			@include breakpoint-min(lg) { //12 cols
			}
		}

		// "Journalism in the Public Interest"
		h3 {
			font-family: $fonts-sans;
			font-weight: bold;
			//font-size:  $impact-rem-size-h3;
			text-transform:	uppercase;
			//text-align: center;
			letter-spacing: $letter-spacing-more;
			color: $pp-blue; //#304154
			display: block;
			line-height: 1.25em;

			// reset user styles
			-webkit-margin-before: 0;
			-webkit-margin-after: 0;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			margin-top: 0.5em;
			margin-bottom: 1.75em;

			// display: none;

			span {
			//	background-color: white;
			//	padding: 0 1rem;
			}
			@include breakpoint-min(xs) {
				font-size:  $impact-rem-size-h3 - math.div($impact-rem-size-h3, 5);
			}
		}
	}

	// article.introduction
	.introduction {
		font-family: $fonts-sans;
		text-align: left;
		letter-spacing: -0.02rem;
		color: $pp-blue;
		margin-bottom: 7.777777rem;
		width: 100%;

		a {
			color: $red;
			border-bottom: 2px solid rgba(255,255,255,0);

			&:hover {
				border-bottom: 2px solid $red;
			}
		}
	}

// container for .profiles-group Profiles + .impact-features "Recent Impact" + Newsletter
.impact-layout {
	border-bottom: 1px solid #979797;
	border-top: 1px solid #979797;
	padding: 3rem 0 3rem 0;

	@include clearfix;
}

// "Recent Impact" + Newsletter within impact-layout ~~~~~~~~~~~~~
	// .impact-features-wrapper {
	// 	//border: 1px solid green;

	// 	@include breakpoint-min(xs) { //4
	// 		width: colspan( 4, 4 );
	// 		margin-right: 0;
	// 	}
	// 	@include breakpoint-min(sm) {  //6
	// 		width: colspan( 6, 6 );
	// 	}
	// 	@include breakpoint-min(md) { //8
	// 		width: colspan( 8, 8 );
	// 		//float: none;
	// 	}
	// 	@include breakpoint-min(lg) { //12
	// 		float: left;
	// 		width: colspan( 8, 12 );
	// 		margin-right: colspan( 1, 12 ) + colspan( g, 12 ); // 1 column + gutter
	// 	}
	// }

	// Recent Impact Container
	.impact-features {

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		align-content: flex-start;

		@include breakpoint-min(lg) { // If there is no profiles module
			justify-content: flex-start;
		}

		.story-entry {
			margin-bottom:	2.2222rem; //40px
			.lead-art {
				float: none;
				width: 100%;
			}
			.description {
				float: none;
				width: 100%;
				margin-left: 0;
			}
			.hed {
				margin-top:	1rem;
				font-size: $impact-rem-size-featured-title;
				line-height: $impact-rem-height-featured-title;
				a:hover {
					color: $red;
					border-bottom: 2px solid $red;
				}
			}
			.dek {
				color: #333;
			}
			.metadata {
				@include breakpoint-min(lg) {
					display: none;
				}
			}
			//story entry breakpoints
			@include breakpoint-min(xs) {
				width: 100%;
			}
			@include breakpoint-min(sm) {
				width: colspan( 3, 6 );
			}
			@include breakpoint-min(md) {
				width: colspan( 4, 8 );
			}
			@include breakpoint-min(lg) {
				margin-right: colspan( g, 12 );
				// width: colspan( 4, 8 );
				width: colspan( 4, 12 );
			}
			@include breakpoint-range(lg,xl) { // If there is no profiles module
				&:nth-of-type(3n) {
					margin-right: 0;
				}
			}
			@include breakpoint-min(xl) {
				// width: colspan( 4, 8 );
				width: colspan( 3, 12 ); // If there is no profiles module
				&:nth-of-type(4n) { // If there is no profiles module
					margin-right: 0;
				}
			}
		} // end .story-entry
	} // end .impact-features

	.profiles-group, .stories, .impact-features {
		.subhead {
			display: block;
			color: $hed-color;
			width: 100%;
		}
	}

	.profiles-group .subhead {
			margin-bottom: 1.6em;
	}

// Newsletter ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	.impact-newsletter {
		padding-bottom: 4rem;
		font-family: $fonts-sans;
		//border: 1px solid green;

		label {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 1.5rem;
			font-weight: bold;
			font-size: 1.5rem;
		}
		input {
			transition: all 0.25s ease;
			box-shadow: none;
		}

		input[type="email"] {
			font-family: $fonts-sans;
			font-weight: bold;
			font-size: 1rem;
			text-align: center;
			color: $grey-light;

			display: block;
			width: 100%;
			margin-bottom: 1rem;

			&:hover {
				border: 1px solid #333;
				box-shadow: none;
				color: #333;
			}
			&:focus {
				border: 1px solid $red;
				box-shadow: none;
				color: $red;
			}
		}
		input[type="submit"] {
			font-family: $fonts-hed;
			font-size: 1rem;
			text-align: center;
			font-weight: normal;

			background-color: $pp-blue; //$red;
			display: block;
			width: 100%;
			box-shadow: none;

			&:hover{
				background-color: $link-color; //#333;
				box-shadow: none;
			}
			&:focus{
				box-shadow: none;
			}
		}

		@include breakpoint-min(xs) { // 4

				width: 100%;
			}
			@include breakpoint-min(sm) {  // 6
				width: 100%;
			}
			@include breakpoint-min(md) { // 8
				width: 100%;
			}
			@include breakpoint-min(lg) { // 12
				width: 100%;
			}
	}


// PROFILES ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

   // container for profiles
    .profiles-group {
		/* flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid red;*/

        @include breakpoint-min(xs) {
            width: 100%;
            border-top: 1px solid #979797;
            padding-top: 3.3333rem;
            margin-top: 2rem;
        }
        @include breakpoint-min(sm) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }
        @include breakpoint-min(md) {

        }
        @include breakpoint-min(lg) {
            border-top: none;
            padding-top: 0;
            margin-top: 0;

            width: colspan( 3, 12 );
            display: block;

            float: right;
        }

        .subhead {
            width: 100%;
        }
    }


    /* Profile Excerpts ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    .impact-profile{
 		padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #979797;
        @include clearfix;

 		@include breakpoint-min(xs) {
 			width: 100%;
 			&:last-child {
        	    border-bottom: none;
        	}
 		}
 		@include breakpoint-min(sm) {
 			width: colspan( 3, 6 );
 			&:last-child {
        	    border-bottom: none;
        	}
        	&:nth-last-child(2) {
        	    border-bottom: none;
        	}
 		}
 		@include breakpoint-min(md) {
 			width: colspan( 4, 8 );

 		}
        @include breakpoint-min(lg) {
        	width: 100%;
        	&:last-child {
        	    border-bottom: none;
        	}
        	&:nth-last-child(2) {
        	    border-bottom: 1px solid #979797;
        	}
        }



        figure {
        	@include breakpoint-min(xs) {
					float: right;
					margin: 0rem 0 1rem 1rem;
					width: colspan( 1, 4 ) * 1.25;
				}

				@include breakpoint-min(sm) {
					margin: 0rem 0 1rem 1rem;
					width: colspan( 1, 3 );
				}

				@include breakpoint-min(md) {
					width: colspan( 1, 4 ) * 1.25;
				}

				@include breakpoint-min(lg) {
					width: colspan( 1, 4 ) * 1.25;
				}
            //position: relative;
            //z-index: 1;
            a,a:hover {
                border-bottom: none;
            }
        }
        figcaption {
            // captions no longer displayed in layout
            display: none;
        }

        .text {}

            h1 {
                font-size:          $impact-rem-size-profile-name;
                line-height:        $impact-rem-height-profile-name;
                font-weight:        bold;

                color:              $text-color;

                a {
                    font-size:      inherit;
                    line-height:    inherit;
                    display:        inline;
                    color:          $text-color;

                    &:hover {
                        color:      $red;
                    }
                }
            }

        // location
            h2 {
                font-family: $fonts-sans;
                font-weight: bold;
                font-size: $impact-rem-size-profile-location;
                line-height: $impact-rem-height-profile-location;
                text-transform: uppercase;
                letter-spacing: $letter-spacing-more;

                color: $grey-light;
                margin-top: 0.277777rem;
                margin-bottom: 0.5rem; //40px
            }

        // excerpt
            p {
                font-size: $impact-rem-size-profile-p;
                line-height: $impact-rem-height-profile-p;
                //letter-spacing: -.02rem;
                font-family: $fonts-sans;
                color: $text-color;
                margin-bottom: 0.5rem;
            }

        // read more link
            a {
              //  display: inline-block;

                font-family: $fonts-sans;
                font-weight: bold;
                font-size: $impact-rem-size-profile-p;
                color: $red;
                border-bottom: 2px solid rgba(255,255,255,0);

                &:hover {
                    border-bottom: 2px solid $red;
                }
            }
    }






// STORIES AT BOTTOM ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	.stories {
		padding-top: 3.3333rem; //60px
		border-top: 1px solid #979797;

		.story-entry {
			margin-bottom: 2rem;
			@include clearfix;
			clear: both;
			padding-bottom: 2rem;

			@include breakpoint-min(xs) {
				float: none;
			}
			@include breakpoint-min(sm) {
				// 2 across
				width: colspan( 3, 6 );
			}
			@include breakpoint-min(md) {
				// 2 across
				width: colspan( 4, 8 );
			}
			@include breakpoint-min(lg) {
				// 3 across
				width: colspan( 4, 12 );
				margin-right: colspan( g, 12 );
			}

			.lead-art {
				@include breakpoint-min(xs) {
					float: right;
					margin: 0rem 0 1rem 1rem;
					width: colspan( 1, 4 );
				}

				@include breakpoint-min(sm) {
					// float continues from xs breakpoint
					margin: .25rem 0 1rem 1rem;
					width: colspan( 1, 3 );
				}

				@include breakpoint-min(md) {
					// float continues from xs breakpoint
					width: colspan( 1, 4 );
				}

				@include breakpoint-min(lg) {
					// float continues from xs breakpoint
					width: colspan( 1, 4 );
				}
			}
			.description {
			}
		} // end .story-entry
			.story-entry:nth-of-type(3n+3) {
				@include breakpoint-min(lg) {
					margin-right: 0em;
					//border: 1px solid green;
				}
			}
		.pagination {
			width: 100%; //flexbox hack
		}

		@include breakpoint-min(xs) { }
		@include breakpoint-min(sm) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
			align-content: flex-start;
		}
		@include breakpoint-min(md) { }
		@include breakpoint-min(lg) {
			justify-content: start;
		}
	}

	// override the added margin-top on stories with art
	.story-entry .lead-art+.description, .series-entry .lead-art+.description, .article-header .lead-art+.description {
    	margin-top: 0rem;
	}

	// Donate ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 	.impact-donate {
 		padding: 4rem 0 5rem 0;
		//margin-bottom: 4rem;
		font-family: $fonts-sans;
 		p {
 			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 1.5rem;
			font-weight: bold;
			font-size: 1.5rem;
 		}
 		a {
			font-family: $fonts-hed;
			font-size: 1rem;
			text-align: center;
			font-weight: normal;
			padding: 0.5em;
			background-color: $red;
			display: block;
			width: 100%;
			box-shadow: none;
			color: white;

			&:hover{
				background-color: #9d0114; //#333;
				box-shadow: none;
			}
			&:focus{
				box-shadow: none;
			}
 		}

 		@include breakpoint-min(xs) { // 4
				margin-left: 0;
				width: 100%;
			}
			@include breakpoint-min(sm) {  // 6
				width: colspan( 4, 6 );
				margin-left: colspan( 1, 6 ) + colspan( g, 6 );
			}
			@include breakpoint-min(md) { // 8
				width: colspan( 6, 8 );
				margin-left: colspan( 1, 8 ) + colspan( g, 8 );
			}
			@include breakpoint-min(lg) { // 12
				width: colspan( 6, 12 );
				margin-left: colspan( 3, 12 ) + colspan( g, 12 );
		}
 	}

	// Latest stories ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`
	// .latest-stories {
	// 	border-top: $border;
	// 	padding-top: 2rem;
	// }
}

// MISC HELPERS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.inline-block {
	display: inline-block;
}
