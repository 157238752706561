// ------------------------------------------------------------
// GLOBAL FORM STYLES
// ------------------------------------------------------------

// Reset, normalize form elements and buttons,
// then layer on default ProPublica styles

$form-border-radius: 0.1875em;
$form-border: 1px solid $pp-blue;

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  margin: 0; // removes margin in Firefox, Safari
  max-width: 100%;
}

button,
input {
    overflow: visible;
}

button,
select {
  text-transform: none; // Remove text transform inheritance in Firefox, Edge, IE
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // Correct inability to style clickable types in iOS and Safari
}

input,
input[type="email"],
input[type="search"],
input[type="text"],
input[type="button"],
input[type="submit"],
textarea {
    border: $form-border; // declare explicitly or you get chunky defaults
    border-radius: $form-border-radius; // resets iOS
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

input[type="email"],
input[type="search"],
input[type="text"],
textarea {
    padding: 0.5em;
    font-family: $fonts-sans;
}

textarea {
    display: block;
}

input[type="checkbox"] {
    border: $form-border;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
}

input[type="radio"] {
    border-radius: 50%;
    border: $form-border;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
}

button,
input[type=button],
input[type=submit] {
    display: inline-block;
    padding: 0.5em;
    text-decoration: none;
    background: $pp-blue;
    color: #fff;
    border: none;
    border-radius: $form-border-radius;
    font-family: $fonts-sans;
    font-size: 1em;
    text-align: center;
    cursor: pointer;

    &:focus,
    &:hover {
        background-color: $link-color;
    }
}

select {
    display: inline-block;
    margin: 0;
    padding: 0.5em 2em 0.5em 0.5em; // avoid the dropdown arrow
    border: $form-border;
    border-radius: $form-border-radius;
    font-family: $fonts-sans;
    font-size: 1em;
    background-color: #fff;
    background-image:
        url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #fff 0%, #fff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.5em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}

.input-text {
    border: none;
    font-size: 1em;
    padding: $scale-1;
    transition: box-shadow 0.2s;

    &:focus,
    &:hover {
        box-shadow: 0 0 2px $link-color;
    }
}

// for text input + submit button pairs,
// displaying icons inside text inputs

.input-wrapper {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background-color: #fff; // for dark bgds
    border: $form-border;
    border-radius: $form-border-radius;

    &.reverse {
        border: 1px solid #fff;
    }

    input[type="text"] {
        border: none;
        background-color: transparent;
    }

    input[type="submit"] {
        align-self: stretch;
        border-radius: 0;
    }

    svg.icon {
        margin-left: 0.5em;
        order: -1;
        fill: desaturate(lighten($pp-blue, 30%), 15%);
    }

    input[type="text"]:focus ~ svg.icon {
        fill: $pp-blue;
    }
}

// for links we want to style to look like buttons…

.btn {
    background: $text-color;
    border-radius: 0.1875em;
    border: none;
    color: #fff;
    font-family: $fonts-sans;
    display: inline-block;
    padding: 0.3125em 0.5em;
    text-decoration: none;
}

// “Urgent” buttons

.btn-cta,
.btn-urgent {
    color: #fff;
    font-weight: bold;
    // letter-spacing: $letter-spacing-base;
}

.btn-cta,
input[type=button].btn-cta,
input[type=submit].btn-cta {
    background-color: $green;
    text-transform: uppercase;
    &:focus,
    &:hover,
    a:focus &,
    a:hover & {
        background-color: lighten($green, 5%);
    }
}

.btn-urgent,
input[type=button].btn-urgent,
input[type=submit].btn-urgent {
    background-color: $red;
    text-transform: uppercase;
    &:focus,
    &:hover,
    a:focus &,
    a:hover & {
        background-color: lighten($red, 5%);
    }
}

// Social media buttons

.btn-social {
    background-color: darken($bg-low-contrast, 10%);
    color: $text-color;
    border-radius: 0.1875em;
    width: 100%;

    &:focus,
    &:hover {
        border: none;
        // background: lighten($text-color, 50%);
        background-color: darken($bg-low-contrast, 20%);
        color: #fff;
    }
}

.btn-social .icon {
    fill: $text-color;
    // margin-right: $scale-1;
    margin-right: 0.125em;
    transition: fill 0.25s;
    vertical-align: -0.15em;
}

.btn-social:focus .icon,
.btn-social:hover .icon {
    fill: #fff;
}

// Suppress display of reCAPTCHA box
// See: https://developers.google.com/recaptcha/docs/faq
.grecaptcha-badge {
	visibility: hidden;
}

.legal {
    display: inline-block;
    margin: 1em 0;
    font-size: $scale-4;
    font-family: $fonts-sans;
    line-height: $line-height-taller;
    color: transparentize($text-color, 0.33);

    a {
        color: inherit;
        border: 0;
        text-decoration: underline;
    }

    a:focus,
    a:hover {
        border: 0;
    }
}

//
// Screendoor form styles
//

.fr_form {
    @include clearfix;
}

aside.form.large,
aside.form.x-large {
    padding-left: 0;
    padding-right: 0;

    .fr_form {
        font-size: $scale-6;
    }
}

// PATCH: override for inline styles
article .fr_page * {
    line-height: inherit;
}

// PATCH: `aside.form` doesn’t need padding at `.large`r sizes
aside.form.large {
    padding-left: 0;
    padding-right: 0;
}

// Field styles
.fr_form .fr_page input[type="email"],
.fr_form .fr_page input[type="search"],
.fr_form .fr_page input[type="text"],
.fr_form .fr_page .fr_field_wrapper textarea {
    display: block;
    max-width: inherit;
    padding: 0.3em;
    width: 100%;
}

.fr_form input[type="email"],
.fr_form input[type="search"],
.fr_form input[type="text"] {
    margin-bottom: 0.5em;
}

.fr_response_field .fr_add_file label {
    line-height: 1;
}

.fr_form abbr.fr_required {
    text-decoration: none;
}

// Button styles
.fr_form .fr_button {
    background: $pp-blue;
    border-radius: 0.2em;
    border: none;
    color: #FFF;
    display: inline-block;
    font-size: inherit;
    font-weight: bold;
    letter-spacing: $letter-spacing-base;
    padding: 0.5em;
    text-transform: uppercase;

    &:focus,
    &:hover {
        background-color: lighten($pp-blue, 5%);
        border: none;
        color: #FFF;
    }
}

input[data-rv-show] {
    margin-top: 0.25em;
}

// Block-level styles
.fr_form .fr_response_field {
    max-width: inherit;
}

.fr_field_wrapper,
.fr_response_field .fr_option,
.fr_response_field label {
    margin-bottom: 0.5em;
}

.fr_form .fr_text {
    font-size: 0.95em;
}

.fr_form .fr_error_alert_bar {
    margin-bottom: 1em;

    & a,
    & a:focus,
    & a:hover {
        // There is default Screendoor styling doing this already but it gets overriden by article link styles
        color: #FFF;
        border-bottom: 2px solid #FFF;
        // Remove default Screendoor underline in favor of border
        text-decoration: none;
    }
}

.fr_response_field_section_break {
    margin-top: 3.5em;
}

// Labels / text styles
.fr_response_field label {
    font-weight: bold;
}

.fr_form .fr_response_field .fr_other_option,
.fr_form .fr_response_field label {
    font-size: inherit;
}

.fr_form .fr_response_field .fr_option,
.fr_form .fr_response_field .fr_option label {
    font-weight: normal;
}

.fr_form .fr_other_option label {
    display: block;
}

.fr_description {
    font-size: 0.95em;
    font-style: italic;
    color: #666;
}

.fr_form h3 {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em;
}

.fr_form .fr_bottom {
    background: $bg-low-contrast;
    padding: colspan(p,4);
    @include breakpoint-min(md) {
        padding: colspan(p,5);
    }
    @include breakpoint-min(lg) {
        padding: colspan(p,7);
    }
}

// .fr_option contains a set of choices, including radio buttons and (presumably) checkboxes
.fr_response_field .fr_option {
    position: relative;
    margin-left: 1.25em;
}

.fr_response_field .fr_option input[type="radio"],
.fr_response_field .fr_option input[type="checkbox"] {
    float: left;
    margin-left: -1.25em;
    margin-top: 0.35em;
}

// “Powered by Screendoor” message
.fr_form + small {
    background-color: inherit !important;
    font-style: italic;
    display: block !important;
    text-align: right;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// Legacy hiding rules, ported from inline styles
.module-2013-ongoing,
aside.module-2013-follow {
    display: none;
}
