// ------------------------------------------------------------
// GRID PROPORTIONS
// ------------------------------------------------------------
// Customize your grid’s proportions by editing the values of
// the four variables below. Use only numbers for values, no
// units (e.g. "10", not "10px"). IMPORTANT: Don't delete any
// of these variables! For any you don't need to use, just
// assign a value of 0.

$mar: 68; // Margin width
$col: 68; // Column width
$gut: 26; // Gutter width
$pad: 13; // Padding width

$grid-color: red; // Grid overlay color

// ------------------------------------------------------------
// BREAKPOINTS
// ------------------------------------------------------------
// You can define as many (or as few) breakpoints as you like,
// and name them whatever you want. Just be sure to:
//
// 1. use the syntax shown below,
// 2. keep the breakpoints in order (smallest to largest), and
// 3. include a name (e.g. "xl"), column count ("cols",
//    unitless), and minimum width ("min-width", with units)
//    for each breakpoint
//
// All breakpoints use the same proportions specified in the
// variables above, but margin widths can be optionally
// customized for each breakpoint.

$breakpoints: (
    xs:  (cols:  4, min-width: 0,      margin: $pad), // 0
    sm:  (cols:  6, min-width: 37.5em, margin: $gut), // 600
    md:  (cols:  8, min-width: 48em),                 // 768
    lg:  (cols: 12, min-width: 60em),                 // 960
    xl:  (cols: 12, min-width: 75em),                 // 1200
    xxl: (cols: 12, min-width: 90em)                  // 1440
);

// $max-width: 1680px; // For no max-width, use $max-width: null;
$max-width: 1440px;
