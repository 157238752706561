@use "sass:math";

// ------------------------------------------------------------
// GLOBAL STYLES
// ------------------------------------------------------------

html {
	font-size: 100%; // 16
	@include breakpoint-min(md) {
		font-size: 112.5%; // 18/16
	}
	@include breakpoint-min(xxl) {
		font-size: 131.25%; // 21/16
	}
	@media print {
		font-size: 10pt;
	}
}

body {
    font-family: $fonts-body;
    line-height: $line-height-base;
    color: $text-color;
    background-color: $page-bg-color;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        color: $text-color-retina;
    }
    @media print {
        color: black;
    }

    &.dark {
        background-color: darken(desaturate($pp-blue, 13%), 13%);
        color: hsl(0, 0%, 70%);

        .masthead {
            border-bottom: none;
        }

        .description .series-header {
            // color: hsl(0,0%,80%);
            color: inherit;

            .series-title {
                color: white;
            }
        }

        .article-header {
            border-bottom: 4px solid hsla(0, 0%, 100%, 0.15);
        }

        .hed {
            color: white;
        }

        article {
            .article-body a {
                border-color: currentColor;
            }

            a:focus,
            a:hover {
                color: white;
                border-color: white;
            }
        }

        .byline,
        .timestamp {
            color: hsl(0,0%,50%);
        }
    }
}

[hidden],
.squelch {
    display: none;
}

.a11y {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
}
.a11y-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static !important;
  width: auto;
}

a {
	color: $link-color;
	text-decoration: none;
	border-bottom: 1px solid rgba($link-color, 0);
	&:hover {
		border-bottom: 1px solid rgba($link-color, 0.6);
	}
	&:focus {
		outline: 2px dotted rgba($pp-blue, .85);
		border: none;
		background: none;
		img & {
			outline: none;
		}
	}

}

cite,
em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

abbr {
	text-decoration: none;
}

hr {
    border: $border;
    border-width: 1px 0 0;
    margin: $scale-5 0;
}

img {
	display: block;
	height: 100%;
    width: 100%;

    // Carveout for DocumentCloud tracking pixel
    &.DV-pixelping {
        height: 1px;
        width: 1px;
    }
}

.icon {
	fill: currentColor;
	vertical-align: baseline;
	width: 1em;
	height: 1em;
}

.aspect-2k,
.aspect-2-3,
.aspect-1-1,
.aspect-16-9,
.aspect-3-2,
.aspect-3-4,
.aspect-4-3,
.aspect-9-16 {
	display: block;
	position: relative;
	> * {
		// video, object, iframe, etc.
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.aspect-2k {
    padding-top: percentage(math.div(1, 1.90)); // 1.90:1 aspect ratio (2K)
}

.aspect-16-9 {
	// padding-top: 56.25%; // 16:9 aspect ratio (HD)
    padding-top: percentage(math.div(9, 16)); // 16:9 aspect ratio (HD)
}

.aspect-9-16 {
	// padding-top: 177.777777777777778%; // 9:16 aspect ratio (HD vertical)
    padding-top: percentage(math.div(16, 9)); // 9:16 aspect ratio (HD vertical)
}

.aspect-4-3 {
	// padding-top: 75%; // 4:3 aspect ratio (SD)
    padding-top: percentage(math.div(3, 4)); // 4:3 aspect ratio (SD)
}

.aspect-3-4 {
	// padding-top: 133.333333333333333%; // 3:4 aspect ratio (SD vertical)
    padding-top: percentage(math.div(4, 3)); // 3:4 aspect ratio (SD vertical)
}

.aspect-3-2 {
	// padding-top: 66.666666666666667%; // 3:2 aspect ratio (lead art)
    padding-top: percentage(math.div(2, 3)); // 3:2 aspect ratio (lead art)
}

.aspect-2-3 {
	// padding-top: 150%; // 2:3 aspect ratio (lead art vertical)
    padding-top: percentage(math.div(3, 2)); // 2:3 aspect ratio (lead art vertical)
}

.aspect-1-1 {
	// padding-top: 100%; // 1:1 aspect ratio (square)
    padding-top: percentage(math.div(1, 1)); // 1:1 aspect ratio (square)
}

// Unadorned list
.list-plain {
	list-style: none;
	margin: 0;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.hide-lg {
    @include breakpoint-min(lg) {
        display: none;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    // TODO: Add easing function?
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-out {
    animation-name: fade-out;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    // TODO: Add easing function?
}

@keyframes slide-down {
    from {
        transform: translateY(-50vh);
    }

    to {
        transform: translateY(0);
    }
}

.slide-down {
    animation-name: slide-down;
    animation-duration: 0.25s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

@keyframes slide-up {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-50vh);
    }
}

.slide-up {
    animation-name: slide-up;
    animation-duration: 0.25s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

@keyframes slide-up-and-fade {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-50vh);
        opacity: 0;
    }
}

.slide-up-and-fade {
    animation-name: slide-up-and-fade;
    animation-duration: 0.25s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

// source: http://animista.net/play/attention/bounce
@keyframes bounce-top {
    0% {
        transform: translateY(-45px);
        animation-timing-function: ease-in;
        opacity: 1;
    }

    24% {
        opacity: 1;
    }

    25% {
        transform: translateY(0px);
        animation-timing-function: ease-out;
    }

    40% {
        transform: translateY(-24px);
        animation-timing-function: ease-in;
    }

    55% {
        transform: translateY(0px);
        animation-timing-function: ease-out;
    }

    65% {
        transform: translateY(-12px);
        animation-timing-function: ease-in;
    }

    75% {
        transform: translateY(0px);
        animation-timing-function: ease-out;
    }

    82% {
        transform: translateY(-6px);
        animation-timing-function: ease-in;
    }

    87% {
        transform: translateY(0px);
        animation-timing-function: ease-out;
    }

    93% {
        transform: translateY(-4px);
        animation-timing-function: ease-in;
    }

    100% {
        transform: translateY(0px);
        animation-timing-function: ease-out;
        opacity: 1;
    }
}

.bounce-top {
    animation-name: bounce-top;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

// source: http://animista.net/play/attention/shake/shake-bottom
@keyframes shake-bottom {
    0% {
        transform: rotate(0deg);
        transform-origin: 50% 100%;
    }

    10% {
        transform: rotate(2deg);
    }

    20% {
        transform: rotate(-4deg);
    }

    30% {
        transform: rotate(4deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(4deg);
    }

    60% {
        transform: rotate(-4deg);
    }

    70% {
        transform: rotate(4deg);
    }

    80% {
        transform: rotate(-2deg);
    }

    90% {
        transform: rotate(2deg);
    }

    100% {
        transform: rotate(0deg);
        transform-origin: 50% 100%;
    }
}

.shake-bottom {
    animation-name: shake-bottom;
    animation-duration: 1s;
    animation-iteration-count: 1;
}
