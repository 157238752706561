@use "sass:math";

////////////////////////////////////////////////////////////////////////////////
// ELECTIONLAND “REPORT VOTING PROBLEMS” BANNER (SITE-WIDE)
////////////////////////////////////////////////////////////////////////////////

.promo-bar--electionland {
    flex-direction: column;
    align-items: center;
    padding: 0.75em 0.75em 0.25em;
    border-radius: 0;
    color: $pp-blue;
    // background-color: #eeece7; // warm neutral
    background-color: $bg-low-contrast;
    @include breakpoint-min(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em;
    }

    & > * {
        flex: 1 1 auto;
    }

    .article-full & {
        display: none;
    }
}

.promo-bar--electionland__intro {
    margin-bottom: 0.5em;
    @include breakpoint-min(lg) {
        width: 30%;
        text-align: left;
        margin-bottom: initial;
    }
}

.promo-bar--electionland__title {
    text-transform: uppercase;
    letter-spacing: $letter-spacing-base;
    line-height: $line-height-shorter;
    @include breakpoint-min(lg) {
        display: block;
    }
}

.promo-bar--electionland__dek {
    @include breakpoint-min(lg) {
        display: inline-block; // allows us to apply line-height
        font-size: 0.6875rem;
        line-height: $line-height-shorter;
    }
}

// list (ul)
.promo-bar--electionland__contact-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint-min(lg) {
        width: 60%;
        font-size: 0.6875rem;
    }
}

// list items
.promo-bar--electionland__contact-option {
    flex: 1 1 auto;
    margin: 2px;

    a {
        display: block;
        padding: 0.25em;
        border: none;
        border-radius: 0.125em;
        color: white;
        line-height: 2em;
        @include breakpoint-min(lg) {
            line-height: 2.25em;
        }
    }

    .icon {
        vertical-align: text-top;
    }
}

.promo-bar--electionland__contact-option--sms {
    a {
        background: #fd005f;
        &:focus,
        &:hover {
            background: darken(#fd005f, 10%);
        }
    }
}

.promo-bar--electionland__contact-option--whatsapp {
    a {
        background: #03c358;
        &:focus,
        &:hover {
            background: darken(#03c358, 10%);
        }
    }
}

.promo-bar--electionland__contact-option--messenger {
    a {
        background: #016afe;
        &:focus,
        &:hover {
            background: darken(#016afe, 10%);
        }
    }
}

.promo-bar--electionland__contact-option--form {
    width: 100%;
    @include breakpoint-min(lg) {
        width: initial;
    }

    a {
        color: $pp-blue;
        text-decoration: underline;
        @include breakpoint-min(lg) {
            color: white;
            background-color: $pp-blue;
            text-decoration: none;
        }
        &:focus,
        &:hover {
            background: darken($pp-blue, 10%);
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// ALL 2020 ELECTIONLAND PAGES
////////////////////////////////////////////////////////////////////////////////

.site-electionland {

    .jump-link {
        font-size: $scale-5;
        margin-bottom: 2em;

        a {
            border-bottom: 1px solid transparent;
            text-decoration: none;
            font-family: $fonts-sans;
            font-weight: bold;

            &:after {
                content: "→";
                padding-left: 0.25em;
            }

            &:hover,
            &:focus {
                border-bottom: 1px solid $link-color;
            }
        }
    }

    .external-url {
        background-color: $bg-low-contrast;
        margin-bottom: 2rem;
        padding: colspan(p, 4);
        @include breakpoint-min(md) {
            padding: colspan(p, 5);
        }
        @include breakpoint-min(lg) {
            padding: colspan(p, 7);
        }

        .article-header {

            // .hed {
            //     font-family: $fonts-sans;
            //     margin-top: 1em;
            //     @include breakpoint-min(sm) {
            //         font-size: $scale-7;
            //     }
            //     @include breakpoint-min(md) {
            //         font-size: $scale-8;
            //     }
            // }

            .metadata {
                margin-top: 0.75em;
            }
        }

        .byline {
            display: none;
        }

        .jump-link {
            margin-bottom: 0;
        }
    }

    // SIDEBAR PROMOS

    // Pitch Interactive map

    // .electionland-map {
    //
    // }

    .electionland-map__wrapper {
        display: block;
        position: relative;
        overflow: hidden;
        // padding-top: percentage(4/3); // 3:4 aspect ratio (SD vertical)
        // padding-top: percentage(520/300);
        padding-top: percentage(math.div(5, 3));

        > * {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .electionland-map__link {
        font-family: $fonts-sans;
        font-size: $scale-5;
        line-height: 1.5;
        margin-bottom: 1em;

        &:after {
            content: "→";
            padding-left: 0.25em;
        }
    }

    .global-promos {
        @include breakpoint-min(sm) {
            border-left: $border;
            padding-left: colspan(p, 4);
        }
        @include breakpoint-min(md) {
            padding-left: colspan(p, 8);
        }
        @include breakpoint-min(lg) {
            padding-left: colspan(p, 12);
        }
    }

    .global-promos .introduction {
        .title {
            font-weight: bold;
            margin-bottom: 0.5em;
        }

        p {
            font-family: $fonts-sans;
            font-size: $scale-5;
            line-height: 1.5;
            margin-bottom: 1em;
        }
    }

    .pp-module {
        background-color: inherit;
        margin-top: 2rem;
        padding: 0;
        @include breakpoint-min(sm) {
            padding: 0;
        }
        @include breakpoint-min(md) {
            padding: 0;
        }
        @include breakpoint-min(lg) {
            padding: 0;
        }

        .pp-module-title {
            font-family: $fonts-hed;
            font-size: $scale-6;
            text-transform: none;
            letter-spacing: inherit;
            margin-bottom: 1em;
        }

        .secondary-title {
            font-size: $scale-5;
            font-weight: bold;
            a {
                color: $hed-color;
                border: 0;
                &:hover,
                &:focus {
                    color: $link-color;
                }
            }
        }

        p {
            font-family: $fonts-sans;
            font-size: $scale-5;
            line-height: 1.5;
            margin-bottom: 1em;
        }
    }

    .pp-module.module-partners {
        .partner-list {
            margin: 0;
            list-style-type: none;
        }

        .secondary-title {
            text-transform: uppercase;
            margin: 1.25em 0;
        }

        .partner {
            margin: 0 0 1.5em 0;
            width: 90%;
            @include breakpoint-min(md) {
                width: colspan(2, 3);
            }
            @include breakpoint-min(md) {
                width: colspan(3, 4);
            }

            // -webkit-filter: grayscale(100);
            // filter: grayscale(100);
            // &:hover,
            // &:focus {
            //     -webkit-filter: grayscale(0);
            //     filter: grayscale(0);
            // }

            img {
                width: auto;
                max-height: 2rem;
            }
        }
    }

}

.about-electionland,
.archive-electionland,
.article-electionland,
.article-link,
.home-electionland {

    // MISC LAYOUT-SPECIFIC OVERRIDES

    article .truncated-posts {
        @include breakpoint-min(sm) {
            float: left;
            width: colspan(4, 6);
        }
        @include breakpoint-min(md) {
            width: colspan(5, 8);
        }
        @include breakpoint-min(lg) {
            // width: colspan(7, 12);
            // margin-left: colspan(1,12) + colspan(g,12); // clears story tools
            width: colspan(8, 12);
        }
    }

    // article .truncated-posts h1 {
    //     padding-left: colspan(p, 4);
    //     @include breakpoint-min(md) {
    //         padding-left: colspan(p, 5);
    //     }
    //     @include breakpoint-min(lg) {
    //         padding-left: colspan(p, 7);
    //     }
    // }

    article .truncated-posts .article-wrap {
        float: none;
        width: 100%;
        // margin-bottom: $scale-9;
        // margin-bottom: 1rem;
        margin-left: 0;
        padding: colspan(p, 4);
        @include breakpoint-min(md) {
            padding: colspan(p, 5);
        }
        @include breakpoint-min(lg) {
            padding: colspan(p, 7);
        }
        border-bottom: $border;
    }

    article .truncated-posts .article-wrap.external-url {
        border-bottom: 0;
    }

    .article-header {
        @include breakpoint-min(lg) {
            width: 100%;
            margin-left: 0;
            margin-bottom: 0;
            padding-right: 0;
            border-bottom: none;
        }

        .series-title {
            margin-bottom: 0.25em;

            font-size: $scale-5;
            a {
                border: none;
            }
        }

        .hed {
            padding-right: initial;
            line-height: $line-height-shorter;
            @include breakpoint-min(sm) {
                font-size: $scale-7;
            }
            @include breakpoint-min(md) {
                font-size: $scale-8;
            }

            margin-top: 0.3125em;
        }

        .dek {
            font-size: inherit;
        }

        .metadata {
            // font-size: $scale-4;
            font-size: 0.75rem;
        }

    }

    .lead-art {
        margin-bottom: 1rem;

        figcaption:last-child {
            // font-size: $scale-4;
            font-size: 0.6875rem;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .article-body {
        p {
            line-height: 1.625;
        }
    }

    .archive-title,
    .subhead {
        font-family: $fonts-sans;
        font-weight: bold;
        margin-bottom: $scale-9;
        padding-left: colspan(p, 4);
        @include breakpoint-min(md) {
            padding-left: colspan(p, 5);
        }
        @include breakpoint-min(lg) {
            padding-left: colspan(p, 7);
        }
    }
}

// V3 “LINK” ARTICLES
.article-link {
    .story-tools {
        @include breakpoint-min(lg) {
            left: -(colspan(1,7) + colspan(g,7) * 2);
            width: colspan(1,7);
        }
    }
}

// 2018 AND EARLIER ELECTIONLAND ARTICLES
.article-electionland {
    .story-tools {
        @include breakpoint-min(lg) {
            left: -(colspan(1,7) + colspan(g,7));
            width: colspan(1,7);
        }
    }
}

// ELECTIONLAND HOMEPAGE
.home-electionland {

    // Featured stories at top of homepage

    .features {
        border-bottom: $border;
        margin-bottom: $scale-9;
        padding: 0 colspan(p, 4) colspan(p, 4) colspan(p, 4);
        @include breakpoint-min(md) {
            padding: 0 colspan(p, 5) colspan(p, 5) colspan(p, 5);
        }
        @include breakpoint-min(lg) {
            padding: 0 colspan(p, 7) colspan(p, 7) colspan(p, 7);
        }

        .features-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 1.5rem;

            .subhead {
                font-size: $scale-5;
                text-transform: uppercase;
                letter-spacing: $letter-spacing-more;
                margin-bottom: 0.25em;
                padding-left: initial;
                @include breakpoint-min(md) {
                    padding-left: initial;
                }
                @include breakpoint-min(lg) {
                    padding-left: initial;
                }
            }

            .language-link {
                display: inline-block;
                font-size: 0.6875rem;
                font-family: $fonts-sans;
                border: none;
                color: white;
                background-color: #05bec7;
                padding: 0.25em 1em;
                border-radius: 1em;

                &:after {
                    content: " →";
                }

                &:hover,
                &:focus {
                    background-color: darken(#05bec7, 10%);
                }
            }
        }

        .features-wrapper {
            @include breakpoint-min(lg) {
                display: flex;

                & > * {
                    flex: 1 1 auto;
                }
            }

            .story-entry {
                margin-bottom: $scale-5;

                @include clearfix;


                @include breakpoint-min(lg) {
                    width: 30%;
                    margin-right: 1rem;
                    padding-right: 1rem;
                    border-right: $border;

                    &:last-child {
                        margin-right: 0;
                        padding-right: 0;
                        border-right: none;
                    }
                }
            }

            .description {
                @include breakpoint-min(lg) {
                    margin-top: -0.33rem;
                    line-height: $line-height-shortest;
                }
            }

            .series-header,
            .dek,
            .byline,
            .metadata:after,
            .timestamp {
                display: none;
            }

            .hed {
                display: inline;
                font-size: $scale-6;
                overflow-wrap: break-word; // Prevents collission with art if hed has large word

                @include breakpoint-min(lg) {
                    font-size: $scale-5;
                }

                // @include breakpoint-min(xxl) {
                //     // font-size: 0.75rem;
                // }
            }

            // .metadata {
            //     display: inline-block;
            // }

            .lead-art {
                display: block;
                width: colspan(1, 4);
                float: right;
                margin-bottom: initial;
                margin-left: 0.5rem;

                @include breakpoint-min(lg) {
                    width: 33%;
                }
            }
        }
    }

    .latest {
        .latest-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            // .subhead {
            // }
            //
            // form {
            // }
            //
            // select {
            // }
        }
    }

    // Homepage river (wrapper)

    .latest {
        > .subhead {
            font-family: $fonts-sans;
            font-size: $scale-5;
            font-weight: bold;
            letter-spacing: $letter-spacing-more;
            // margin-bottom: $scale-7;
            margin-bottom: 1.5em;
            text-transform: uppercase;
        }
    }

    .more-stories-link {
        margin: 1rem 0 3rem;
        padding-left: colspan(p, 4);
        @include breakpoint-min(md) {
            padding-left: colspan(p, 5);
        }
        @include breakpoint-min(lg) {
            padding-left: colspan(p, 7);
        }
        a {
            border-bottom: none;
            font-family: $fonts-sans;
            font-weight: bold;

            &:hover,
            &:focus {
                border-bottom: none;
            }
        }
    }
}

// ELECTIONLAND ABOUT PAGES
.about-electionland {
    .article-wrap {
        margin-bottom: 3rem;

        @include breakpoint-min(lg) {
            width: colspan(8, 12);
            margin-left: 0;
        }
    }
}

// STATE ICONS
.site-electionland .article-header,
.electionland-promo-banner,
.home .home-electionland-promo {
    .state:before,
    .via:before {
        background-color: transparent;
        background-image: url(../images/electionland-sprites.png);
        background-position: left 0 top 0;
        background-repeat: no-repeat;
        background-size: 1em auto;
        content: "";
        display: inline-block;
        font-size: 1.5em;
        height: 1em;
        margin-right: 0.25em;
        vertical-align: -0.3em;
        width: 1em;
    }
    $states: "national", "alaska", "alabama", "arkansas", "arizona",
        "california", "colorado", "connecticut", "delaware", "washington-dc",
        "florida", "georgia", "hawaii", "iowa", "idaho", "illinois", "indiana",
        "kansas", "kentucky", "louisiana", "massachusetts", "maryland", "maine",
        "michigan", "minnesota", "missouri", "mississippi", "montana",
        "north-carolina", "north-dakota", "nebraska", "new-hampshire",
        "new-jersey", "new-mexico", "nevada", "new-york", "ohio", "oklahoma",
        "oregon", "pennsylvania", "rhode-island", "south-carolina",
        "south-dakota", "tennessee", "texas", "utah", "virginia", "vermont",
        "washington", "wisconsin", "west-virginia", "wyoming";
    @for $i from 1 through length($states) {
        .state.#{nth($states, $i)}:before {
            background-position: 0 #{- ($i * 1em + 2em)};
        }
    }
    .via:before {
        background-position: 0 -2em;
        margin: 0;
    }

    .series-title {
        font-weight: normal;
        font-size: $scale-5;
    }
}

// CANNED PROMO ON ARTICLE PAGES
.article {
    aside.canned-promo.partnership-electionland {
        @include breakpoint-max(sm) {
            width: 100%;
        }
        .logo-electionland svg {
            display: block;
            margin: 0 auto;
            width: 10rem;
        }
        .dek {
            margin-top: $scale-6;

            a {
                font-weight: 700;
                color: $text-color;
            }
        }
    }
}

// ARTICLE SCREENDOOR PROMO
article {
    aside.canned-promo.special-electionland-callout {
        border: 0;
        padding: 0;
    }
}


////////////////////////////////////////////////////////////////////////////////
// ELECTIONLAND 2020 PROMO BANNER FOR MAIN PROPUBLICA HOMEPAGE
////////////////////////////////////////////////////////////////////////////////

@include grid("electionland-promo-banner");
// @include grid("electionland-promo-banner", overlay);

.electionland-promo-banner {
    margin-top: colspan(p, 4);
    border-bottom: $border;

    @include breakpoint-min(sm) {
        margin-top: colspan(p, 6);
    }

    @include breakpoint-min(md) {
        margin-top: colspan(p, 8);
    }

    @include breakpoint-min(lg) {
        display: flex;
        margin-top: colspan(p, 12);
        padding-bottom: colspan(p, 12);
    }
}

.electionland-promo-banner__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: colspan(g, 4);
    padding: colspan(p, 4) colspan(p, 4) 0 colspan(p, 4);

    @include breakpoint-min(sm) {
        margin-bottom: colspan(g, 6);
        padding: colspan(p, 6) colspan(p, 6) 0 colspan(p, 6);
    }

    @include breakpoint-min(md) {
        margin-bottom: colspan(g, 8);
        padding: colspan(p, 8) colspan(p, 8) 0 colspan(p, 8);
    }

    @include breakpoint-min(lg) {
        width: colspan(3, 12);
        margin-bottom: initial;
        margin-right: colspan(g, 12);
        padding: colspan(p, 12);
    }
}

.electionland-promo-banner__logo {
    width: 80%;
    margin-bottom: 0.25rem;

    @include breakpoint-min(sm) {
        width: 50%;
    }

    @include breakpoint-min(lg) {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    a:hover,
    a:focus {
        border: none;

    }
}

.electionland-promo-banner__description {
    font-family: $fonts-sans;
    font-size: $scale-5;
    text-align: center;

    @include breakpoint-min(lg) {
        font-size: 0.75rem; // 12
    }

    @include breakpoint-min(xxl) {
        font-size: 0.6875rem; // 11
    }
}

.electionland-promo-banner__river {

    .story-entry {
        margin-bottom: colspan(g, 4);

        .via:before {
            outline: 1px dashed red;
            display: none;
        }

        .hed .icon {
            width: 0.75em;
            height: 0.75em;
            padding-left: 0.25em;
        }
    }

    @include breakpoint-min(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .story-entry {
            flex: 1 1 auto;
            width: colspan(3, 6);
            margin-bottom: colspan(g, 6);
            padding-right: colspan(p, 6);

            &:nth-child(odd) {
                margin-right: colspan(g, 6);
                border-right: $border;
            }
        }
    }

    @include breakpoint-min(md) {
        .story-entry {
            margin-bottom: colspan(g, 8);
        }
    }

    @include breakpoint-min(lg) {
        width: colspan(9, 12);
        flex-wrap: nowrap;
        justify-content: flex-start;

        .story-entry {
            width: colspan(3, 9);
            margin-right: colspan(g, 9);
            margin-bottom: 0;
            padding-top: colspan(p, 9);
            padding-right: colspan(p, 9);
            border-right: $border;

            &:nth-child(odd) {
                margin-right: colspan(g, 9);
            }

            .series-title {
                font-size: 0.6875rem; // 11
            }

            .hed {
                font-size: $scale-5;
            }
        }

        .story-entry:nth-of-type(3) {
            margin-right: 0;
            border-right: none;
        }

        .story-entry:nth-of-type(4) {
            display: none;
        }
    }

    @include breakpoint-min(xl) {

        .story-entry {
            width: inherit;
        }

        .story-entry:nth-of-type(3) {
            margin-right: colspan(g, 9);
            border-right: $border;
        }

        .story-entry:nth-of-type(4) {
            display: block;
            margin-right: 0;
            border-right: none;
        }
    }
}

// // ELECTIONLAND 2018 PROMO ON MAIN HOMEPAGE (2018)
// .home .home-electionland-promo {
//     background: $bg-low-contrast;
//     font-family: $fonts-sans;
//     margin-bottom: colspan(g, 4);
//     padding: colspan(p, 4);
//     position: relative;
//
//     @include breakpoint-max(lg) {
//         @include full-width;
//     }
//
//     @include breakpoint-min(sm) {
//         margin-bottom: colspan(g, 6);
//         padding: colspan(g, 6);
//     }
//
//     @include breakpoint-min(md) {
//         margin-bottom: colspan(g, 8);
//         padding: colspan(g, 8) colspan(1, 8);
//     }
//
//     @include breakpoint-min(lg) {
//         // padding: colspan(g,12);
//         padding: colspan(g, 12) 0;
//     }
//
//     .promo-inner-wrap {
//         @include breakpoint-min(lg) {
//             margin-left: colspan(4, 12) + colspan(g, 12);
//             margin-right: colspan(g, 12);
//             // width: colspan(8,12);
//         }
//     }
//
//     .contact-electionland,
//     .electionland-description {
//         text-align: center;
//     }
//
//     .electionland-logo {
//         margin: 0 auto 1em auto;
//         width: 90%;
//
//         @include breakpoint-min(sm) {
//             width: 50%;
//         }
//     }
//
//     .electionland-logo:before {
//         @include breakpoint-min(lg) {
//             // background-color: #666;
//             background-image: url(../images/electionland-promo-04.jpg);
//             background-position: center center;
//             background-size: cover;
//             content: "";
//             display: block;
//             height: 100%;
//             left: 0;
//             position: absolute;
//             top: 0;
//             width: colspan(4, 12);
//         }
//     }
//
//     .electionland-description {
//         @include breakpoint-min(md) {
//             margin: 0 auto;
//             width: colspan(6, 8);
//         }
//     }
//
//     .electionland-river {
//         border-bottom: $border;
//         margin-top: colspan(p, 4);
//         margin-bottom: colspan(p, 4);
//         @include breakpoint-min(sm) {
//             align-items: stretch;
//             border-top: $border;
//             display: flex;
//             flex-direction: row;
//             flex-wrap: wrap;
//             justify-content: space-between;
//             margin-top: 1.25em;
//             margin-bottom: 1em;
//         }
//     }
//
//     .story-entry {
//         border-top: $border;
//         padding: 1em 0;
//         @include breakpoint-min(sm) {
//             align-items: center;
//             display: flex;
//             width: colspan(3, 6);
//
//             &:nth-child(1),
//             &:nth-child(2) {
//                 border-top: none;
//             }
//         }
//
//         @include breakpoint-min(md) {
//             width: colspan(4, 8);
//         }
//     }
//
//     .series-title {
//         font-size: $scale-4;
//     }
//
//     .contact-electionland p {
//         display: inline-block;
//         margin-right: 0.5em;
//     }
//
//     .contact-options {
//         display: inline-block;
//         // margin-top: 0.5em;
//     }
//
//     .contact-item {
//         display: inline-block;
//     }
//
//     .contact-item a {
//         line-height: 1;
//         padding: 0.33em 0.33em 0.125em 0.33em;
//
//         span {
//             display: none;
//         }
//     }
// }
