// Newsletter landing page (/newsletters)

.newsletter-landing {

	main > .wrapper {
		@include breakpoint-min(lg) {
			width: colspan(8,12);
			margin-left: colspan(2,12) + colspan(g,12);
		}
	}

	.hed,
	.intro {
		text-align: center;
	}

	.hed {
		font-family: $fonts-sans;
		font-size: $scale-8;
		font-weight: bold;
		@include breakpoint-min(sm) {
			font-size: $scale-9;
		}
	}

	.intro {
		margin-bottom: 1em;
	}

	.subhed {
		font-family: $fonts-sans;
		font-size: $scale-6;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 1em;
	}

	.form-label-set {
		position: relative;
		margin-bottom: colspan(g, 2);
		width: 100%;
		@include breakpoint-min(sm) {
			margin-bottom: colspan(1, 6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(1, 8);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(2, 12);
		}

		.input-text {
			padding-left: 2em;
			width: 100%;
		}

		label {
			display: block;
			position: absolute;
			top: -2px;
			padding-top: 0.5em;
			padding-left: 0.5em;
		}

		label svg {
			fill: desaturate(lighten($pp-blue, 50%), 15%);
		}

		.input-text:focus + label svg {
			fill: $text-color;
		}
	}

	.newsletter-list {
		margin-bottom: colspan(g, 4);
		@include breakpoint-min(sm) {
			margin-bottom: colspan(1, 6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(1, 8);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(1, 12);
		}

		.newsletter-label {
			display: flex;
			align-items: center;
			margin-bottom: colspan(p, 4);
			padding: colspan(p,4);
			cursor: pointer;
			border: $border;
			border-radius: 0.25em;
			@include breakpoint-min(sm) {
				margin-bottom: colspan(g, 6);
			}
			@include breakpoint-min(md) {
				margin-bottom: colspan(g, 8);
			}
			@include breakpoint-min(lg) {
				margin-bottom: colspan(g, 12);
				padding: 1.5em;
			}

			&:hover,
			&:focus {
				border: 1px solid $link-color;
				background-color: lighten($link-color, 40%);
			}

		}

		input[type="checkbox"] {
			width: colspan(1,4);
			margin-right: colspan(p, 4);
			@include breakpoint-min(sm) {
				width: colspan(1,6);
				margin-right: colspan(g, 6);
			}
			@include breakpoint-min(md) {
				width: colspan(1,8);
				margin-right: colspan(g, 8);
			}
			@include breakpoint-min(lg) {
				width: colspan(1,12);
				margin-right: colspan(g, 12);
			}
		}

		.newsletter-title {
			font-family: $fonts-sans;
			font-weight: bold;
			font-size: $scale-7;
			margin-bottom: 0;
		}

		.newsletter-description {
			font-size: $scale-6;
		}
	}

	.newsletter-list-submit {
		width: 100%;
		margin-bottom: 4rem;
		padding-top: 1em;
		padding-bottom: 1em;
	}

	.subscription-management {
		background-color: $bg-low-contrast;
		padding: 2em;
		text-align: center;

		.msg {
			font-family: $fonts-sans;
			font-size: $scale-7;
			font-weight: bold;
			margin-bottom: 1em;
		}

		.msg a {
			display: inline-block;
		}

		a.unsub {
			color: #999;
			font-size: $scale-5;
			text-decoration: underline;
		}

		a.unsub:focus,
		a.unsub:hover {
			border: none;
		}
	}
}

.newsletter-signup {

	main {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	main > .wrapper {
		@include breakpoint-min(lg) {
			width: colspan(8,12);
			margin: 0 auto;
		}
	}

	.signup-hed,
	.signup-intro {
		text-align: center;
	}

	.signup-hed {
		font-family: $fonts-hed;
		font-size: $scale-8;
		font-weight: bold;
		line-height: $line-height-shortest;
		color: $pp-blue;
		margin: 1rem;

		@include breakpoint-min(sm) {
			font-size: $scale-9;
		}
	}

	.signup-intro {
		margin-bottom: 1rem;
		font-family: $fonts-serif;
		font-size: $scale-6 !important;
		line-height: $line-height-base;
		color: $color-neutral-cool-40;

		@include breakpoint-min(md) {
			width: colspan(6,8);
			margin-left: auto;
			margin-right: auto;
		}
	}

	.signup-subhed {
		font-family: $fonts-sans;
		font-size: $scale-6;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 1rem;
	}

	.form-label-set {
		position: relative;
		margin-bottom: 1rem;
		width: 100%;

		.input-text {
			padding: 0.7rem 0.7rem 0.7rem 2rem;
			width: 100%;
			font-size: $scale-5;
		}

		label {
			display: block;
			position: absolute;
			top: 0px;
			padding-top: 0.5rem;
			padding-left: 0.5rem;
		}

		label svg {
			fill: desaturate(lighten($pp-blue, 50%), 15%);
		}

		.input-text:focus + label svg {
			fill: $text-color;
		}
	}

	.newsletter-list-submit {
		width: 100%;
		padding: 0.7rem;
		font-size: $scale-5;
	}

	form {
		@include breakpoint-min(md) {
			width: colspan(4,8);
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 4rem;
		}
	}

	footer[role="contentinfo"] .newsletter {
		display: none;
	}

	// Since we are importing newsletter styles from newsletters/_includes/styles, the following adds some rules to prevent them from clashing with global styles

	.newsletter-content {
		html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
			vertical-align: top;
		}
		p {
			margin-bottom: 1em;
		}
		a {
			text-decoration: underline;
		}
		img[width="80"] {
			width: 80px;
		}
		ol {
			list-style-type: decimal;
		}
		ul {
			list-style-type: disc;
		}
	}

	.masthead-inner-wrapper h1 {
		font-size: $scale-6;
		line-height: 1;
		margin: 0;
	}

	.site-footer-inner h3 {
		font-size: $scale-6;
	}

}
