
// Invoke column-setter on .site-footer-inner
@include grid(site-footer-inner);

footer[role="contentinfo"] {
    @include clearfix;
    clear: both;
    background: $bg-low-contrast;
    font-family: $fonts-sans;
    font-size: $scale-5;
    margin-top: colspan(p, 2);

    @include breakpoint-min(md) {
        margin-top: colspan(g, 4);
    }
    @include breakpoint-min(lg) {
        margin-top: colspan(g, 6);
    }

    .site-footer-inner {
        padding: colspan(p, 4) 0;
        @include breakpoint-min(md) {
            padding: colspan(g, 8) 0;
        }
        @include breakpoint-min(lg) {
            padding: colspan(g, 12) 0;
            padding-top: 3em;
        }
    }

    .newsletter {
        background: $pp-blue;
        padding-bottom: 0.5rem;
        @include breakpoint-min(lg) {
            padding-bottom: 1rem;
        }

        .pp-module-title {
            color: white;
            text-transform: none;
            font-size: $scale-7;
            line-height: 1.2;
            margin-bottom: 0.5em;
            @include breakpoint-min(lg) {
                width: colspan(6,12);
                margin-bottom: 0;
                padding-top: 0.33em;
            }
        }

        form {
            display: block;
            width: 100%;
            @include breakpoint-min(lg) {
                display: flex;
                justify-content: space-between;
                // align-items: baseline;
            }
        }

        fieldset,
        .form-wrap {
            display: block;
            position: relative;
            @include breakpoint-min(lg) {
                width: colspan(6,12);
            }
        }

        input {
            padding: 0.75em;
        }

        input[type="email"] {
            display: block;
            width: 100%;
            padding-right: 7em;
            background-color: $page-bg-color;
            border: 2px solid $page-bg-color;
        }

        input[type="submit"] {
            display: block;
            position: absolute;
            right: 1px;
            top: 1px;
            bottom: 1px;
            border-radius: 0;
            background-color: $red;
            @include breakpoint-min(sm) {
                width: colspan(2,6);
            }
            @include breakpoint-min(md) {
                width: colspan(3,8);
            }
            @include breakpoint-min(lg) {
                width: colspan(3,6);
            }
        }
    }

    .collapsible-expandwhenwide.collapsible .collapsible-content {
        @include breakpoint-min(md) {
            display: block;
        }
    }

    .collapsible-expandwhenwide.collapsible .collapsible-header,
    .collapsible-expandwhenwide.collapsible .collapsible-header button {
        @include breakpoint-min(md) {
            cursor: default;
        }
    }

    .collapsible-header {
        border-top: $border;
        margin-top: 1em;
        padding-top: 1em;
        @include breakpoint-min(md) {
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
    }

    nav > *:first-child .collapsible:first-child .collapsible-header {
        border-top: none;
        margin-top: 0;
    }

    .collapsible {
        @include breakpoint-min(md) {
            margin-bottom: 1.5em;
        }
    }

    nav {
        @include clearfix;
        margin-bottom: 2em;
        @include breakpoint-min(md) {
            margin-bottom: 1em;
        }
        @include breakpoint-min(lg) {
            float: right;
            width: colspan(9, 12);
        }
    }

    nav > * {
        @include breakpoint-min(md) {
            float: left;
            width: colspan(3, 8);
            margin-right: colspan(g, 8);

            &:last-child {
                width: colspan(2, 8);
                margin-right: 0;
            }
        }
        @include breakpoint-min(lg) {
            width: colspan(3, 9);
            margin-right: colspan(g, 9);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    // nav > *:last-child > .collapsible:last-child {
    //     border-bottom: $border;
    //     margin-bottom: 1em;
    //     padding-bottom: 1em;
    // }

    h3 {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0.25em;

        .no-js & {
            margin-top: 0.5em;
        }
    }

    ul {
        .no-js & {
            margin-bottom: 1.5em;
        }
    }

    li {
        margin-bottom: 0.33em;
    }

    a {
        color: $text-color;
    }

    .site-copyright {
        clear: both;
        @include breakpoint-min(md) {
            padding-top: 2em;
            border-top: $border;
        }
        @include breakpoint-min(lg) {
            clear: none;
            float: left;
            width: colspan(3, 12);
            padding-top: 0;
            border-top: none;
        }

        .logo {
            display: block;
            border-bottom: none;
            &:hover, &:focus {
              border-bottom: none;
            }
        }

        .logo svg {
            display: block;
            height: auto;
            width: colspan(3, 4);
            margin-bottom: 0.33em;
            @include breakpoint-min(sm) {
                width: colspan(3, 6);
            }
            @include breakpoint-min(md) {
                width: colspan(3, 8);
            }
            @include breakpoint-min(lg) {
                width: 80%;
            }
        }

        // p {
        //   @include breakpoint-min(lg) {
        //       width: 90%;
        //   }
        // }

        small {
            font-size: $scale-4;
            color: $text-color-low-contrast;
        }
    }
}

@media print {
    footer[role="contentinfo"] {
        display: none;
    }
}
