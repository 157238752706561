body.listing {
	.series-entry .series-title {
		font-size: $scale-6;
	}
	// end .series-entry .series-title

	header.series-header {
		.series-title {
			font-size: $scale-8;
			line-height: 1;
			letter-spacing: 0;
			@include breakpoint-min(sm) {
				font-size: $scale-9;
			}
			@include breakpoint-min(lg) {
				font-size: $scale-10;
			}
		}

		.series-description {
			margin-top: 0;
		}
	}
	// end .header.series-header

	.introduction {
		> * + * {
			margin-top: 1em;
		}
		> ul > li {

			list-style: disc;
			margin-left: 1em;

			& + li {
				margin-top: 0.5em;
			}
		}
	}

	.article-header,
	header.series-header {
		width: 100%;
		padding-bottom: $scale-6;
		@include breakpoint-min(sm) {
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(g,12);
		}
	}
	// end .article-header, header.series-header

	.subhead {
		clear: both;
		color: $red;
		font-family: $fonts-sans;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: $letter-spacing-base;
		margin-bottom: colspan(p,4);
		@include breakpoint-min(md) {
			margin-bottom: colspan(p,5);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(p,7);
		}
	}

	.features .story-entry.f1 {
		@include clearfix;
		margin-bottom: colspan(g,4);
		@include breakpoint-min(sm) {
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,5);
		}

		.lead-art {
			@include breakpoint-min(lg) {
				float: left;
				width: colspan(3,8);
			}
		}

		.description {
			@include breakpoint-min(xl) {
				margin-right: colspan(2,8);
			}
		}

		.lead-art + .description {
			margin-top: $scale-4;
			@include breakpoint-min(lg) {
				float: left;
				width: colspan(5,8);
				margin-top: 0;
				margin-left: colspan(g,8);
			}
			@include breakpoint-min(xl) {
				margin-right: 0;
			}
		}

		.hed {
			font-size: $scale-7;
			@include breakpoint-min(sm) {
				font-size: $scale-9;
				line-height: $line-height-shorter;
			}
			@include breakpoint-min(md) {
				font-size: $scale-8;
			}
		}
	}
	// end .features .story-entry.f1

	.features .series-entry.f1 {
		// @include clearfix;
		margin-bottom: colspan(g,4);
		@include breakpoint-min(sm) {
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			display: flex;
			justify-content: space-between;
		}

		.lead-art {
			@include breakpoint-min(lg) {
				width: colspan(5,8);
			}
		}

		.lead-art + .series-header {
			align-self: center;
			margin-top: $scale-4;
			@include breakpoint-min(lg) {
				width: colspan(3,8);
				margin-top: 0;
				margin-left: colspan(g,8);
			}
		}

		.series-title {
			font-size: $scale-7;
			@include breakpoint-min(xl) {
				font-size: $scale-8;
				line-height: $line-height-shorter;
			}
		}
	}
	// end .features .series-entry.f1

	.river .story-entry {
		@include clearfix;
		clear: both;
		padding: colspan(p,4) 0;
		border-top: $border;
		@include breakpoint-min(md) {
			padding: colspan(p,5) 0;
		}
		@include breakpoint-min(xl) {
			float: left;
			clear: none;
			width: colspan(4,8);
			padding: colspan(p,8) 0;
			border-bottom: none;
		}

		&:nth-of-type(odd) {
			@include breakpoint-min(xl) {
				clear: left;
			}
		}

		&:nth-of-type(even) {
			@include breakpoint-min(xl) {
				margin-left: colspan(g,8);
			}
		}
		&:last-of-type {
			@include clearfix;
		}

		.lead-art {
			float: right;
			width: colspan(1,4);
			margin-left: $scale-6;
			margin-bottom: $scale-6;
			@include breakpoint-min(md) {
				width: colspan(1,5);
			}
			@include breakpoint-range(lg,xl) {
				width: colspan(2,8);
				margin-bottom: 0;
			}
			@include breakpoint-min(xl) {
				width: colspan(1,4);
			}
		}

		.lead-art + .description {
			margin-top: 0;
		}

		.hed {
			font-size: $scale-6;
			line-height: $line-height-shorter;
		}
	}
	// end .river .story-entry

	.river .series-entry {
		float: left;
		clear: left;
		width: colspan(2,4);
		padding: colspan(p,4) 0;
		border-top: $border;
		@include breakpoint-range(md,lg) {
			 @include clearfix;
			float: none;
			width: 100%;
			padding: colspan(p,5) 0;
		}
		@include breakpoint-min(lg) {
			width: colspan(4,8);
			padding: colspan(p,8) 0;
		}

		&:nth-of-type(even) {
			clear: none;
			margin-left: colspan(g,4);
			@include breakpoint-min(md) {
				margin-left: 0;
			}
			@include breakpoint-min(lg) {
				margin-left: colspan(g,8);
			}
		}

		.lead-art {
			@include breakpoint-range(md,lg) {
				float: left;
				width: colspan(2,5);
			}
		}

		.series-header {
			margin-bottom: $scale-2;
		}

		.lead-art + .series-header {
			margin-top: $scale-4;
			@include breakpoint-range(md,lg) {
				float: left;
				width: colspan(3,5);
				margin-top: 0;
				margin-left: colspan(g,5);
			}
		}
	}
	// end .river .series-entry

	&.topics-index {
		.features .story-entry .hed,
		.river .story-entry .hed {
			font-family: $fonts-sans;
		}
		.story-entry .recent {
			margin: $scale-4 0 0 1.2em;
			list-style-position: outside;
			list-style-type: disc;
			a {
				color: $link-color;
			}
		}
		.f1.story-entry .recent {
			@include breakpoint-min(xl) {
				margin-right: colspan(2,8);
			}
		}
	}
}
// end body.listing

.dark.listing .river .story-entry {
	border-color: hsla(0,0%,100%,0.15);
}