// ------------------------------------------------------------
// ABOUT SECTION
// ------------------------------------------------------------

.about {

	.article-header {
		width: 100%;
		padding-bottom: colspan(p,4);
		margin-bottom: colspan(g,4);
		@include breakpoint-min(sm) {
			padding-bottom: colspan(p,6);
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			padding-bottom: colspan(p,8);
			margin-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			padding-bottom: colspan(g,12);
			margin-bottom: colspan(g,12);
		}
	}

	#level1 .subnav {
		font-family: $fonts-sans;
		font-size: $scale-5;
		// @include breakpoint-min(lg) {
		// 	font-size: $scale-6;
		// }
		a {
			display: block;
			border-bottom: $border;
			padding: $scale-1 0;
			// &:hover,
			// &:focus {
			// 	border-color: $link-color;
			// }
		}

		li:first-child a {
			padding-top: 0;
		}
	}

	.subnav.collapsible-enhanced {
		border-bottom: $border;

		.collapsible-header {
			cursor: pointer;
			position: relative;
			padding-right: 2em;
			padding-bottom: $scale-4;

			&:focus .series-title,
			&:hover .series-title {
				color: $link-color;
			}

			&:after {
				position: absolute;
				top: 0;
				right: 0;
			}
			@include breakpoint-min(md) {
				display: none;
			}
		}

		@include breakpoint-min(md) {
			border-bottom: none;
			.collapsible-content {
				display: block;
			}
		}
	}

	.subnav.collapsible-collapsed .collapsible-header:after {
		content: "▼";
	}

	.subnav.collapsible-expanded .collapsible-header:after {
		content: " ▲";
	}

	.introduction {
		margin-bottom: 1em;
	}
	// This is nearly identical to the .river .story-entry
	// homepage styles and should at some point become a mixin.
	.river .story-entry {
		@include clearfix;
		clear: both;
		padding: colspan(p,4) 0;
		border-top: $border;

		&:last-of-type {
			@include clearfix;
		}

		.lead-art {
			float: left;
			width: colspan(1,4);
			@include breakpoint-min(lg) {
				width: colspan(1,6);
			}
		}

		.lead-art + .description {
			float: left;
			width: colspan(3,4);
			margin-top: 0;
			margin-left: colspan(g,4);
			@include breakpoint-min(lg) {
				width: colspan(5,6);
				margin-left: colspan(g,6);
			}
		}

		.hed {
			font-size: $scale-6;
			// line-height: $line-height-shorter;
		}
	}

	.river:first-child .story-entry:first-child {
		padding-top: 0;
		margin-top: 0;
		border-top: none;
	}
	// Follow ProPublica module
	.links-social li {
		@include breakpoint-range(sm,lg) {
			flex: 0 0 100%;
		}
	}

	article {

		.river {
			margin-top: $scale-8;
			&:first-child {
				margin-top: 0;
			}
		}

		& > h1,
		& > h2,
		& > h3,
		& > h4,
		& > h5,
		& > h6,
		.river > .subhead {
			color: $hed-color;
			font-weight: bold;
			// font-family: $fonts-sans;
			margin: $scale-8 0 $scale-6;

			&:first-child {
				margin-top: 0;
			}


			a {
				color: $text-color-retina;
				&:hover,
				&:focus {
					border-bottom: none;
				}
			}
		}

		& > h1,
		& > h3,
		.river > .subhead {
			font-size: $scale-7;
			font-family: $fonts-sans;
			margin: $scale-9 0 $scale-6;
			@include breakpoint-min(md) {
				font-size: $scale-8;
				line-height: $line-height-shorter;
				+ p {
					margin-top: $scale-4;
				}
			}
		}

		& > h2,
		& > h4 {
			@include breakpoint-min(md) {
				font-size: $scale-7;
			}
		}

		& > h5 {
			font-size: $scale-5;
			@include breakpoint-min(md) {
				font-size: $scale-6;
			}
		}

		& > h6 {
			font-size: $scale-4;
			@include breakpoint-min(md) {
				font-size: $scale-5;
			}
		}
	}

}
