// ------------------------------------------------------------
// VIDEO PAGE SPECIFIC STYLES
// ------------------------------------------------------------

.lead-art {
    position: relative;
}

.duration {
    background-color: rgba(0,0,0,0.8);
    border-radius: 0.25em;
    bottom: 0.35em;
    color: white;
    display: inline-block;
    font-family: $fonts-sans;
    font-size: $scale-5;
    padding: 0.25em 0.5em;
    position: absolute;
    right: 0.35em;
    &:before {
        content: "▶ ";
        font-size: 0.5em;
        vertical-align: middle;
    }
}

.video-single {

    // Hide series bug
    .article-body .promo.x-small.left:first-child {
        display: none;
    }

    .article-header {

        @include breakpoint-min(lg) {
            margin-left: 0;
            padding-right: 0;
            width: 100%;
        }

        @include breakpoint-min(xl) {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .lead-art {

            @include breakpoint-min(lg) {
                margin-bottom: colspan(g,12);
            }

            @include breakpoint-min(xl) {
                align-self: flex-start;
                margin-bottom: 0;
                order: 2;
                width: colspan(7,12);
            }
        }

        .description {

            @include breakpoint-range(lg,xl) {
                padding-right: colspan(3,12);
            }

            @include breakpoint-min(xl) {
                margin-top: 0;
                order: 1;
                width: colspan(5,12);
            }

            .story-tools {
                margin-top: $scale-7;

                @include breakpoint-min(lg) {
                    position: static;
                    width: 100%;

                    li {
                        display: inline-block;
                        margin: 0 $scale-5 0 0;
                        width: $scale-8;
                    }
                }

                a .icon {
                    fill: hsl(0,0%,50%);
                }

                a:focus .icon,
                a:hover .icon {
                    fill: white;
                }
            }
        }

        .hed,
        .dek {

            @include breakpoint-min(md) {
                padding: 0;
            }
        }
    }

    &.dark .article-header .description .series-header .series-title {
        color: white;
    }
}

.video-index {

    a:focus,
    a:hover {
        border-color: white;
        color: white;
    }

    .article-header {
        padding-right: 0;
        width: 100%;

        @include breakpoint-min(sm) {
            margin-bottom: colspan(g,6);
            padding-bottom: colspan(p,6);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g,8);
            padding-bottom: colspan(p,8);
        }
        @include breakpoint-min(lg) {
            margin-bottom: colspan(g,12);
            padding-bottom: colspan(g,12);
        }

        .hed {
            font-family: $fonts-sans;
        }
    }

    .video-features {
        border-top: $border;
        margin: colspan(g,4) 0;
        padding-top: colspan(g,4);

        @include breakpoint-min(sm) {
            margin: colspan(g,6) 0;
            padding-top: colspan(g,6);
        }
        @include breakpoint-min(md) {
            margin: colspan(g,8) 0;
            padding-top: colspan(g,8);
        }
        @include breakpoint-min(lg) {
            margin: colspan(1,12) 0;
            padding-top: colspan(g,12) * 2;

            &:first-of-type {
                margin-top: colspan(g,12);
            }
        }
    }

    &.dark .video-features {
        // border-top: 4px solid hsl(0,0%,22%);
        border-top: 4px solid hsla(0,0%,100%,0.15);
    }

    .video-category-intro {
        margin-bottom: colspan(g,4);

        @include breakpoint-min(sm) {
            margin-right: colspan(1,6) + colspan(g,6);
            margin-bottom: colspan(g,6);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g,8);
            margin-right: colspan(2,8) + colspan(g,8);
        }
        @include breakpoint-min(lg) {
            margin-right: colspan(4,12) + colspan(g,12);
            margin-bottom: colspan(g,12) * 2;
        }

        p {
            font-family: $fonts-sans;
        }
    }

    .see-more {
        // border-bottom: 1px solid $link-color;
        // color: inherit;
        background-color: $bg-low-contrast;
        border: none;
        border-radius: $scale-5 * 0.25;
        color: $pp-blue;
        display: inline-block;
        font-family: $fonts-sans;
        font-weight: normal;
        line-height: 1;
        padding: 0.5em;
        vertical-align: 0.5em;

        &.all {
            display: block;
            font-size: $scale-7;
            margin: 0 auto colspan(1,4) auto;
            // padding: 1em 2em;
            text-align: center;

            @include breakpoint-min(sm) {
                margin-bottom: colspan(1,6);
            }
            @include breakpoint-min(md) {
                margin-bottom: colspan(1,8);
                width: colspan(6,8);
            }
            @include breakpoint-min(lg) {
                margin-bottom: colspan(1,12);
                width: colspan(6,12);
            }
        }

        &:focus,
        &:hover {
            // color: $link-color;
            background-color: $link-color;
            color: white;
        }

        // &:after {
        //     content: " →";
        // }
    }

    &.dark .see-more {
        // background-color: hsl(0,0%,25%);
        background-color: hsla(0,0%,100%,0.25);
        // color: inherit;
        color: white;

        &:focus,
        &:hover {
            // background-color: hsl(0,0%,40%);
            background-color: hsla(0,0%,100%,0.4);
        }
    }

    .video-category-title {
        font-size: $scale-7;
        font-family: $fonts-sans;
        font-weight: bold;

        @include breakpoint-min(lg) {
            font-size: $scale-8;
        }

        .see-more {
            font-size: $scale-4;
            letter-spacing: $letter-spacing-base;
            margin-left: 0.5em;
            text-transform: uppercase;
        }
    }

    &.dark .video-category-title {
        color: white;
    }

    .features .series-header,
    .video-features .series-header {
        font-size: $scale-4;
        line-height: 1;
        margin-top: $scale-5;

        + * {
            margin-top: $scale-1;
        }
    }

    .video-features._4up .related {
        margin-top: $scale-6;
    }

    .related {
        margin-top: $scale-9;

        .related-header {
            // color: $red;
            font-family: $fonts-sans;
            font-size: $scale-4;
            // font-weight: bold;
            letter-spacing: $letter-spacing-more;
            // margin-top: $scale-4;
            // margin-bottom: $scale-3;
            text-transform: uppercase;
        }

        ul {
            list-style: square outside none;
        }

        .story-entry {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .story-entry .description {
            order: 1;
            > * + * {
                margin-top: 0;
            }
        }

        .story-entry .lead-art {
            align-self: flex-start;
            border: none;
        }

        .story-entry .lead-art + .description {
            margin-top: 0;
        }

        .story-entry .hed {
            // font-family: $fonts-sans;
            font-size: $scale-5;
            // font-weight: normal;
            display: block;
        }

        // .story-entry a {
        //     border-bottom: 1px solid $link-color;
        // }
    }

    // &.dark .related .related-header {
    //     color: hsl(0,50%,45%);
    // }

    &.dark .related .story-entry .hed {
        color: hsl(0,0%,80%);
    }

    ._1up > .story-entry {

        > .description > .hed {
            font-size: $scale-7;
            line-height: $line-height-shorter;

            @include breakpoint-min(sm) {
                font-size: $scale-8;
                line-height: $line-height-shorter;
            }
            @include breakpoint-min(md) {
                font-size: $scale-9;
            }
            @include breakpoint-min(lg) {
                font-size: $scale-9;
            }
        }

        .related .lead-art {
            width: colspan(1,4) - colspan(p,4);
        }
        .related .lead-art + .description {
            width: colspan(3,4) + colspan(g,4);
        }

        @include breakpoint-min(sm) {
            .related {
                width: colspan(4,6);
            }
        }

        @include breakpoint-min(md) {
            .related {
                width: colspan(5,8);
            }
            .related .lead-art {
                width: colspan(1,5) - colspan(p,5);
            }
            .related .lead-art + .description {
                width: colspan(4,5) + colspan(g,5);
            }
        }

        @include breakpoint-min(lg) {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .lead-art {
                order: 2;
                width: colspan(7,12);
            }

            .description {
                align-self: center;
                order: 1;
                width: colspan(5,12);
            }

            .related {
                width: 100%;
            }
            .related .lead-art {
                order: 1;
            }
            .related .lead-art + .description {
                order: 2;
            }
        }

        @include breakpoint-min(xl) {
            .related {
                width: colspan(4,5);
            }
            .related .lead-art {
                width: colspan(1,4) - colspan(p,4);
            }
            .related .lead-art + .description {
                width: colspan(3,4) + colspan(g,4);
            }
        }
    }

    ._3up {

        .video-feature-entries {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > .story-entry:nth-child(1) {
                margin-bottom: colspan(g,4);
                width: 100%;

                .related .lead-art {
                    width: colspan(1,4) - colspan(p,4);
                }
                .related .lead-art + .description {
                    width: colspan(3,4) + colspan(g,4);
                }

                @include breakpoint-min(sm) {
                    margin-bottom: colspan(g,6);
                    width: colspan(5,6);

                    .related {
                        width: colspan(4,5);
                    }
                }
                @include breakpoint-min(md) {
                    margin-bottom: colspan(g,8);
                    width: colspan(6,8);

                    .related {
                        width: colspan(5,6);
                    }
                    .related .lead-art {
                        width: colspan(1,5) - colspan(p,5);
                    }
                    .related .lead-art + .description {
                        width: colspan(4,5) + colspan(g,5);
                    }
                }
                @include breakpoint-min(lg) {
                    margin: 0;
                    width: colspan(6,12);
                }
                @include breakpoint-min(xl) {
                    margin: 0;
                    width: colspan(6,12);

                    .related {
                        width: colspan(4,6);
                    }
                    .related .lead-art {
                        width: colspan(1,4) - colspan(p,4);
                    }
                    .related .lead-art + .description {
                        width: colspan(3,4) + colspan(g,4);
                    }
                }

                > .description > .hed {

                    @include breakpoint-min(sm) {
                        font-size: $scale-7;
                    }
                }
            }

            > .story-entry:nth-child(2),
            > .story-entry:nth-child(3) {
                width: colspan(2,4);

                .related .lead-art {
                    display: none;
                }

                @include breakpoint-min(sm) {
                    width: colspan(3,6);

                    .related .lead-art {
                        display: block;
                        width: colspan(1,3) - colspan(p,3);
                    }
                    .related .lead-art + .description {
                        width: colspan(2,3) + colspan(g,3);
                    }
                }
                @include breakpoint-min(md) {
                    width: colspan(4,8);

                    .related .lead-art {
                        display: block;
                        width: colspan(1,4) - colspan(p,4);
                    }
                    .related .lead-art + .description {
                        width: colspan(3,4) + colspan(g,4);
                    }
                }
                @include breakpoint-min(lg) {
                    width: colspan(3,12);

                    .related .lead-art {
                        display: block;
                        width: colspan(1,3) - colspan(p,3);
                    }
                    .related .lead-art + .description {
                        width: colspan(2,3) + colspan(g,3);
                    }
                }
            }
        }
    } // end ._3up

    ._2up {

        @include breakpoint-min(lg) {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;

            .video-category-intro {
                margin-right: 0;
            }
        }

        .video-category-intro {
            @include breakpoint-min(lg) {
                width: colspan(4,12);
            }
        }

        .video-feature-entries {
            display: flex;
            justify-content: space-between;

            @include breakpoint-min(lg) {
                width: colspan(8,12);
            }

            > .story-entry {
                width: colspan(2,4);

                .related .lead-art {
                    display: none;
                }

                @include breakpoint-min(sm) {
                    width: colspan(3,6);

                    .related .lead-art {
                        display: block;
                        width: colspan(1,3) - colspan(p,3);
                    }
                    .related .lead-art + .description {
                        width: colspan(2,3) + colspan(g,3)
                    }
                }
                @include breakpoint-min(md) {
                    width: colspan(4,8);

                    .related .lead-art {
                        width: colspan(1,4) - colspan(p,4);
                    }
                    .related .lead-art + .description {
                        width: colspan(3,4) + colspan(g,4)
                    }
                }
                @include breakpoint-min(lg) {
                    > .description > .hed {
                        font-size: $scale-7;
                    }
                }
            }
        }
    } // end ._2up

    ._4up {

        .video-feature-entries {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > .story-entry {
                width: colspan(2,4);

                @include breakpoint-min(sm) {
                    width: colspan(3,6);
                }
                @include breakpoint-min(md) {
                    width: colspan(4,8);
                }
                @include breakpoint-min(lg) {
                    width: colspan(3,12);
                }
                @include breakpoint-min(xl) {
                    align-items: flex-start;
                    display: flex;
                    justify-content: space-between;
                    width: colspan(6,12);

                    > .lead-art,
                    > .description {
                        width: colspan(3,6);
                    }

                    > .description {
                        align-self: center;
                        margin: 0;
                    }

                    .series-header {
                        margin-top: 0;
                    }
                }

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: colspan(g,4);

                    @include breakpoint-min(sm) {
                        margin-bottom: colspan(g,6);
                    }
                    @include breakpoint-min(md) {
                        margin-bottom: colspan(g,8);
                    }
                    @include breakpoint-min(lg) {
                        margin-bottom: colspan(g,12);
                    }
                }
            }
        }
    } // end ._4up
} // end .video-index

