@use "sass:math";

// ------------------------------------------------------------
// ARTICLE LISTING, ARTICLE HEADER, SERIES LISTING
// ------------------------------------------------------------

// Article Listing, Article Header, Series Listing Base Styles

.series-entry,
.series-header {
    // font-family: $fonts-sans-initial;
    // .fonts-loaded & {
    //     font-family: $fonts-sans;
    // }
    font-family: $fonts-sans;
	font-size: $scale-5;

	a {
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid rgba($link-color,0);
		&:hover,
		&:focus {
			color: $link-color;
			border-color: rgba($link-color,.8);
		}
	}

	.lead-art a {
		border: none;
	}

}


.features .story-entry .series-header,
.river .story-entry .series-header,
.promo .story-entry .series-header {
	display: block;
	font-size: $scale-4;
	line-height: $line-height-shorter;
}

.series-title {
	color: $hed-color;
	font-size: inherit;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: $letter-spacing-base;
}

.story-entry,
.series-entry,
.article-header {
	display: block;

	&.region-local-reporting-network .description > *:last-child:after {
		content: "Local Reporting Network";
		display: table;
		font-family: $fonts-sans;
		font-size: $scale-4;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: $letter-spacing-more;
		line-height: $line-height-shorter;
		color: $page-bg-color;
		background-color: $pp-blue;
		padding: 0.5em 0.5em 0.4em 0.5em;
		margin-top: $scale-3;
	}
	.site-local-reporting-network &.region-local-reporting-network .description > *:last-child:after {
		display: none;
	}

	.lead-art a {
		border: none;
	}

	.lead-art + .description {
		margin-top: $scale-3;
	}

	.description > * + * {
		margin-top: $scale-2;
	}

	.hed {
		color: $hed-color;
        // font-family: $fonts-serif-initial;
        // .fonts-loaded & {
        //     font-family: $fonts-serif;
        // }

        // font-family: $fonts-serif;
        font-family: $fonts-hed;

        font-size: $scale-6;
		font-weight: bold;
	}

	.dek {
        // font-family: $fonts-sans-initial;
        // .fonts-loaded & {
        //     font-family: $fonts-sans;
        // }
        font-family: $fonts-sans;
		font-size: $scale-5;
	}

	.metadata {
		color: $text-color-low-contrast;
        // font-family: $fonts-sans-initial;
        // .fonts-loaded & {
        //     font-family: $fonts-sans;
        // }
        font-family: $fonts-sans;
		font-size: $scale-4;
		// @include breakpoint-min(xxl) {
		// 	font-size: $scale-4;
		// }
	}

	.byline {
		display: inline;
		margin: 0;
		&:after {
			content: ",";
			font-weight: normal;
		}
	}

	.byline.multiple {
		display: block;
		&:after {
			content: "";
		}
	}

	.name {
		// font-weight: bold;
		// text-transform: uppercase;
		// letter-spacing: $letter-spacing-base;
	}

	.timestamp {
		display: inline;
		white-space: nowrap;
	}

	.byline.multiple + .timestamp {
		display: block;
	}

	a {
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid rgba($link-color,0);
		&:hover,
		&:focus {
			color: $link-color;
			border-color: rgba($link-color,.8);
			outline: none;
		}
	}

}

// Article Header, Series Header
// These styles modify the core styles in the initial
// ".story-entry, .article-header" declaration above.

.article-header,
header.series-header {
	position: relative;
	padding-bottom: colspan(p,4);
	margin-bottom: colspan(p,4);
	border-bottom: $border;
	@include breakpoint-min(sm) {
		width: colspan(6,4);
		padding-bottom: colspan(p,4);
		margin-bottom: colspan(g,4);
	}
	@include breakpoint-min(md) {
		width: colspan(8,5);
		padding-bottom: colspan(p,5);
		margin-bottom: colspan(g,5);
	}
	@include breakpoint-min(lg) {
		width: colspan(11,7);
		padding-right: colspan(1,7) + colspan(g,7);
		padding-bottom: colspan(g,7);
		margin-bottom: colspan(g,7);
	}
	@include breakpoint-min(xl) {
		padding-right: colspan(2,7) + colspan(g,7);
	}
	&.search-header {
		width: auto;
	}
}

.article-header,
.article-full .article-header .description,
.video-single .article-header .description {

	> * + * {
		margin-top: $scale-4;
		@include breakpoint-min(sm) {
			margin-top: $scale-5;
		}
		@include breakpoint-min(lg) {
			margin-top: $scale-6;
		}
	}

	.series-header {
		// display: inline-block;
		border: none;
		@include breakpoint-min(md) {
			font-size: $scale-6;
		}

		.series-title {
			color: $hed-color;
			text-transform: uppercase;
			letter-spacing: $letter-spacing-base;


            margin-bottom: -0.66em;
            padding-top: 0.33em;
		}

		// &:hover .series-title,
		// &:focus .series-title {
		// 	color: $link-color;
		// }

	}

	.series-description {
		color: $text-color;
		margin: 0;
	}

	.hed {
		font-size: $scale-8;
		line-height: $line-height-shorter;
		@include breakpoint-min(sm) {
			font-size: $scale-9;
		}
		@include breakpoint-min(md) {
			font-size: $scale-10;
            line-height: $line-height-shortest;
		}
		@include breakpoint-min(lg) {
			// font-size: $scale-11;
            // font-size: $scale-10 * 1.125;
		}
	}

	.dek {
		font-size: $scale-6;
		// @include breakpoint-min(sm) {
		// 	font-size: $scale-7;
		// }
	}

	.metadata {
		@include breakpoint-min(sm) {
			font-size: $scale-5;
		}
	}

	.name,
	.publication {
		white-space: nowrap;
	}
}

.story-tools {
	color: $pp-blue;
    margin-top: $scale-7;
    transition: opacity 0.2s;
    @include breakpoint-min(lg) {
        position: absolute;
        top: $scale-10 * 0.25; // adjust to align relative to headline
        left: -(colspan(1,11) + colspan(g,11));
        width: colspan(1,11);
        margin: 0;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
        display: inline-block;
        width: $scale-7;
        margin-right: $scale-5;
        @include breakpoint-min(md) {
            width: $scale-8;
        }
        @include breakpoint-min(lg) {
            display: block;
            // width: $scale-8;
            margin: 0 auto $scale-7;
        }
    }

    a {
        border: none;
        display: block;

        .icon {
            fill: currentColor;
            height: auto;
            width: 100%;
        }

		.icon-label {
			display: none;
		}

        &:focus,
        &:hover {
            border-bottom: none;

            // .icon {
            //     fill: $text-color;
            // }
        }
        // override
        &.nav-tools-link {
            padding: 0;
        }

        &.disabled,
        &.disabled:focus,
        &.disabled:hover {
        	color: inherit;
        	cursor: default;
        	.icon {
        		opacity: 0.3;
        	}
        }
    }
}

.story-tools.is-fixed {
    // JS adds this class via waypoint when the top of
    // .article-header reaches the top of the viewport.
    // The messy math within is necessary when using
    // position:fixed. Josef may eventually absorb
    // this complexity more fully.
    @include breakpoint-min(lg) {
        position: fixed;
        top: 12rem;
        left: percentage(math.div($mar, (($col * 12) + ($gut * 11) + ($mar * 2))));
        width: percentage(math.div($col, (($col * 12) + ($gut * 11) + ($mar * 2))));
    }
    @media screen and (min-width: $max-width + ((math.div(($mar * 2), (($col * 12) + ($gut * 11)))) * $max-width)) {
        left: calc((100% - #{$max-width}) / 2);
        width: $max-width * math.div($col, (($col * 12) + ($gut * 11)));
    }
}
.mast-compact .story-tools.is-fixed {
    @include breakpoint-min(lg) {
        top: $scale-12;
    }
}
.story-tools.is-bottom {
    opacity: 0;
    pointer-events: none;
}
.mast-compact .story-tools.is-fixed {
    top: 8.15rem;
}

header.series-header {
	font-size: $scale-7;
	padding-bottom: $scale-6;
	margin-bottom: colspan(p,4);
	border-bottom: 1px solid $border-color;
	@include breakpoint-min(sm) {
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		margin-bottom: colspan(g,8);
	}
	@include breakpoint-min(lg) {
		padding-right: colspan(4,12);
		padding-bottom: $scale-7;
		margin-bottom: colspan(g,12);
	}

	.series-description {
		font-size: $scale-6;
		@include breakpoint-min(sm) {
			font-size: $scale-7;
		}
	}

	> * + * {
		margin-top: $scale-2;
	}

}

// Homepage and Listing Page F1 Article Listing

// These styles modify the core styles in the initial
// ".story-entry, .article-header" declaration above.

@function zoom($colspan) { // Allows hover scaling to be uniform across different element sizes
	@return (($col * $colspan) + ($gut * $colspan)) / (($col * $colspan) + ($gut * ($colspan - 0.5)));
}

// .features .story-entry.f1,
// .features .series-entry.f1 {
// 	@include clearfix;
// 	margin-bottom: colspan(g,4);
// 	@include breakpoint-min(sm) {
// 		margin-bottom: colspan(g,6);
// 	}
// 	@include breakpoint-min(md) {
// 		margin-bottom: colspan(g,8);
// 	}
// 	.lead-art {
// 		@include breakpoint-min(md) {
// 			float: left;
// 			width: colspan(5,8);
// 		}
// 	}
// 	.description {
// 		@include breakpoint-range(sm,md) {
// 			margin-right: colspan(1,6) + colspan(g,6);
// 		}
// 	}
// 	.lead-art + .description,
// 	.lead-art + .series-header {
// 		margin-top: $scale-4;
// 		@include breakpoint-min(md) {
// 			float: left;
// 			width: colspan(3,8);
// 			margin-top: 0;
// 			margin-left: colspan(g,8);
// 		}
// 	}
// 	.hed,
// 	body.listing & .series-title {
// 		font-size: $scale-7;
// 		@include breakpoint-min(sm) {
// 			font-size: $scale-9;
// 			line-height: $line-height-shorter;
// 		}
// 		@include breakpoint-min(md) {
// 			font-size: $scale-8;
// 		}
// 	}
// 	.series-description {
// 		font-size: $scale-6;
// 	}
// }

// body.listing .features .story-entry.f1 {
// 	@include breakpoint-range(md,lg) {
// 		margin-bottom: colspan(g,5);
// 		.lead-art,
// 		.description {
// 			float: none;
// 			width: 100%;
// 			margin-left: 0;
// 		}
// 		.description {
// 			margin-top: $scale-3;
// 		}
// 	}
// }

// Homepage F2, F3, F4, etc Article Listings

// These styles modify the core styles in the initial
// ".story-entry, .article-header" declaration above.

// body.home .features .story-entry {
// 	&.f2,
// 	&.f3,
// 	&:nth-child(2n+2),  // For F2, F4, F6, etc
// 	&:nth-child(2n+3) { // For F3, F5, F7, etc
// 		float: left;
// 		width: colspan(2,4);
// 		background: $page-bg-color;
// 		margin-bottom: colspan(g,4);
// 		@include breakpoint-min(sm) {
// 			width: colspan(3,6);
// 			margin-bottom: colspan(g,6);
// 		}
// 		@include breakpoint-min(md) {
// 			width: colspan(4,8);
// 			margin-bottom: colspan(g,8);
// 		}
// 		.lead-art {
// 			@include breakpoint-min(md) {
// 				float: left;
// 				width: colspan(2,4);
// 			}
// 		}
// 		.lead-art + .description {
// 			@include breakpoint-min(md) {
// 				float: left;
// 				width: colspan(2,4);
// 				margin-top: 0;
// 				margin-left: colspan(g,4);
// 			}
// 		}
// 		.hed {
// 			font-size: $scale-6;
// 			@include breakpoint-min(sm) {
// 				font-size: $scale-7;
// 			}
// 			@include breakpoint-min(md) {
// 				font-size: $scale-6;
// 			}
// 		}
// 		.dek {
// 			display: none;
// 		}
// 	}
// 	&.f2,
// 	&:nth-child(2n+2) { // For F2, F4, F6, etc
// 		clear: left;
// 	}
// 	&.f3,
// 	&:nth-child(2n+3) { // For F3, F5, F7, etc
// 		margin-left: colspan(g,4);
// 		@include breakpoint-min(sm) {
// 			margin-left: colspan(g,6);
// 		}
// 		@include breakpoint-min(md) {
// 			margin-left: colspan(g,8);
// 		}
// 	}
// }

// Homepage and Listing Page River Article Listing and Series Listing

// These styles modify the core styles in the initial
// ".story-entry, .article-header" declaration above.

// .river .story-entry {
// 	@include clearfix;
// 	clear: both;
// 	padding: colspan(p,4) 0;
// 	border-top: $border;
// 	&:first-child {
// 		margin-top: 0;
// 	}
// 	&:last-of-type {
// 		@include clearfix;
// 	}
// 	.lead-art {
// 		float: left;
// 		width: colspan(1,4);
// 		@include breakpoint-min(lg) {
// 			width: colspan(1,6);
// 		}
// 	}
// 	.lead-art + .description {
// 		float: left;
// 		width: colspan(3,4);
// 		margin-top: 0;
// 		margin-left: colspan(g,4);
// 		@include breakpoint-min(lg) {
// 			width: colspan(5,6);
// 			margin-left: colspan(g,6);
// 		}
// 	}
// 	.hed {
// 		font-size: $scale-6;
// 	}
// }
// body.listing .river .story-entry {
// 	@include breakpoint-min(md) {
// 		padding: colspan(p,5) 0;
// 	}
// 	@include breakpoint-min(lg) {
// 		padding: colspan(p,8) 0;
// 	}
// 	@include breakpoint-min(lg) {
// 		float: left;
// 		clear: none;
// 		width: colspan(4,8);
// 		border-bottom: none;
// 		&:nth-of-type(odd) {
// 			clear: left;
// 		}
// 		&:nth-of-type(even) {
// 			margin-left: colspan(g,8);
// 		}
// 	}
// 	.lead-art {
// 		@include breakpoint-min(md) {
// 			width: colspan(1,5);
// 		}
// 		@include breakpoint-min(lg) {
// 			width: colspan(1,4);
// 		}
// 	}
// 	.lead-art + .description {
// 		@include breakpoint-min(md) {
// 			width: colspan(4,5);
// 			margin-left: colspan(g,5);
// 		}
// 		@include breakpoint-min(lg) {
// 			width: colspan(3,4);
// 			margin-left: colspan(g,4);
// 		}
// 	}
// }
// .river .series-entry {
// 	float: left;
// 	width: colspan(2,4);
// 	padding: colspan(p,4) 0;
// 	border-top: $border;
// 	&:nth-child(even) {
// 		margin-left: colspan(g,4);
// 	}
// 	@include breakpoint-range(md,lg) {
// 		@include clearfix;
// 		float: none;
// 		width: 100%;
// 		padding: colspan(p,5) 0;
// 		&:nth-child(even) {
// 			margin-left: 0;
// 		}
// 	}
// 	@include breakpoint-min(lg) {
// 		width: colspan(4,8);
// 		padding: colspan(p,8) 0;
// 		&:nth-child(even) {
// 			margin-left: colspan(g,8);
// 		}
// 	}
// 	.lead-art {
// 		@include breakpoint-range(md,lg) {
// 			float: left;
// 			width: colspan(2,5);
// 		}
// 	}
// 	.series-header {
// 		font-size: $scale-6;
// 		margin-bottom: $scale-2;
// 	}
// 	.lead-art + .series-header {
// 		margin-top: $scale-4;
// 		@include breakpoint-range(md,lg) {
// 			float: left;
// 			width: colspan(3,5);
// 			margin-top: 0;
// 			margin-left: colspan(g,5);
// 		}
// 	}
// }
// body.listing header + #level1 .river:first-child {
// 	div:nth-child(1),
// 	.pagination + div {
// 		border-top: none;
// 		padding-top: 0;
// 	}
// 	@include breakpoint-range(xs,md) {
// 		.series-entry:nth-child(2) {
// 			border-top: none;
// 			padding-top: 0;
// 		}
// 	}
// 	@include breakpoint-min(lg) {
// 		div:nth-child(2),
// 		.pagination + div + div {
// 			border-top: none;
// 			padding-top: 0;
// 		}
// 	}
// }

// In-Article Promo Article Listing

// These styles modify the core styles in the initial
// ".story-entry, .article-header" declaration above.

// .story-entry,
// .article-header {
// 	h1,h2,h3,h4,h5,h6,p {
// 		font-size: inherit;
// 		line-height: inherit;
// 		text-indent: inherit;
// 		text-transform: inherit;
// 		letter-spacing: inherit;
// 	}
// }

// aside .story-entry {
// 	@include clearfix;
// 	margin: $scale-6 0;
// 	.lead-art.thumbnail {
// 		margin: 0 $scale-6 $scale-6 0;
// 	}
// 	.lead-art.thumbnail + .description {
// 		margin-top: 0;
// 	}
// 	.series-header,
// 	.metadata {
// 		font-size: $scale-4;
// 	}
// 	.hed {
// 		font-size: $scale-6;
// 	}
// 	.dek {
// 		font-size: $scale-5;
// 		font-weight: normal;
// 	}
// }
// aside.x-small .story-entry {
// 	.lead-art.thumbnail {
// 		display: none;
// 	}
// 	.hed {
// 		font-size: $scale-5;
// 	}
// 	.hed + .dek {
// 		margin-top: 0;
// 	}
// }
// aside.small .story-entry {
// 	.lead-art.thumbnail {
// 		display: none;
// 		@include breakpoint-min(lg) {
// 			display: block;
// 			float: left;
// 			width: colspan(1,3);
// 		}
// 	}
// 	.hed {
// 		font-size: $scale-5;
// 	}
// 	.hed + .dek {
// 		margin-top: 0;
// 	}
// }
// aside.medium .story-entry {
// 	.lead-art.thumbnail {
// 		display: none;
// 		@include breakpoint-min(md) {
// 			display: block;
// 			float: left;
// 			width: colspan(1,3);
// 		}
// 		@include breakpoint-min(lg) {
// 			width: colspan(1,4);
// 		}
// 	}
// 	&:first-of-type .hed {
// 		@include breakpoint-min(md) {
// 			font-size: $scale-7;
// 			line-height: $line-height-shorter;
// 		}
// 	}
// }
// aside.large .story-entry {
// 	.lead-art {
// 		@include breakpoint-min(md) {
// 			float: left;
// 			width: colspan(2,5);
// 		}
// 	}
// 	.lead-art.thumbnail {
// 		float: left;
// 		width: colspan(1,4);
// 		margin: 0;
// 		@include breakpoint-min(md) {
// 			width: colspan(1,5);
// 		}
// 	}
// 	.lead-art + .description {
// 		@include breakpoint-min(md) {
// 			float: left;
// 			width: calc((#{colspan(3,5)} + #{colspan(g,5)}) - #{$scale-6});
// 			margin-top: 0;
// 			margin-left: $scale-6;
// 		}
// 	}
// 	.lead-art.thumbnail + .description {
// 		float: left;
// 		margin-left: $scale-6;
// 		width: calc((#{colspan(3,4)} + #{colspan(g,4)}) - #{$scale-6});
// 		@include breakpoint-min(md) {
// 			width: calc((#{colspan(4,5)} + #{colspan(g,5)}) - #{$scale-6});
// 		}
// 	}
// 	.hed {
// 		font-size: $scale-6;
// 		line-height: $line-height-shorter;
// 	}
// 	&:first-of-type .hed {
// 		font-size: $scale-7;
// 		line-height: $line-height-shorter;
// 	}
// }

// "Don't Miss Our Latest Stories" Promo Article Listing

// These styles modify the core styles in the initial
// ".story-entry, .article-header" declaration above.

// .latest-stories {
// 	clear: both;
// 	width: 100%;
// 	margin-top: colspan(g,4);
// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: space-between;
// 	align-items: stretch;
// 	@include breakpoint-min(sm) {
// 		margin-top: colspan(g,6);
// 	}
// 	@include breakpoint-min(md) {
// 		margin-top: colspan(g,8);
// 	}
// 	@include breakpoint-min(lg) {
// 		margin-top: colspan(g,12);
// 	}
// 	.title {
// 		font-family: $fonts-sans;
// 		font-weight: bold;
// 		text-transform: uppercase;
// 		letter-spacing: $letter-spacing-base;
// 		width: 100%;
// 	}
// 	.story-entry {
// 		display: flex;
// 		flex-direction: column;
// 		align-items: stretch;
// 		width: colspan(2,4);
// 		margin-top: colspan(g,4);
// 		@include breakpoint-min(sm) {
// 			width: colspan(3,6);
// 			margin-top: colspan(g,6);
// 		}
// 		@include breakpoint-min(md) {
// 			width: colspan(2,8);
// 			margin-top: colspan(g,8);
// 		}
// 		@include breakpoint-min(lg) {
// 			width: colspan(3,12);
// 			margin-top: colspan(g,12);
// 		}
// 		.description:first-child { // If the story has no image
// 			display: flex;
// 			flex: 1;
// 			padding: colspan(p,2);
// 			background: $bg-low-contrast;
// 			// background: $pp-blue;
// 			@include breakpoint-min(sm) {
// 				padding: colspan(p,3)
// 			}
// 			@include breakpoint-min(md) {
// 				padding: colspan(p,2)
// 			}
// 			@include breakpoint-min(lg) {
// 				padding: colspan(p,3)
// 			}
// 			.hed {
// 				align-self: center;
// 				// color: white;
// 				font-size: $scale-7;
// 				padding-bottom: 1em;
// 			}
// 		}
// 	}
// }

// Default lead art background (in case image doesn't load).

.lead-art > img {
	position: relative;
	z-index: 1;
	background: $bg-low-contrast;
	&:after {
		content: "Loading Image";
		color: $text-color-low-contrast;
        // font-family: $fonts-sans-initial;
        // .fonts-loaded & {
        //     font-family: $fonts-sans;
        // }
        font-family: $fonts-sans;
		font-weight: bold;
		text-align: center;
		z-index: 0;
		display: block;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		overflow: hidden;
	}
}
