@use "sass:math";

// ------------------------------------------------------------
// HOMEPAGE
// ------------------------------------------------------------

// GRID

body.home.layout-grid {
	#level1.features {
		// Temporarily hiding the border because of the COVID module
		// border-bottom: $border;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		// Temporarily removing bottom margin because of the COVID module
		// margin-bottom: colspan(g,4);
		//
		// @include breakpoint-min(sm) {
		//	margin-bottom: colspan(g,6);
		// }
		// @include breakpoint-min(md) {
		//	margin-bottom: colspan(g,8);
		// }
		// @include breakpoint-min(lg) {
		//	margin-bottom: colspan(g,9);
		// }

		// Temporarily hiding series headers in homepage features
		// since they're all Coronavirus
		.story-entry .series-header {
			display: none;
			& + .hed {
				margin-top: 0;
			}
		}
	}
	.features .story-entry.f1 {
		background-color: $page-bg-color;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row-reverse;
		justify-content: space-between;
		text-align: center;
		width: 100%;
		border-bottom: $border;
		margin-bottom: colspan(p,4);
		padding-bottom: colspan(p,4);

		@include breakpoint-min(sm) {
			margin-bottom: colspan(p,6);
			padding-bottom: colspan(p,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,8);
			padding-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(g,9);
			padding-bottom: colspan(g,9);
		}

		.lead-art {
			width: 100%;
			@include breakpoint-min(xl) {
				width: colspan(6,9);
			}
		}

		.hed {
			font-size: $scale-9;
			line-height: $line-height-shortest;
			@include breakpoint-min(sm) {
				font-size: $scale-10;
				margin-bottom: $scale-5;
			}
		}
		.dek {
			@include breakpoint-min(sm) {
				margin-left: auto;
				margin-right: auto;
				width: colspan(5,6);
			}
			@include breakpoint-min(md) {
				width: colspan(7,8);
			}
			@include breakpoint-min(xl) {
				width: colspan(6,8);
			}
		}

		.lead-art + .description {
			margin-top: $scale-4;
			text-align: left;
			@include breakpoint-min(md) {
				align-self: center;
				padding-right: colspan(1,8) + colspan(g,8);
			}
			@include breakpoint-min(xl) {
				width: colspan(3,9);
				padding-right: 0;
				margin-top: 0;
			}
			.hed {
				font-size: $scale-7;
				line-height: $line-height-shorter;
				margin-bottom: 0;
				@include breakpoint-min(sm) {
					font-size: $scale-9;
				}
				@include breakpoint-min(md) {
					font-size: $scale-8;
				}
				@include breakpoint-min(xl) {
					font-size: $scale-8;
				}
			}
			.dek {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
			}
		}
	}
	.features .story-entry.f2,
	.features .story-entry.f3,
	.features .story-entry.f4 {
		margin-bottom: colspan(p,4);

		@include breakpoint-min(sm) {
			margin-bottom: colspan(p,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(g,9);
		}

		.lead-art {
			@include breakpoint-min(md) {
				float: right;
				width: percentage(math.div((($col * 2) + ($gut) - $pad), (($col * 4) + ($gut * 3) - $pad)));
				margin-left: $scale-6;
				margin-bottom: $scale-6;
			}
		}
	}

	.features .story-entry.f2 {
		width: 100%;
		padding-bottom: colspan(p,4);
		border-bottom: $border;
		
		@include breakpoint-min(sm) {
			padding-bottom: colspan(p,6);
		}
		@include breakpoint-min(md) {
			padding-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			width: calc(#{colspan(5,8)} + #{colspan(g,8)}/2);
			padding-bottom: 0;
			padding-right: calc(#{math.div(colspan(g,8), 2) + colspan(p,8)} - 1px);
			border-bottom: none;
			border-right: $border;
		}
		@include breakpoint-min(xl) {
			width: calc(#{colspan(5,9)} + #{colspan(g,9)}/2);
			padding-right: calc(#{math.div(colspan(g,9), 2) + colspan(p,9)} - 1px);
		}

		.lead-art {
			@include breakpoint-min(md) {
				width: colspan(3,8);
			}
			@include breakpoint-min(lg) {
				width: colspan(2,5) - colspan(p,8);
			}
		}

		.lead-art + .description {
			@include breakpoint-min(md) {
				margin-top: 0;
			}
			.hed {
				font-size: $scale-6;
				@include breakpoint-min(sm) {
					font-size: $scale-7;
				}
				@include breakpoint-min(md) {
					font-size: $scale-6;
				}
			}
		}

		.hed {
			font-size: $scale-8;
			line-height: $line-height-shorter;
		}
	}

	.features-group-f3-f4 {
		display: flex;
		flex-direction: row;

		@include breakpoint-min(lg) {
			flex-direction: column;
			width: colspan(3,8) + math.div(colspan(g,8), 2);
			padding-left: math.div(colspan(g,8), 2) + colspan(p,8);
		}
		@include breakpoint-min(xl) {
			width: colspan(4,9) + math.div(colspan(g,9), 2);
			padding-left: math.div(colspan(g,9), 2) + colspan(p,9);
		}
	}

	.features .story-entry.f3,
	.features .story-entry.f4 {
		@include breakpoint-max(lg) {
			width: 50%;
		}
		@include breakpoint-min(lg) {
			@include clearfix;
			width: 100%;
			padding-left: 0;
		}

		.lead-art {
			display: block;
			width: 100%;

			@include breakpoint-min(md) {
				width: colspan(1,4); // The context here isn’t 4 exactly, it should be 4 - g/2
			}

			@include breakpoint-min(lg) {
				display: none;
			}

			@include breakpoint-min(xl) {
				display: block;
				width: colspan(1,4); // The context here isn’t 4 exactly, it should be 4 - g/2
			}
		}

		.lead-art + .description {
			@include breakpoint-min(md) {
				margin-top: 0;
			}

			.hed {
				@include breakpoint-min(xs) {
					font-size: $scale-6;
				}
				@include breakpoint-min(lg) {
					font-size: $scale-5;
				}
			}
		}

		.hed {
			line-height: $line-height-shorter;
			font-size: $scale-7;

			@include breakpoint-min(lg) {
				font-size: $scale-5;
			}
			@include breakpoint-min(xl) {
				font-size: $scale-6;
			}
		}

		.dek {
			display: none;
		}
	}

	.features .story-entry.f3 {
		padding-right: math.div(colspan(g,4), 2);

		@include breakpoint-min(sm) {
			padding-right: math.div(colspan(g,6), 2);
		}
		@include breakpoint-min(md) {
			padding-right: math.div(colspan(g,8), 2);
			border-right: $border;
		}
		@include breakpoint-min(lg) {
			padding-right: 0;
			border-right: none;
		}
	}

	.features .story-entry.f4 {
		padding-left: calc(#{math.div(colspan(g,4), 2)} - 1px);
		border-left: $border;
		
		@include breakpoint-min(sm) {
			padding-left: calc(#{math.div(colspan(g,6), 2)} - 1px);
		}
		@include breakpoint-min(md) {
			padding-left: math.div(colspan(g,8), 2);
			border-left: 0;
		}
		@include breakpoint-min(lg) {
			padding-left: 0;
		}
	}

	.evergreen {
		@include breakpoint-min(md) {
			border-bottom: $border;
			padding-bottom: colspan(g,8);
			margin-bottom: colspan(g,8);
		}
		@include breakpoint-min(lg) {
			border-bottom: none;
			padding-bottom: 0;
			border-left: $border;
			padding-left: colspan(g,4);
			margin-bottom: colspan(g,4);
		}
		@include breakpoint-min(xl) {
			border-left: $border;
			padding-left: colspan(g,3);
			margin-bottom: colspan(g,3);
		}

		#featured-series-module,
		#featured-reporting-module {
			border-bottom: $border;
			padding-bottom: colspan(g,4);
			margin-bottom: colspan(g,4);

			@include breakpoint-min(sm) {
				border-bottom: $border;
				padding-bottom: colspan(g,6);
				margin-bottom: colspan(g,6);
			}
			@include breakpoint-min(md) {
				border-bottom: none;
				padding-bottom: 0;
				margin-right: 0;
			}
		}

		// Featured Investigation module
		#featured-series-module {
			@include breakpoint-min(lg) {
				border-bottom: $border;
				padding-bottom: colspan(g,4);
				margin-bottom: colspan(g,4);
			}
			@include breakpoint-min(xl) {
				border-bottom: $border;
				padding-bottom: colspan(g,3);
				margin-bottom: colspan(g,3);
			}

			.pp-module-title {
				margin-bottom: $scale-5;
				line-height: $line-height-shorter;
				text-transform: none;
			}

			.module-content {
				font-size: $scale-5;
			}

			.pp-module-title + a {
				color: $text-color-retina;
			}

			a + .series-detail,
			a + .dek {
				margin-top: $scale-4;
			}

			.series-detail {
				font-size: $scale-5;

				@include breakpoint-min(lg) {
					font-size: $scale-4;
				}

				ul {
					list-style: disc;
					padding-left: math.div(colspan(g,3), 2);

					li + li {
						margin-top: $scale-4;
					}
				}
			}

			.dek {
				font-size: $scale-5;
			}
		}
	}

	.river .story-entry {
		@include clearfix;
		clear: both;
		padding: colspan(p,4) 0;
		border-top: $border;

		&:first-child {
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}

		&:last-of-type {
			@include clearfix;
		}

		.lead-art {
			float: right;
			width: colspan(1,4);
			margin-left: $scale-6;
			margin-bottom: $scale-6;
			@include breakpoint-min(lg) {
				width: colspan(1,6);
			}
		}

		.lead-art + .description {
			// float: left;
			// width: colspan(3,4);
			margin-top: 0;
			// margin-left: colspan(g,4);
			@include breakpoint-min(lg) {
				// width: colspan(5,6);
				// margin-left: colspan(g,6);
			}
		}

		.hed {
			font-size: $scale-6;
			// line-height: $line-height-shorter;
		}
	}

	.browse-by-month {
		margin: colspan(g,4) 0 0 0;
		@include breakpoint-min(sm) {
			padding: colspan(p,4);
		}
		@include breakpoint-min(lg) {
			padding: colspan(p,6);
			margin-top: colspan(g,6);
		}
	}

	#level3 > * {
		margin-bottom: colspan(g,4);
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,2);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	#level4 > * {
		margin-bottom: colspan(g,4);

		@include breakpoint-min(sm) {
			margin-bottom: colspan(g,2);
		}
		@include breakpoint-min(lg) {
			margin-bottom: colspan(g,4);
		}
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}

body.home.layout-float {
	#level1.features {
		border-bottom: $border;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: colspan(g,4);
		@include breakpoint-min(sm) {
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,8);
		}
		// Temporarily hiding series headers in homepage features
		// since they're all Coronavirus
		.story-entry .series-header {
			display: none;
			& + .hed {
				margin-top: 0;
			}
		}
	}
	.features .story-entry.f1 {
		background-color: $page-bg-color;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: colspan(g,4);
		text-align: center;
		width: 100%;
		@include breakpoint-min(sm) {
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,8) + colspan(p,8);
		}
		@include breakpoint-min(xl) {
			margin-bottom: colspan(g,8);
		}

		.lead-art {
			width: 100%;
			@include breakpoint-min(xl) {
				width: colspan(5,8);
			}
		}

		.hed {
			font-size: $scale-9;
			line-height: $line-height-shortest;
			@include breakpoint-min(sm) {
				font-size: $scale-10;
				margin-bottom: $scale-5;
			}
		}
		.dek {
			@include breakpoint-min(sm) {
				margin-left: auto;
				margin-right: auto;
				width: colspan(5,6);
			}
			@include breakpoint-min(md) {
				width: colspan(7,8);
			}
			@include breakpoint-min(xl) {
				width: colspan(6,8);
			}
		}

		.lead-art + .description {
			margin-top: $scale-4;
			text-align: left;
			@include breakpoint-min(sm) {
				padding-right: colspan(1,6) + colspan(g,6);
			}
			@include breakpoint-min(md) {
				align-self: center;
				padding-right: colspan(1,8) + colspan(g,8);
			}
			@include breakpoint-min(xl) {
				width: colspan(3,8);
				padding-right: 0;
				margin-top: 0;
			}
			.hed {
				font-size: $scale-7;
				line-height: $line-height-shorter;
				margin-bottom: 0;
				@include breakpoint-min(sm) {
					font-size: $scale-9;
				}
				@include breakpoint-min(md) {
					font-size: $scale-8;
				}
				@include breakpoint-min(xl) {
					font-size: $scale-7;
				}
			}
			.dek {
				margin-left: 0;
				margin-right: 0;
				width: 100%;
			}
		}
	}
	.features .story-entry.f2, .features .story-entry.f3, .features .story-entry:nth-child(2n+2),
	// For F2, F4, F6, etc
	.features .story-entry:nth-child(2n+3) {
		// For F3, F5, F7, etc
		width: 50%; // not sure why "justify-content: space-between;" is misbehaving :/
		margin-bottom: colspan(g,4);
		@include breakpoint-min(sm) {
			margin-bottom: colspan(g,6);
		}
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,8);
		}

		.lead-art {
			@include breakpoint-min(md) {
				float: right;
				width: percentage(math.div((($col * 2) + ($gut) - $pad), (($col * 4) + ($gut * 3) - $pad)));
				margin-left: $scale-6;
				margin-bottom: $scale-6;
			}
		}

		.hed {
			font-size: $scale-7;
			@include breakpoint-min(sm) {
				font-size: $scale-8;
				line-height: $line-height-shorter;
			}
		}

		.dek {
			display: none;
		}

		.lead-art + .description {
			@include breakpoint-min(md) {
				margin-top: 0;
			}
			.hed {
				font-size: $scale-6;
				@include breakpoint-min(sm) {
					font-size: $scale-7;
				}
				@include breakpoint-min(md) {
					font-size: $scale-6;
				}
			}
		}
	}

	.features .story-entry.f2,
	.features .story-entry:nth-child(2n+2) {
		// For F2, F4, F6, etc
		padding-right: math.div(colspan(g,4), 2);
		@include breakpoint-min(sm) {
			padding-right: math.div(colspan(g,6), 2);
		}
		@include breakpoint-min(md) {
			padding-right: calc(#{colspan(g,8)} - 1px);
			border-right: $border;
		}
	}

	.features .story-entry.f3,
	.features .story-entry:nth-child(2n+3) {
		// For F3, F5, F7, etc
		padding-left: math.div(colspan(g,4), 2);
		@include breakpoint-min(sm) {
			padding-left: math.div(colspan(g,6), 2);
		}
		@include breakpoint-min(md) {
			padding-left: colspan(g,8);
		}
	}

	.river .story-entry {
		@include clearfix;
		clear: both;
		padding: colspan(p,4) 0;
		border-top: $border;

		&:first-child {
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}

		&:last-of-type {
			@include clearfix;
		}

		.lead-art {
			float: right;
			width: colspan(1,4);
			margin-left: $scale-6;
			margin-bottom: $scale-6;
			@include breakpoint-min(lg) {
				width: colspan(1,6);
			}
		}

		.lead-art + .description {
			// float: left;
			// width: colspan(3,4);
			margin-top: 0;
			// margin-left: colspan(g,4);
			@include breakpoint-min(lg) {
				// width: colspan(5,6);
				// margin-left: colspan(g,6);
			}
		}

		.hed {
			font-size: $scale-6;
			// line-height: $line-height-shorter;
		}
	}

	.browse-by-month {
		margin: colspan(g,4) 0 0 0;
		@include breakpoint-min(sm) {
			padding: colspan(p,4);
		}
		@include breakpoint-min(lg) {
			padding: colspan(p,6);
			margin-top: colspan(g,6);
		}
	}

	#level3 > * {
		margin-bottom: colspan(g,4);
		@include breakpoint-min(md) {
			margin-bottom: colspan(g,2);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	#level4 > * {
		margin-bottom: colspan(g,4);
		@include breakpoint-range(sm,lg) {
			margin-bottom: colspan(g,2);
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.features,
.river {
	@include clearfix;
}

.update {
		// font-family: $fonts-sans-initial;
		// .fonts-loaded & {
		//     font-family: $fonts-sans;
		// }
		font-family: $fonts-sans;
	font-size: $scale-5;
	background: $bg-low-contrast;

	a {
		display: block;
	}
}

.series-list {
	margin-bottom: colspan(g,4);
	@include breakpoint-range(sm,lg) {
		margin-bottom: colspan(g,2);
	}
	> .hed {
		color: $hed-color;
		font-family: $fonts-sans;
		// font-size: $scale-7;
		font-size: $scale-6;
		font-weight: bold;
		border-bottom: $border;

		a {
			display: block;
			color: inherit;
			padding-bottom: $scale-3;
			border: none;

			&:focus,
			&:hover {
				color: $link-color;
			}
		}
	}

	.series-entry {
				// font-family: $fonts-sans-initial;
				// .fonts-loaded & {
				//     font-family: $fonts-sans;
				// }
				font-family: $fonts-sans;
		font-size: $scale-5;
		border-bottom: $border;
		padding: $scale-4 0 $scale-6;

		> * + * {
			margin-top: $scale-4;
		}
	}

	.series-header {
		outline: none;
	}

	.lead-art a {
		border: none;
	}

	.cta {
		margin-top: 1em;
	}
}

// If JavaScript is enabled, on page load it applies
// .expanded to the first .series and .collapsed to
// the rest.

.series-list .collapsible-enhanced {
	.series-header {
		cursor: pointer;

		&:focus .series-title,
		&:hover .series-title {
			color: $link-color;
		}
	}

	.series-title {
		position: relative;
		padding-right: 2em;

		&:after {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

.series-list .collapsible-collapsed {
	> * {
		display: none;
	}

	> .series-header {
		display: block;
	}

	.series-title:after {
		content: "▼";
		// content: "►";
	}
}

.series-list .collapsible-expanded {
	position: relative;

	> * {
		display: block;
	}

	.series-title:after {
		content: " ▲";
	}
}

.home #level3 {
	@include breakpoint-max(md) {
		// .pp-module {
		// 	padding: colspan(p, 4);
		// }

		.pp-module.has-photo {
			.module-photo {
				margin-left: $scale-5;
				margin-bottom: $scale-5;
				float: right;
				width: 25%;
			}

			.module-content {
				display: block;
			}
		}
	}
}

.home #level4 {
	// .pp-module {
	// 	padding: colspan(p, 4);
	// 	@include breakpoint-min(sm) {
	// 		padding: colspan(p, 2);
	// 	}
	// 	@include breakpoint-min(lg) {
	// 		padding: colspan(p, 4);
	// 	}
	// }
	@include breakpoint-range(sm, lg) {
		.module-cap {
			display: block;
		}

		.module-cap p {
			margin-bottom: $scale-5;
		}
	}
}

.home .features #unheard-promo {
	background-color: black;
	background-position: center center;
	background-size: cover;
	border: none;
	border-radius: 0.25em;
	color: white;
	display: flex;
	flex-direction: column;
	font-family: $fonts-sans;
	justify-content: center;
	margin-bottom: colspan(g,4);
	overflow: hidden;
	padding: 2em;
	padding-left: 50%;
	text-shadow: 0 0 3em black, 1px 1px 0 rgba(0,0,0,0.5);

	@include breakpoint-min(sm) {
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		margin-bottom: colspan(g,8);
	}

	.promo-hed {
		font-size: $scale-8;
		text-transform: uppercase;
	}
	.promo-dek {
		font-size: $scale-5;
	}

	&.unheard-promo-1 {
		background-image: url(../images/unheard-promo-1-1120.jpg);
		@include breakpoint-min(sm) {
			background-image: url(../images/unheard-promo-1-1400.jpg);
		}
		@include breakpoint-min(md) {
			background-image: url(../images/unheard-promo-1-1600.jpg);
		}
		@include breakpoint-min(xl) {
			background-image: url(../images/unheard-promo-1-1900.jpg);
		}
	}
	&.unheard-promo-2 {
		background-image: url(../images/unheard-promo-2-1120.jpg);
		@include breakpoint-min(sm) {
			background-image: url(../images/unheard-promo-2-1400.jpg);
		}
		@include breakpoint-min(md) {
			background-image: url(../images/unheard-promo-2-1600.jpg);
		}
		@include breakpoint-min(xl) {
			background-image: url(../images/unheard-promo-2-1900.jpg);
		}
	}
	&.unheard-promo-3 {
		background-image:
			linear-gradient(
				to right,
				rgba(0,0,0,0) 0%,
				rgba(0,0,0,0) 25%,
				rgba(0,0,0,0.75) 100%
			),
			url(../images/unheard-promo-3-1120.jpg)
		;
		@include breakpoint-min(sm) {
			background-image:
				linear-gradient(
					to right,
					rgba(0,0,0,0) 0%,
					rgba(0,0,0,0) 25%,
					rgba(0,0,0,0.75) 100%
				),
				url(../images/unheard-promo-3-1400.jpg)
			;
		}
		@include breakpoint-min(md) {
			background-image:
				linear-gradient(
					to right,
					rgba(0,0,0,0) 0%,
					rgba(0,0,0,0) 25%,
					rgba(0,0,0,0.75) 100%
				),
				url(../images/unheard-promo-3-1600.jpg)
			;
		}
		@include breakpoint-min(xl) {
			background-image:
				linear-gradient(
					to right,
					rgba(0,0,0,0) 0%,
					rgba(0,0,0,0) 25%,
					rgba(0,0,0,0.75) 100%
				),
				url(../images/unheard-promo-3-1900.jpg)
			;
		}
	}
}
