// ------------------------------------------------------------
// COLOR VARIABLES
// ------------------------------------------------------------

// New color system

$color-black: #000000;
$color-white: #ffffff;

$color-neutral-gray-90: #1B1B1B;
$color-neutral-gray-80: #303030;
$color-neutral-gray-70: #474747;
$color-neutral-gray-60: #5E5E5E;
$color-neutral-gray-50: #777777;
$color-neutral-gray-40: #919191;
$color-neutral-gray-30: #ABABAB;
$color-neutral-gray-20: #C6C6C6;
$color-neutral-gray-10: #E2E2E2;

$color-neutral-cool-90: #1B2127;
$color-neutral-cool-80: #2B3137;
$color-neutral-cool-70: #40484F;
$color-neutral-cool-60: #556068;
$color-neutral-cool-50: #6C7982;
$color-neutral-cool-40: #84939C;
$color-neutral-cool-30: #9EADB6;
$color-neutral-cool-20: #BEC8CE;
$color-neutral-cool-10: #DEE3E6;

$color-neutral-warm-90: #201B10;
$color-neutral-warm-80: #353025;
$color-neutral-warm-70: #4C463A;
$color-neutral-warm-60: #645E51;
$color-neutral-warm-50: #7D7669;
$color-neutral-warm-40: #979082;
$color-neutral-warm-30: #B1AB9C;
$color-neutral-warm-20: #CDC6B7;
$color-neutral-warm-10: #EEECE7;

// ---

// $pp-blue: #134f7c;
$pp-blue: #304154;

$red: #d0011b;
$green: #36bc36;
$grey-light: $color-neutral-gray-30;

$hed-color: $color-black;
$text-color: $color-neutral-gray-80;
$text-color-retina: $color-black;
$text-color-low-contrast: $color-neutral-gray-40;

$page-bg-color: $color-white;
$bg-low-contrast: #f0efee;
$bg-dark: #1c2126;

$border-color: darken($bg-low-contrast, 10%);
$border: 1px solid $border-color;

$link-color: #006997;
$donate-color: #bf2d2d;

$nav-red: #be4751;

$fb-color: #3f5d9a;
$twitter-color: #1da1f3;
$youtube-color: #ff0000;
$rss-color: #fd7e03;
