// ------------------------------------------------------------
// LAYOUT
// ------------------------------------------------------------

#header {}

#footer {}

#level1 {
	body.article &,
	body.home & {
		background: none;
	}
}

#level2 {
	// background: yellow;
	// min-height: 10em;
	// body.home & {
	// 	background: none;
	// }
}

#level3 {
	// background: green;
	// min-height: 10em;
}

#level4 {
	// background: cyan;
	// min-height: 10em;
}

#banner {
	background: magenta;
	width: 100%;
	min-height: 10em;
	margin-bottom: colspan(g,4);
	@include breakpoint-min(sm) {
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		margin-bottom: colspan(g,8);
	}
	@include breakpoint-min(lg) {
		margin-bottom: colspan(g,12);
	}
}

// Home Layout

// GRID

body.home.layout-grid .wrapper {
	max-width: 1024px;
	margin: 0 auto;

	@supports (display: grid) {
		max-width: none;
		display: grid;

		@include breakpoint-min(xs) {
			grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end row3-start] auto [row3-end row4-start] auto [row4-end row5-start] auto [row5-end row6-start] auto [row6-end];
			grid-template-columns: 1fr;
			grid-template-areas:
				"level-1"
				"level-1a"
				"level-1b"
				"level-2"
				"level-3"
				"level-4";
		}

		@include breakpoint-min(sm) {
			grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end row3-start] auto [row3-end row4-start] auto [row4-end row5-start] auto [row5-end];
			grid-template-columns: repeat(6, 1fr);
			column-gap: colspan(g,6);
			grid-template-areas:
				"level-1  level-1  level-1  level-1  level-1  level-1"
				"level-1a level-1a level-1a level-1a level-1a level-1a"
				"level-1b level-1b level-1b level-1b level-1b level-1b"
				"level-2  level-2  level-2  level-2  level-3  level-3"
				"level-4  level-4  level-4  level-4  level-3  level-3";
		}

		@include breakpoint-min(md) {
			grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end row3-start] auto [row3-end row4-start] auto [row4-end];
			grid-template-columns: repeat(8, 1fr);
			column-gap: colspan(g,8);
			grid-template-areas:
				"level-1  level-1  level-1  level-1  level-1  level-1  level-1  level-1"
				"level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a"
				"level-1b level-1b level-1b level-1b level-1b level-1b level-1b level-1b"
				"level-3  level-3  level-2  level-2  level-2  level-2  level-4  level-4";
		}

		@include breakpoint-min(lg) {
			grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end];
			grid-template-columns: repeat(12, 1fr);
			column-gap: colspan(g,12);
			grid-template-areas:
				"level-1  level-1  level-1  level-1  level-1  level-1  level-1  level-1  level-1b level-1b level-1b level-1b"
				"level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a"
				"level-3  level-3  level-2  level-2  level-2  level-2  level-2  level-2  level-4  level-4  level-4  level-4";
		}

		@include breakpoint-min(xl) {
			grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end];
			grid-template-columns: repeat(12, 1fr);
			column-gap: colspan(g,12);
			grid-template-areas:
				"level-1  level-1  level-1  level-1  level-1  level-1  level-1  level-1  level-1  level-1b level-1b level-1b"
				"level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1a level-1b level-1b level-1b"
				"level-3  level-3  level-2  level-2  level-2  level-2  level-2  level-2  level-4 level-4  level-4  level-4";
		}
	}
}

body.home.layout-grid #level1.features {
	grid-area: level-1;
}

body.home.layout-grid #level1a.promo-covid {
	grid-area: level-1a;
}

body.home.layout-grid #level1b.evergreen {
	grid-area: level-1b;

	.brief-thumb {
		@include breakpoint-min(lg) {
			width: colspan(1,4); // The context here isn’t 4 exactly, it should be 4 - g
		}
		@include breakpoint-min(xl) {
			width: colspan(1,3); // The context here isn’t 3 exactly, it should be 3 - g
		}
	}

	display: flex;
	flex-direction: column;

	.series-img {
		float: right;
		width: colspan(2,4);
		margin: 0 0 colspan(p,6) colspan(g,4);
	}

	@include breakpoint-min(sm) {
		.series-img {
			float: right;
			width: colspan(2,6);
			margin: 0 0 colspan(p,6) colspan(g,6);
		}
	}

	@include breakpoint-min(md) {
		display: flex;
		flex-direction: row;

		#featured-series-module {
			width: colspan(6,8);
			margin-right: colspan(g,8);

			.series-img {
				float: right;
				width: colspan(2,6);
				margin: 0 0 colspan(p,6) colspan(g,6);
			}
		}
		#featured-reporting-module {
			width: colspan(2,8);
		}
	}

	@include breakpoint-min(lg) {
		display: flex;
		flex-direction: column;

		#featured-series-module {
			width: 100%;
			margin-right: 0;

			.series-img {
				float: none;
				width: 100%;
				margin: 0;
			}
		}
		#featured-reporting-module {
			width: 100%;
		}
	}
}

body.home.layout-grid #level2.river {
	grid-area: level-2;
}

body.home.layout-grid #level3.promos-1 {
	grid-area: level-3;
}

body.home.layout-grid #level4.promos-2 {
	grid-area: level-4;
}

// FLOAT

body.home.layout-float .wrapper {
	@include breakpoint-min(lg) {
		float: left;
		width: colspan(8,12);
	}
}

body.home.layout-float #level1.features {
	@include clearfix;
	// margin-bottom: colspan(g,4);
	// @include breakpoint-min(sm) {
	// 	margin-bottom: colspan(g,6);
	// }
	// @include breakpoint-min(md) {
	// 	margin-bottom: colspan(g,8);
	// }
	// @include breakpoint-min(lg) {
	// 	width: colspan(8,8);
	// }
}

body.home.layout-float #level2.river {
	margin-bottom: colspan(g,4);
	@include breakpoint-min(sm) {
		float: left;
		width: colspan(4,6);
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		width: colspan(4,8);
		margin-left: colspan(2,8) + colspan(g,8);
		margin-bottom: 0;
	}
	@include breakpoint-min(lg) {
		width: colspan(6,8);
	}
}

body.home.layout-float #level3.promos-1 {
	margin-bottom: colspan(g,4);
	@include breakpoint-min(sm) {
		float: left;
		width: colspan(4,6);
		margin-bottom: 0;
	}
	@include breakpoint-min(md) {
		width: colspan(2,8);
		margin-left: -(colspan(6,8));
	}
	@include breakpoint-min(lg) {
		margin-left: -100%;
	}
}

body.home.layout-float #level4.promos-2 {
	@include breakpoint-min(sm) {
		display: inline-block;
		width: colspan(2,6);
		margin-left: colspan(g,6);
	}
	@include breakpoint-min(md) {
		display: block;
		float: right;
		width: colspan(2,8);
		margin-left: 0;
	}
	@include breakpoint-min(lg) {
		width: colspan(4,12);
	}
}

// Article & Listing Page Layout

// body.article #level1,
body.listing #level1 {
	margin-bottom: colspan(g,4);
	@include breakpoint-min(sm) {
		float: left;
		width: colspan(4,6);
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		width: colspan(5,8);
		margin-bottom: colspan(1,8);
	}
	@include breakpoint-min(lg) {
		width: colspan(8,12);
		margin-bottom: colspan(1,12);
	}
}

body.article #level2,
body.listing #level2 {
	@include breakpoint-min(sm) {
		display: inline-block;
		// float: right;
		width: colspan(2,6);
		margin-left: colspan(g,6);
	}
	@include breakpoint-min(md) {
		width: colspan(3,8);
		margin-left: colspan(g,8);
	}
	@include breakpoint-min(lg) {
		width: colspan(4,12);
		margin-left: colspan(g,12);
	}
}
// body.listing .features{
// 	@include breakpoint-min(lg) {
// 		float: left;
// 		width: colspan(8,12);
// 	}
// }

body.listing .introduction {
	margin-bottom: colspan(g,4);
	@include breakpoint-min(sm) {
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(md) {
		margin-bottom: colspan(g,8);
	}
	// @include breakpoint-min(lg) {
	// 	// float: left;
	// 	// width: colspan(8,12);
	// 	margin-bottom: colspan(g,12);
	// }
}

// About Layout

body.about #banner {}

body.about .wrapper {
	@include breakpoint-min(md) {
		float: left;
		width: colspan(6,8);
		margin-bottom: colspan(g,6);
	}
	@include breakpoint-min(lg) {
		width: colspan(8,12);
		margin-bottom: colspan(g,8);
	}
}

body.about #level1 {
	@include breakpoint-min(sm) {
		float: left;
		width: colspan(4,6);
	}
	@include breakpoint-min(md) {
		width: colspan(2,6);
	}
	@include breakpoint-min(lg) {
		width: colspan(2,8);
	}
}

body.about #level2 {
	margin-top: colspan(g,4);
	@include breakpoint-min(sm) {
		float: left;
		width: colspan(4,6);
		margin-top: colspan(g,6);
	}
	@include breakpoint-min(md) {
		float: right;
		width: colspan(4,6);
		margin-left: colspan(g,6);
		margin-top: 0;
	}
	@include breakpoint-min(lg) {
		width: colspan(6,8);
		margin-left: colspan(g,8);
	}
}

body.about #level3 {
	margin-top: colspan(g,4);
	@include breakpoint-min(sm) {
		float: left;
		width: colspan(4,6);
		margin-top: colspan(g,6);
	}
	@include breakpoint-min(md) {
		clear: left;
		width: colspan(2,6);
		margin-top: colspan(g,6);
	}
	@include breakpoint-min(lg) {
		width: colspan(2,8);
		margin-top: colspan(g,8);
	}
}

body.about #level4 {
	margin-top: colspan(g,4);
	@include breakpoint-min(sm) {
		display: inline-block;
		width: colspan(2,6);
		margin-top: 0;
		margin-left: colspan(g,6);
	}
	@include breakpoint-min(md) {
		display: block;
		float: right;
		width: colspan(2,8);
		margin-left: 0;
	}
	@include breakpoint-min(lg) {
		width: colspan(4,12);
	}
}

// LRN and other regional landing pages

.about-module {
    .hed {
        font-family: $fonts-sans;
        font-size: $scale-7;
        font-weight: bold;
        line-height: $line-height-shorter;
        @include breakpoint-min(sm) {
            font-size: $scale-8;
            line-height: $line-height-base;
        }
        @include breakpoint-min(md) {
            font-size: $scale-7;
        }
        @include breakpoint-min(lg) {
            font-size: $scale-7;
            line-height: $line-height-shorter;
        }
    }

    .description {
        font-size: $scale-6;
        margin-top: 1em;
        margin-bottom: 1em;
        @include breakpoint-min(lg) {
            font-size: $scale-6;
            line-height: $line-height-base;
        }
    }

    .description--sans {
        font-family: $fonts-sans;
        font-size: $scale-5;
    }

    .description-link {
        margin-bottom: 0;
    }
}

.about-module__email {
	form {
		margin-bottom: 0.5em;
	}

	input[type="email"] {
		border-color: transparent;
	}
}

// Places homepage partners, series, river sections

.places-two-col-sm-right {
	@include clearfix;
    margin-bottom: colspan(g, 12);

    .title {
        font-family: $fonts-sans;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-base;
    }

    .intro {
        font-size: $scale-5;
        line-height: $line-height-taller;
        padding-right: 1em;
    }

    .places-sidebar {
        border-bottom: $border;
        margin-bottom: colspan(g, 4);

        .title,
        .intro {
            margin-bottom: colspan(g, 4);
            @include breakpoint-min(sm) {
                margin-bottom: colspan(g, 2);
            }
            @include breakpoint-min(lg) {
                margin-bottom: colspan(g, 4);
            }
            @include breakpoint-min(xxl) {
                margin-bottom: colspan(g, 3);
            }
        }

        .intro {
            margin-top: -0.75 * colspan(g, 4);
            @include breakpoint-min(sm) {
                margin-top: -0.75 * colspan(g, 2);
            }
            @include breakpoint-min(lg) {
                margin-top: -0.75 * colspan(g, 4);
            }
            @include breakpoint-min(xxl) {
                margin-top: -0.75 * colspan(g, 3);
            }
        }

        @include breakpoint-min(sm) {
            border-bottom: none;
            float: right;
            padding-bottom: 0;
            width: colspan(2, 6);
        }
        @include breakpoint-min(md) {
            width: colspan(2, 8);
        }
        @include breakpoint-min(lg) {
            border-left: $border;
            padding-left: 1em;
            width: colspan(4, 12);
        }
        @include breakpoint-min(xxl) {
            width: colspan(3, 12);
        }
    }

    .places-river-wrapper {
        .title {
            margin-bottom: colspan(g, 4);
            @include breakpoint-min(sm) {
                margin-bottom: colspan(g, 4);
            }
            @include breakpoint-min(md) {
                margin-bottom: colspan(g, 6);
            }
            @include breakpoint-min(lg) {
                margin-bottom: colspan(g, 8);
            }
            @include breakpoint-min(xxl) {
                margin-bottom: colspan(g, 9);
            }
        }
        @include breakpoint-min(sm) {
            float: left;
            margin-right: colspan(g, 6);
            width: colspan(4, 6);
        }
        @include breakpoint-min(md) {
            margin-right: colspan(g, 8);
            width: colspan(6, 8);
        }
        @include breakpoint-min(lg) {
            margin-right: colspan(g, 12);
            width: colspan(8, 12);
        }
        @include breakpoint-min(xxl) {
            width: colspan(9, 12);
        }
    }
}

.places-staff-wrapper {

    .places-staff-group {
        font-family: $fonts-sans;
        font-size: $scale-5;
    }

    .places-staff-group-header {
        display: block;
        font-weight: 700;
        font-size: $scale-5;
        text-transform: uppercase;
        margin-top: 1rem;
    }
}

.places-partners-wrapper {

    .places-partners-list {
        @include clearfix;
        margin-bottom: colspan(g, 4);
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g,6);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g,8);
        }
        @include breakpoint-min(lg) {
            margin-bottom: colspan(g,12);
        }
    }

    .places-partners-item {
        float: left;
        width: colspan(2, 4);
        margin-right: colspan(g, 4);
        @include breakpoint-min(sm) {
            float: none;
            margin-right: 0;
            width: colspan(4, 4);
        }

        .description > * {
            line-height: $line-height-shorter;
        }

        .description > * + * {
            margin-top: 0.25rem;
        }
    }

    .places-partners-item:nth-child(2n) {
        margin-right: 0;
    }

    .story-entry {
        @include clearfix;
        margin-bottom: colspan(g, 8);
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g, 2);
        }
        @include breakpoint-min(lg) {
            margin-bottom: colspan(g, 4);
        }
        @include breakpoint-min(xxl) {
            // margin-bottom: colspan(g, 3);
            margin-bottom: 1em;
        }
    }

    .story-entry .lead-art {
        display: none;
        @include breakpoint-range(lg,xxl) {
            display: block;
            float: left;
            width: colspan(1, 4);
            margin-right: 1em;
        }
        // @include breakpoint-min(xxl) {
        //     width: colspan(1, 3);
        // }
    }

    .story-entry .description {
        @include breakpoint-range(lg,xxl) {
            float: left;
            width: colspan(3, 4);
        }
    }

    .story-entry .lead-art + .description {
        margin-top: 0;
    }
}

.places-series-wrapper {
}

.places-river-wrapper {
}

// LRN homepage partners and series sections

.lrn-two-col-sm-left {
    @include clearfix;
    margin-bottom: colspan(g, 12);

    .title {
        font-family: $fonts-sans;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-base;
    }

    .intro {
        font-size: $scale-5;
        line-height: $line-height-taller;
        padding-right: 1em;
    }

    .lrn-partners-wrapper {
        border-bottom: $border;
        margin-bottom: colspan(g, 4);

        .title,
        .intro {
            margin-bottom: colspan(g, 4);
            @include breakpoint-min(sm) {
                margin-bottom: colspan(g, 2);
            }
            @include breakpoint-min(lg) {
                margin-bottom: colspan(g, 4);
            }
            @include breakpoint-min(xxl) {
                margin-bottom: colspan(g, 3);
            }
        }

        .intro {
            margin-top: -0.75 * colspan(g, 4);
            @include breakpoint-min(sm) {
                margin-top: -0.75 * colspan(g, 2);
            }
            @include breakpoint-min(lg) {
                margin-top: -0.75 * colspan(g, 4);
            }
            @include breakpoint-min(xxl) {
                margin-top: -0.75 * colspan(g, 3);
            }
        }

        @include breakpoint-min(sm) {
            border-bottom: none;
            float: left;
            padding-bottom: 0;
            width: colspan(2, 6);
        }
        @include breakpoint-min(md) {
            width: colspan(2, 8);
        }
        @include breakpoint-min(lg) {
            border-right: $border;
            width: colspan(4, 12);
        }
        @include breakpoint-min(xxl) {
            width: colspan(3, 12);
        }
    }

    .lrn-series-wrapper {
        .title {
            margin-bottom: colspan(g, 4);
            @include breakpoint-min(sm) {
                margin-bottom: colspan(g, 4);
            }
            @include breakpoint-min(md) {
                margin-bottom: colspan(g, 6);
            }
            @include breakpoint-min(lg) {
                margin-bottom: colspan(g, 8);
            }
            @include breakpoint-min(xxl) {
                margin-bottom: colspan(g, 9);
            }
        }
        @include breakpoint-min(sm) {
            float: left;
            margin-left: colspan(g, 6);
            width: colspan(4, 6);
        }
        @include breakpoint-min(md) {
            margin-left: colspan(g, 8);
            width: colspan(6, 8);
        }
        @include breakpoint-min(lg) {
            margin-left: colspan(g, 12);
            width: colspan(8, 12);
        }
        @include breakpoint-min(xxl) {
            width: colspan(9, 12);
        }
    }
}

.lrn-partners-wrapper {
    .lrn-partners-list {
        @include clearfix;
        margin-bottom: colspan(g, 4);
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g,6);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g,8);
        }
        @include breakpoint-min(lg) {
            margin-bottom: colspan(g,12);
        }
    }

    .lrn-partners-item {
        float: left;
        width: colspan(2, 4);
        margin-right: colspan(g, 4);
        @include breakpoint-min(sm) {
            float: none;
            margin-right: 0;
            width: colspan(4, 4);
        }

        .description > * {
            line-height: $line-height-shorter;
        }

        .description > * + * {
            margin-top: 0.25rem;
        }
    }

    .lrn-partners-item:nth-child(2n) {
        margin-right: 0;
    }

    .story-entry {
        @include clearfix;
        margin-bottom: colspan(g, 8);
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g, 2);
        }
        @include breakpoint-min(lg) {
            margin-bottom: colspan(g, 4);
        }
        @include breakpoint-min(xxl) {
            // margin-bottom: colspan(g, 3);
            margin-bottom: 1em;
        }
    }

    .story-entry .lead-art {
        display: none;
        @include breakpoint-range(lg,xxl) {
            display: block;
            float: left;
            width: colspan(1, 4);
            margin-right: 1em;
        }
        // @include breakpoint-min(xxl) {
        //     width: colspan(1, 3);
        // }
    }

    .story-entry .description {
        @include breakpoint-range(lg,xxl) {
            float: left;
            width: colspan(3, 4);
            padding-right: 1em;
        }
        @include breakpoint-min(xxl) {
            // width: colspan(2, 3);
            padding-right: 1em;
        }
    }

    .story-entry .lead-art + .description {
        margin-top: 0;
    }
}

.lrn-series-wrapper {
    .series-list {
        @include clearfix;
        margin-bottom: colspan(g, 4);
        @include breakpoint-min(sm) {
            margin-bottom: colspan(g, 6);
        }
        @include breakpoint-min(md) {
            margin-bottom: colspan(g, 8);
        }
        @include breakpoint-min(lg) {
            margin-bottom: colspan(g, 8);
        }
        @include breakpoint-min(xxl) {
            margin-bottom: colspan(g, 9);
        }

        .series-entry {
            @include clearfix;
            border-bottom: none;
            float: left;
            width: colspan(2, 4);
            margin-left: colspan(g, 4);
            margin-bottom: colspan(g, 4);
            padding: 0;

            &:nth-child(2n+1) {
                @include breakpoint-max(xxl) {
                    clear: left;
                    margin-left: 0;
                }
            }
            @include breakpoint-min(sm) {
                margin-bottom: colspan(g, 4);
            }
            @include breakpoint-min(md) {
                margin-bottom: colspan(g, 6);
                width: colspan(3, 6);
                margin-left: colspan(g, 6);
            }
            @include breakpoint-min(lg) {
                margin-bottom: colspan(g, 8);
                width: colspan(4, 8);
                margin-left: colspan(g, 8);
            }
            @include breakpoint-min(xxl) {
                margin-left: colspan(g, 9);
                width: colspan(3, 9);
                margin-bottom: colspan(g, 9);

                &:nth-child(3n+1) {
                    clear: left;
                    margin-left: 0;
                }
            }
        }
    }

    .lead-art {
        width: colspan(4, 4);
        @include breakpoint-min(sm) {
            width: colspan(4, 4);
        }
        @include breakpoint-min(md) {
            width: colspan(3, 3);
        }
        @include breakpoint-min(lg) {
            width: colspan(4, 4);
        }
    }

    .series-header {}

    .series-title {
        font-family: $fonts-sans;
        font-weight: bold;
        text-transform: uppercase;
    }

    .series-dek {}
}
